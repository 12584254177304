<template>
  <v-flex xs12 class="px-4 pb-3">
    <span v-if="!store.isSendInvoice">
      <AgentAssistTextField
        label="Card number"
        :detail="details.cardNumber"
        :disabled="retrievalDisabled"
        @retrieve="retrieveCardNumber"
      />
      <AgentAssistTextField
        label="Expiry date"
        :detail="details.expiryDate"
        :disabled="retrievalDisabled"
        @retrieve="retrieveExpiryDate"
      />
      <AgentAssistTextField label="CVC" :detail="details.cvc" :disabled="retrievalDisabled" @retrieve="retrieveCVC" />
      <div v-if="errorMessage" class="red--text text-center my-2">{{ errorMessage }}</div>
      <div v-if="!store.paymentError" class="text-right">
        <v-btn
          :disabled="store.isPaymentDetailConfirmationPending"
          color="error"
          class="mr-0 btn-payment white--text"
          @click="cancel"
        >
          {{ errorMessage ? 'Retry' : 'Cancel' }}
        </v-btn>
        <v-btn
          :color="brandColour"
          class="mr-0 btn-payment white--text"
          :disabled="retrievalIncomplete || store.isPaymentDetailConfirmationPending"
          :loading="store.isPaymentDetailConfirmationPending"
          @click="completeRetrieval"
        >
          Capture Payment
        </v-btn>
      </div>
      <div v-else>
        <div class="red--text text-center my-2">{{ store.paymentError.description }}</div>
        <div class="text-right">
          <v-btn color="error" class="mr-0 btn-payment white--text" @click="cancel"> Retry </v-btn>
          <v-btn
            v-if="store.paymentError.enablePaymentLink"
            :color="brandColour"
            class="mr-0 btn-payment white--text"
            @click="onInvoiceClick"
          >
            Invoice
          </v-btn>
        </div>
      </div>
    </span>
    <PaymentInvoice v-else @cancelInvoice="cancel" />
  </v-flex>
</template>

<script lang="ts">
import TwilioController from '@/api/twilioController'
import { AssistedPayCaptureType } from '@/common/enums'
import AgentAssistSignalRWatcher from './AgentAssistSignalRWatcher'
import AgentAssistCardDetail from '@/models/twilio/assistedPay/AgentAssistCardDetail'
import TwilioUpdateDTO from '@/api/models/assistedPay/TwilioUpdateDTO'
import Vue from 'vue'
import { FormatDate, FormatPaymentCard, FormatCVC } from '@/common/StringFormatting'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import AgentAssistTextField from './AgentAssistTextField.vue'
import { useCustomerToPayStore } from '@/pinia/customer-to-pay'
import AssistedPaymentsController from '@/api/assisted-payments-controller'
import TwilioPaymentMethodDTO from '@/api/models/assistedPay/TwilioPaymentMethodDTO'
import Job from '@/models/Job'
import PaymentInvoice from '../PaymentInvoice.vue'
import { colours } from '@/common/themes'

@Component({
  components: { AgentAssistTextField, PaymentInvoice },
})
export default class AgentAssist extends Vue {
  @Prop() public callSid: string
  @Prop() private job: Job | null
  public details = {
    cardNumber: new AgentAssistCardDetail({
      format: FormatPaymentCard,
      type: AssistedPayCaptureType.CardNumber,
    }),
    expiryDate: new AgentAssistCardDetail({
      format: FormatDate,
      type: AssistedPayCaptureType.ExpiryDate,
    }),
    cvc: new AgentAssistCardDetail({
      format: FormatCVC,
      type: AssistedPayCaptureType.CVC,
    }),
  }
  public errorMessage = ''
  public brandColour = colours.brand

  private customerCallSid: string | null = null
  private signalRWatcher: AgentAssistSignalRWatcher | null = null
  private detailBeingUpdated: AgentAssistCardDetail | null = null
  public store = useCustomerToPayStore()

  public get retrievalIncomplete() {
    return !this.details.cardNumber.isSet || !this.details.expiryDate.isSet || !this.details.cvc.isSet
  }
  public get retrievalDisabled() {
    return !!this.detailBeingUpdated || !this.store.paymentSid
  }

  private mounted() {
    if (!this.store.isPaymentConfirmed) {
      this.initiate()
    }
  }

  private async destroyed() {
    if (this.store.paymentSid && this.customerCallSid) {
      await AssistedPaymentsController.Cancel(this.customerCallSid, this.store.paymentSid, this.store.idempotencyKey)
    }

    if (this.signalRWatcher) {
      this.signalRWatcher.off('updateReceived', this.handleTwilioUpdate)
      this.signalRWatcher.off('paymentMethodReceived', this.handlePaymentCompleted)
      this.signalRWatcher.disconnect()
    }
  }

  private async initiate() {
    const callSidResponse = await TwilioController.RetrieveCustomerCallSid(this.callSid)
    if (callSidResponse.callSid && this.job) {
      this.customerCallSid = callSidResponse.callSid

      await useCustomerToPayStore().initiatePayment(this.customerCallSid, this.job.id)

      if (this.store.paymentInitiated) {
        this.$emit('inProgressChanged', true)
        this.signalRWatcher = new AgentAssistSignalRWatcher()
        this.signalRWatcher.on('updateReceived', this.handleTwilioUpdate)
        this.signalRWatcher.on('paymentMethodReceived', this.handlePaymentCompleted)
      } else {
        this.errorMessage = 'Unable to start assisted payment. Please send an invoice.'
      }
    } else {
      this.errorMessage = 'Unable to retrieve customer call SID. You must be on a call to capture a payment.'
    }
  }

  public async retrieveCardNumber() {
    if (this.store.paymentSid && this.customerCallSid) {
      const res = await this.details.cardNumber.update(
        this.customerCallSid,
        this.store.paymentSid,
        this.store.idempotencyKey
      )
      this.updateProgressState(res, this.details.cardNumber)
    }
  }

  public async retrieveExpiryDate() {
    if (this.store.paymentSid && this.customerCallSid) {
      const res = await this.details.expiryDate.update(
        this.customerCallSid,
        this.store.paymentSid,
        this.store.idempotencyKey
      )
      this.updateProgressState(res, this.details.expiryDate)
    }
  }

  public async retrieveCVC() {
    if (this.store.paymentSid && this.customerCallSid) {
      const res = await this.details.cvc.update(this.customerCallSid, this.store.paymentSid, this.store.idempotencyKey)
      this.updateProgressState(res, this.details.cvc)
    }
  }

  private async updateProgressState(success: boolean, detail: AgentAssistCardDetail) {
    if (!success) {
      this.errorMessage = 'Unable to start retrieving details.'
      return
    }

    this.detailBeingUpdated = detail
  }

  public async cancel() {
    if (this.store.paymentSid && this.customerCallSid) {
      await AssistedPaymentsController.Cancel(this.customerCallSid, this.store.paymentSid, this.store.idempotencyKey)
    }

    this.details.cardNumber.reset()
    this.details.expiryDate.reset()
    this.details.cvc.reset()
    this.store.reset()
    this.detailBeingUpdated = null
    this.$emit('inProgressChanged', false)
    this.clearErrorMessage()
  }

  public async completeRetrieval() {
    if (this.store.paymentSid && this.customerCallSid) {
      this.clearErrorMessage()
      const res = await AssistedPaymentsController.Complete(
        this.customerCallSid,
        this.store.paymentSid,
        this.store.idempotencyKey
      )
      if (!res) {
        this.errorMessage = 'Unable to complete assisted payment. Please try again.'
        return
      }

      this.store.isPaymentDetailConfirmationPending = true
      this.store.isPaymentComplete = true
    }
  }

  public clearErrorMessage() {
    this.errorMessage = ''
  }

  public onInvoiceClick() {
    useCustomerToPayStore().isSendInvoice = true
  }

  private handleTwilioUpdate(error: string, payload: TwilioUpdateDTO) {
    if (error) {
      this.errorMessage = 'An error occurred: ' + error
      if (this.detailBeingUpdated) {
        this.detailBeingUpdated.reset()
        this.detailBeingUpdated = null
      }
    } else {
      if (this.detailBeingUpdated && this.detailBeingUpdated.type === payload.captureType) {
        this.detailBeingUpdated.setValue(payload.newValue)
        if (!payload.partialResult) {
          this.detailBeingUpdated.isSet = true
          this.detailBeingUpdated = null
        }
      }
    }
  }

  private async handlePaymentCompleted(payload: TwilioPaymentMethodDTO) {
    if (!payload.isSuccessful || !this.customerCallSid || !this.job) {
      this.errorMessage = payload.error ? payload.error : 'undefined'
      this.store.isPaymentDetailConfirmationPending = false
    } else {
      this.store.confirmPayment(this.customerCallSid, payload.paymentMethodId, this.job)
    }
  }
}
</script>

<style scoped>
.complete-text {
  font-size: 16px;
}

.secondary-cancel-button {
  border-radius: 28px;
  font-size: 11px;
}
</style>
