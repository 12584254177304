import AssistedPaymentsController from '@/api/assisted-payments-controller'
import { PaymentErrorResponse } from '@/api/models/customerToPay/payment-error-response'
import Address from '@/models/Address'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import EscalateCTPResponseModel from '@/models/claim/EscalateCTPResponseModel'
import Job from '@/models/Job'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import store from '@/store'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useCustomerToPayStore = defineStore('Customer-To-Pay', () => {
  const amount = ref('0')
  const paymentInitiated = ref(false)
  const paymentDetailsConfirmed = ref(false)
  const paymentDetailsOpened = computed(() => [!paymentDetailsConfirmed.value])
  const paymentOpened = computed(() => [paymentDetailsConfirmed.value])
  const paymentSid = ref('')
  const statementDescription = ref('')
  const cardholderName = ref('')
  const emailAddress = ref('')
  const selectedPaymentType = ref('')
  const address = ref(new Address())
  const isPaymentComplete = ref(false)
  const isPaymentConfirmed = ref(false)
  const idempotencyKey = ref('')
  const escalateCtpResponseModel = ref<EscalateCTPResponseModel | null>(null)
  const escalatedCtpAmount = ref(0)
  const paymentError = ref<PaymentErrorResponse | null>(null)
  const isSendInvoice = ref(false)
  const isPaymentDetailConfirmationPending = ref(false)
  const emergencyId = ref('')
  const customerToPay = ref<CustomerToPayModel | null>(null)
  const isTransactionCompleted = computed(
    () => isPaymentConfirmed.value || customerToPay.value?.isTransactionCompleted || false
  )

  function setAmount(ctpAmount: number) {
    amount.value = ctpAmount.toFixed(2).toString()
  }

  async function initiatePayment(callSid: string, jobId: string) {
    idempotencyKey.value = jobId + crypto.randomUUID()
    const result = await AssistedPaymentsController.Initiate(callSid, jobId, idempotencyKey.value)

    if (!result) {
      store.Instance.dispatch('snackbarModule/showSnackbar', new ShowErrorSnackbar('Unable to initiate payment'))
      return
    }

    paymentSid.value = result.sid
    paymentInitiated.value = true
  }

  async function confirmPayment(callSid: string, paymentMethodId: string, job: Job) {
    const response = await AssistedPaymentsController.Confirm({
      callSid,
      paymentMethodId,
      amount: Number(amount.value),
      jobId: job.id,
      jobType: job.jobType,
      clientName: job.insurer,
      jobPostcode: job.postCode,
      statementDescription: statementDescription.value,
      cardholderName: cardholderName.value,
      cardholderEmail: emailAddress.value,
      paymentType: selectedPaymentType.value,
      billingAddress: {
        line1: address.value.line1,
        line2: address.value.line2,
        postcode: address.value.postcode,
        city: address.value.city,
        state: address.value.state,
        country: address.value.country,
      },
      escalateCtp: escalateCtpResponseModel.value,
      emergencyId: emergencyId.value,
    })

    if (response.isTransactionSuccessful) {
      isPaymentConfirmed.value = true
    } else {
      paymentError.value = response.paymentError
    }

    isPaymentDetailConfirmationPending.value = false
  }

  function reset() {
    isPaymentConfirmed.value = false
    paymentSid.value = ''
    paymentInitiated.value = false
    paymentDetailsConfirmed.value = false
    paymentError.value = null
    isSendInvoice.value = false
    isPaymentDetailConfirmationPending.value = false
  }

  return {
    amount,
    paymentInitiated,
    paymentDetailsConfirmed,
    paymentDetailsOpened,
    paymentOpened,
    statementDescription,
    cardholderName,
    emailAddress,
    selectedPaymentType,
    address,
    paymentSid,
    isPaymentComplete,
    isPaymentConfirmed,
    idempotencyKey,
    escalateCtpResponseModel,
    escalatedCtpAmount,
    paymentError,
    isSendInvoice,
    isPaymentDetailConfirmationPending,
    emergencyId,
    customerToPay,
    isTransactionCompleted,
    setAmount,
    initiatePayment,
    confirmPayment,
    reset,
  }
})
