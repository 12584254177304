import { defineStore, setMapStoreSuffix } from 'pinia'
import { computed, ref } from 'vue'
import { useTwilioDevice } from './device'
import { useTwilioWorker } from './worker'
import { useTwilioWorkspace } from './workspace'
import { useTwilioCall } from './call'

export const useTwilioStore = defineStore('twilio', () => {
  const initialized = ref<boolean>(false)
  const device = useTwilioDevice()
  const worker = useTwilioWorker()
  const workspace = useTwilioWorkspace()
  const call = useTwilioCall()

  async function init() {
    await device.init()
    await worker.init()
    await workspace.init()
    initialized.value = true
  }

  return {
    init,
    initialized,
    worker: computed(() => worker.instance),
    device: computed(() => device.instance),
    call: computed(() => call.call),
    reservation: computed(() => call.reservation),
    setWorkerActivity: worker.setActivity,
    returnWorkerToQueue: worker.returnToQueue,
    workerInQueue: computed(() => worker.inQueue),
    workerNotInQueueReason: computed(() => worker.notInQueueReason),
    workerInWrapUp: computed(() => worker.inWrapUp),
    workerWrapUpCountdown: computed(() => worker.wrapUpCountdown),
    workspaceContacts: computed(() => workspace.contacts),
    setCall: call.setCall,
    clearCall: call.clearCall,
    setReservation: call.setReservation,
    clearReservation: call.clearReservation,
  }
})
