<template>
  <div class="pa-2 mb-2 text-center black--text">
    <PaymentDetails :job="job" />
    <Payment :job="job" />
  </div>
</template>

<script lang="ts">
import TimeLineItemBase from '@/components/TimeLineItemBase'
import { useCustomerToPayStore } from '@/pinia/customer-to-pay'
import { Component } from 'vue-property-decorator'
import PaymentDetails from './PaymentDetails.vue'
import eventBus from '@/common/bus'
import EscalateCTPResponseModel from '@/models/claim/EscalateCTPResponseModel'
import Payment from './Payment.vue'
import storeGetters from '@/storeGetters'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import Shared from '@/common/shared'

@Component({
  components: { Payment, PaymentDetails },
})
export default class PaymentPreview extends TimeLineItemBase {
  public isCompleted = false
  public store = useCustomerToPayStore()

  private mounted() {
    eventBus.$on(
      'escalateCTPResponse',
      (escalateCTPResponseModel: EscalateCTPResponseModel, amountNeedToPay: number, emergencyId: string) => {
        this.store.escalateCtpResponseModel = escalateCTPResponseModel
        this.store.escalatedCtpAmount = amountNeedToPay ? Number(Shared.getFormatedCurrency(amountNeedToPay, true)) : 0
        this.store.setAmount(this.store.escalatedCtpAmount)
        this.store.emergencyId = emergencyId
      }
    )
    eventBus.$on('paymentOnClosingJob', (amountNeedToPay: number) => {
      this.store.escalatedCtpAmount = amountNeedToPay ? Number(Shared.getFormatedCurrency(amountNeedToPay, true)) : 0
      this.store.setAmount(this.store.escalatedCtpAmount)
    })
    eventBus.$on(
      'triggerCTPForPolicyChange',
      (escalateCTPResponseModel: EscalateCTPResponseModel, amountNeedToPay: number) => {
        this.store.escalateCtpResponseModel = escalateCTPResponseModel
        this.store.escalatedCtpAmount = amountNeedToPay ? Number(Shared.getFormatedCurrency(amountNeedToPay, true)) : 0
        this.store.setAmount(this.store.escalatedCtpAmount)
      }
    )

    if (this.itemId) {
      this.store.customerToPay = storeGetters.getCustomerToPayByItemId(this.jobId, this.itemId)
    } else {
      this.store.customerToPay = new CustomerToPayModel(this.jobId)
    }
  }
}
</script>
