import SessionDetail, { DetailRecord } from '@/models/auth/SessionDetail'
import ClientUserDetailModel from '@/models/client/ClientUserDetailModel'
import EngineerModel from '@/models/contractor/EngineerModel'
import UserModel from '@/models/user/UserModel'
import { initialize } from 'launchdarkly-js-client-sdk'
import type { State } from '@/store'
import type { LDClient, LDContext, LDOptions } from 'launchdarkly-js-client-sdk'
import type { PluginObject, VueConstructor } from 'vue'
import type { Store } from 'vuex'

function createContextFromUser(user: UserModel): LDContext {
  const ldUser: LDContext = {
    kind: 'user',
    anonymous: false,
    key: user.id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    role: user.roleName,
    team: user.teamName || '',
    skillLevel: user.skillLevel,
    jobTypes: user.jobTypes,
    skills: {
      complaintResolution: user.skills.complaintResolution,
      deployment: user.skills.deployment,
      fnol: user.skills.fnol,
      invoicing: user.skills.invoicing,
      system: user.skills.system,
      workInProgress: user.skills.workInProgress,
    },
  }
  return ldUser
}

function createContextFromEngineer(user: EngineerModel): LDContext {
  return {
    kind: 'multi',
    engineer: {
      anonymous: false,
      key: user.id || '',
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      contractorId: user.contractorId,
      hasPortalAccess: user.hasPortalAccess,
    },
    contractor: {
      anonymous: false,
      key: user.contractorId,
    },
  }
}

function createContextFromClient(user: ClientUserDetailModel): LDContext {
  return {
    kind: 'multi',
    'client-user': {
      anonymous: false,
      key: user.id,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      clientId: user.insurerId.toString(),
      hasPortalAccess: user.hasPortalAccess,
      isAffinityPartner: user.isAffinityPartner,
    },
    client: {
      anonymous: false,
      key: user.insurerId.toString(),
    },
  }
}

function createContextFromDetail(user: DetailRecord): LDContext {
  if (user && user.UserDetail) {
    return createContextFromUser(user.UserDetail)
  }
  if (user && user.EngineerDetail) {
    return createContextFromEngineer(user.EngineerDetail)
  }
  if (user && user.ClientUserDetail) {
    return createContextFromClient(user.ClientUserDetail)
  }
  return systemContext
}

const systemContext: LDContext = {
  kind: 'user',
  anonymous: true,
  key: 'SYSTEM',
  name: 'SYSTEM',
}

interface LdUserPluginOptions {
  envKey: string
  ldOptions: LDOptions | undefined
  store: Store<State>
}

let ldClient: LDClient | undefined

export async function createLaunchDarkly(options: LdUserPluginOptions): Promise<PluginObject<LdUserPluginOptions>> {
  const mergedOptions = { ...options.ldOptions }
  mergedOptions.logger = {
    debug: () => {},
    info: () => {},
    warn: () => {},
    error: () => {},
  }

  ldClient = initialize(options.envKey, systemContext, mergedOptions)
  await ldClient.waitUntilReady()
  options.store.watch(
    (_state, getters) => getters.sessionDetail,
    (newVal: SessionDetail) => {
      if (newVal.detailRecord && ldClient) {
        const newContext = createContextFromDetail(newVal.detailRecord)
        ldClient.identify(newContext)
      }
    }
  )

  return {
    install: (vue: VueConstructor) => {
      vue.prototype.$ld = ldClient
    },
  }
}

export function useLaunchDarkly(): LDClient {
  if (!ldClient) {
    throw new Error('Attempted to access LaunchDarkly client before create.')
  }
  return ldClient
}
