<template>
  <v-expansion-panel v-model="store.paymentDetailsOpened" expand>
    <v-expansion-panel-content>
      <div slot="header">
        <h3>Payment Details</h3>
      </div>
      <div class="px-3 py-1">
        <v-text-field
          v-model="store.amount"
          v-validate="'required'"
          label="Amount"
          :disabled="isDisabled"
          data-vv-scope="paymentDetailsValidator"
          required
          class="required"
          data-vv-name="Amount"
          :error-messages="errors.collect('Amount')"
          type="number"
          @keypress="currencyFilter"
        >
          <template #prepend-inner>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M14 21c1.93 0 3.62-1.17 4-3l-1.75-.88C16 18.21 15.33 19 14 19H9.1c.83-1 1.5-2.34 1.5-4c0-.35-.03-.69-.1-1H14v-2H9.82C9 10.42 8 9.6 8 8a3.5 3.5 0 0 1 6.78-1.22L16.63 6c-.8-2.05-2.79-3.5-5.13-3.5C8.46 2.5 6 4.96 6 8c0 1.78.79 2.9 1.5 4H6v2h2.47c.08.31.13.64.13 1c0 2.7-2.6 4-2.6 4v2z"
              />
            </svg>
          </template>
        </v-text-field>

        <v-text-field
          v-model="store.statementDescription"
          v-validate="'required'"
          label="Statement Description"
          :disabled="isDisabled"
          data-vv-scope="paymentDetailsValidator"
          required
          class="required"
          data-vv-name="Statement Description"
          :error-messages="errors.collect('Statement Description')"
          maxlength="22"
        />

        <v-text-field
          v-model="store.cardholderName"
          v-validate="'required'"
          label="Card Holder Name"
          :disabled="isDisabled"
          data-vv-scope="paymentDetailsValidator"
          required
          class="required"
          data-vv-name="Card Holder Name"
          :error-messages="errors.collect('Card Holder Name')"
        />

        <v-checkbox
          v-model="sameBillingAddress"
          label="Billing Address same as job address"
          :disabled="isDisabled"
        ></v-checkbox>

        <v-layout v-if="!sameBillingAddress" row wrap>
          <v-flex xs6 pr-2>
            <v-text-field
              v-model="store.address.line1"
              v-validate="'required'"
              label="Address Line 1"
              data-vv-scope="paymentDetailsValidator"
              required
              class="required"
              data-vv-name="Address Line 1"
              :error-messages="errors.collect('Address Line 1')"
              :disabled="isDisabled"
            />
          </v-flex>
          <v-flex xs6 pl-2>
            <v-text-field v-model="store.address.line2" label="Address Line 2" :disabled="isDisabled" />
          </v-flex>
          <v-flex xs6 pr-2>
            <v-text-field
              v-model="store.address.city"
              v-validate="'required'"
              label="Town/City"
              data-vv-scope="paymentDetailsValidator"
              required
              class="required"
              data-vv-name="Town/City"
              :error-messages="errors.collect('Town/City')"
              :disabled="isDisabled"
            />
          </v-flex>
          <v-flex xs6 pl-2>
            <v-text-field v-model="store.address.state" label="County" :disabled="isDisabled" />
          </v-flex>
          <v-flex xs6 pr-2>
            <v-text-field v-model="store.address.country" label="Country" :disabled="isDisabled" />
          </v-flex>
          <v-flex xs6 pl-2>
            <v-text-field
              v-model="store.address.postcode"
              v-validate="'required'"
              label="Postal Code"
              data-vv-scope="paymentDetailsValidator"
              required
              class="required"
              data-vv-name="Postal Code"
              :error-messages="errors.collect('Postal Code')"
              :disabled="isDisabled"
            />
          </v-flex>
        </v-layout>

        <v-text-field
          v-model="store.emailAddress"
          v-validate="'required|email'"
          label="Email Address"
          :disabled="isDisabled"
          data-vv-scope="paymentDetailsValidator"
          required
          class="required"
          data-vv-name="Email Address"
          :error-messages="errors.collect('Email Address')"
        />

        <v-select
          v-model="store.selectedPaymentType"
          v-validate="'required'"
          label="Payment Type"
          :disabled="isDisabled"
          :items="paymentTypes"
          item-label="label"
          item-value="value"
          data-vv-scope="paymentDetailsValidator"
          required
          class="required"
          data-vv-name="Payment Type"
          :error-messages="errors.collect('Payment Type')"
        >
          <template #item="{ item }">
            <div class="flex flex-col">
              <div>{{ item.label }}</div>
              <div class="text--secondary text-lg">{{ item.description }}</div>
            </div>
          </template>

          <template #selection="{ item }">
            <span>{{ item.label }}</span>
          </template>
        </v-select>

        <v-layout class="justify-end">
          <v-flex xs4 class="d-flex justify-end">
            <v-btn :color="brandColour" class="white--text" :disabled="isDisabled" @click="onConfirmPress"
              >Confirm</v-btn
            >
          </v-flex>
        </v-layout>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script lang="ts">
import { useCustomerToPayStore } from '@/pinia/customer-to-pay'
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Job from '@/models/Job'
import { CtpPaymentTypes } from '@/models/claim/CustomerToPayModel'
import Store from '@/store'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import Shared from '@/common/shared'
import { colours } from '@/common/themes'

@Component
export default class PaymentDetails extends Vue {
  @Prop() private job: Job | null
  public sameBillingAddress = true
  public paymentTypes = Object.values(CtpPaymentTypes)
  public brandColour = colours.brand

  public store = useCustomerToPayStore()

  private created() {
    this.store.reset()
    if (this.job) {
      const store = useCustomerToPayStore()
      store.statementDescription =
        this.job && this.job.emergencies.length === 1
          ? this.job.emergencies[0].typeDescription
            ? this.job.emergencies[0].typeDescription
            : this.job.emergencies[this.job.emergencies.length - 1].detailDescription
          : this.job.id
      store.cardholderName = this.job.clientFullName
      store.emailAddress = this.job.email
      store.address.line1 = this.job.addressLine1
      store.address.line2 = this.job.addressLine2
      store.address.city = this.job.addressLine3
      store.address.state = this.job.addressLine4
      store.address.postcode = this.job.postCode
    }
  }

  public get callSid(): string {
    return Store.Instance.state.CurrentCallSid
  }

  // or if no call sid
  public get isDisabled(): boolean {
    return this.store.paymentDetailsConfirmed || this.store.isTransactionCompleted
  }

  public async onConfirmPress() {
    const result = await this.$validator.validateAll('paymentDetailsValidator')

    if (Number(this.store.amount) < this.store.escalatedCtpAmount) {
      this.$store.dispatch(
        'snackbarModule/showSnackbar',
        new ShowErrorSnackbar(
          'Amount must not be less than ' + Shared.getFormatedCurrency(this.store.escalatedCtpAmount)
        )
      )

      return
    }

    if (result && this.job) {
      useCustomerToPayStore().paymentDetailsConfirmed = true
    }
  }

  public currencyFilter($event: KeyboardEvent) {
    const store = useCustomerToPayStore()
    const key = $event.key

    if (!/^\d$/.test(key) && (key !== '.' || store.amount.includes('.'))) {
      $event.preventDefault()
    }

    if (store.amount != null && store.amount.includes('.') && store.amount.split('.')[1].length > 1) {
      $event.preventDefault()
    }
  }
}
</script>
