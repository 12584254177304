<template>
  <v-flex xs12 lg6>
    <v-text-field
      v-model="fileName"
      label="Upload Files"
      :loading="uploadDocumentInProgress"
      append-icon="attach_file"
      :hide-details="true"
      :readonly="isDisabled"
      name="select-file"
      @click="pickFile"
    ></v-text-field>
    <input
      ref="fileUpload"
      v-validate="{ rules: { required: false, size: getAttachmentSize } }"
      type="file"
      style="display: none"
      name="file"
      multiple
      :disabled="isDisabled"
      data-vv-scope="formReference"
      @change="onFilePicked"
    />
    <div class="input-group__details">
      <div class="input-group__messages input-group__error input-group--error error--text">
        <span>{{ fileValidationMessage(errors, fileErrorMessage) }}</span>
      </div>
    </div>
    <div class="complaint-files mt-3">
      <span v-for="file in selectedFileList" :key="file.fileName" class="elevation-1">
        <div class="image-action hover-content-section">
          <span v-if="typeof file.fileURL === 'string'">
            <img
              v-if="
                file.fileType.toLowerCase() === 'jpg' ||
                file.fileType.toLowerCase() === 'jpeg' ||
                file.fileType.toLowerCase() === 'png'
              "
              :src="file.fileURL"
              @click="uploadCarousel = true"
            />
            <img v-else :src="documentTypeWiseThumbnail(file.fileType.toLowerCase())" />
          </span>
          <span v-else>
            <img :src="documentTypeWiseThumbnail(file.fileType.toLowerCase())" />
          </span>
          <div class="grey lighten-3 hover-item">
            <v-btn
              v-if="!isDisabled"
              icon
              small
              flat
              color="primary"
              class="elevation-0 upload-img-action-btn ma-0 btn-remove"
              @click.native.stop="removeFile(file)"
            >
              <v-icon class="md-icon">delete</v-icon>
            </v-btn>
            <v-btn
              v-if="showDownloadButton(file.fileURL)"
              icon
              flat
              small
              color="primary"
              class="elevation-0 upload-img-action-btn ma-0 btn-download"
              @click.native.stop="downloadFile(file.fileURL)"
            >
              <v-icon class="md-icon">get_app</v-icon>
            </v-btn>
          </div>
        </div>
      </span>
    </div>
    <v-dialog v-model="uploadCarousel" max-width="50%">
      <v-card>
        <v-btn icon flat color="primary" class="uploadCarousel-btn white" @click="uploadCarousel = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-carousel :cycle="false">
          <v-carousel-item
            v-for="(item, i) in selectedFileList.filter(
              (e) =>
                (typeof e.fileURL === 'string' && e.fileType.toLowerCase() === 'png') ||
                e.fileType.toLowerCase() === 'jpg' ||
                e.fileType.toLowerCase() === 'jpeg'
            )"
            :key="i"
            :src="item.fileURL"
          ></v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Shared from '@/common/shared'
import store from '@/store'
import ComplaintController from '@/api/complaintController'
import eventBus from '@/common/bus'
import DocumentController from '@/api/documentController'
import AddComplaintDocumentsModel from '@/models/requests/AddComplaintDocumentsModel'
import DeleteComplaintDocumentModel from '@/models/requests/DeleteComplaintDocumentModel'
import { logger } from '@/plugins/datadog'
import { BlobServiceClient } from '@azure/storage-blob'

interface IFile {
  fileName: string
  fileURL: File | string
  fileType: string
}

@Component
export default class ComplaintDocumentUpload extends Vue {
  public selectedFileList: IFile[] = []
  @Prop({ default: '' }) private id: string // Id = complaint Id
  @Prop({ default: '' }) private jobId: string
  @Prop({ default: false }) private isDisabled: boolean
  @Prop() private documentList: string[]
  @Prop() private isComplaintLogged: boolean
  private fileErrorMessage = ''
  private uploadCarousel = false
  private fileName: any = ''
  private documentsToBeUploaded: IFile[] = []
  private successfullyUploadedDocumentList: IFile[] = []
  private uploadedDocumentURL: string[] = []
  private snackbarTimeout = 3000
  private snackbar = false
  private snackbarText = ''
  private uploadDocumentInProgress = false

  public uploadDocuments() {
    this.documentsToBeUploaded = []
    this.selectedFileList
      .filter((s) => typeof s.fileURL !== 'string')
      .map((file) => {
        this.documentsToBeUploaded.push(file)
      })
    this.documentsToBeUploaded.forEach(async (document) => {
      await this.uploadSingleDocument(document)
    })
  }

  public async uploadSingleDocument(document: IFile, fromCreateDocument = false) {
    const blobName = `${this.id}/${Shared.generateGuid()}.${document.fileName.split('.').at(-1)}`
    const token = await DocumentController.GetSASTokenToUploadComplaintDocument(this.jobId, blobName)
    if (!token) {
      this.saveUploadedDocuments()
      return
    }

    try {
      const containerName = 'complaints'
      const blobUri = token.split(`/${containerName}`)[0]
      const sasToken = token.split('?')[1]
      await new BlobServiceClient(`${blobUri}?${sasToken}`)
        .getContainerClient(containerName)
        .getBlockBlobClient(blobName)
        .uploadData(document.fileURL as Blob)

      this.successfullyUploadedDocumentList.push(document)
      this.uploadedDocumentURL.push(token.split('?')[0])

      if (this.documentsToBeUploaded.length === this.successfullyUploadedDocumentList.length || fromCreateDocument) {
        if (this.isComplaintLogged) {
          this.addComplaintDocuments(null)
        } else {
          this.$emit('uploadDocument', this.uploadedDocumentURL)
        }
      }
    } catch (error) {
      this.saveUploadedDocuments()
      const message = `Error saving complaint document`
      eventBus.$emit('errorHandler', message, true)
      logger.error(message, { error, jobId: this.jobId })
    } finally {
      this.uploadDocumentInProgress = false
    }
  }

  private mounted() {
    this.complaintFileListChanged()
  }

  private get getAttachmentSize(): number {
    return store.Instance.state.Environment.AudioAttachmentSize
  }

  private pickFile() {
    const fileError = this.$validator.errors.items.find((a) => a.field === 'file')
    if (fileError) {
      fileError.msg = ''
    }
    const file = this.$refs.fileUpload as any
    file.click()
  }

  private onFilePicked(event: any) {
    const selectedFileList: any = event.target.files
    const self = this
    this.uploadDocumentInProgress = true
    for (const file of selectedFileList) {
      if (file !== undefined) {
        const fileName = file.name.split(' ').join('_')
        if (fileName.lastIndexOf('.') <= 0) {
          return
        }
        const existingFile: any = self.selectedFileList.find((e) => e.fileName === fileName)
        if (existingFile) {
          this.fileValidationMessage(null, 'file with name ' + fileName + ' already exist.')
        } else {
          this.fileErrorMessage = ''
          const fr = new FileReader()
          fr.readAsDataURL(file)
          fr.addEventListener('load', async () => {
            if (self.fileErrorMessage === null || self.fileErrorMessage === '') {
              // save file to local filelist.
              const fileSelected: any = {}
              fileSelected.fileName = fileName
              fileSelected.fileURL = file
              fileSelected.fileType = fileName.substr(fileName.lastIndexOf('.') + 1)
              if (this.id) {
                this.documentsToBeUploaded.push(fileSelected)
                await this.uploadSingleDocument(fileSelected)
              } else {
                self.selectedFileList.push(fileSelected)
                self.uploadDocumentInProgress = false
              }
              self.fileName = ''
              const fileUpload = this.$refs.fileUpload as any
              fileUpload.value = null
            }
          })
        }
      }
    }
  }

  // return validation message on file selection if any.
  private fileValidationMessage(err: any, fileErrorMessage: any) {
    if (err && err.collect('file').length > 0) {
      const message: string = err.collect('file')[0]
      this.fileErrorMessage = message
      return message
    } else if (fileErrorMessage !== '') {
      this.fileErrorMessage = fileErrorMessage
      return this.fileErrorMessage
    }
  }

  private removeFile(file: IFile) {
    if (typeof file.fileURL === 'object') {
      // remove file from local filelist.
      const fileToRemove: IFile | undefined = this.selectedFileList.find((e) => e.fileName === file.fileName)
      if (fileToRemove) {
        this.selectedFileList.splice(this.selectedFileList.indexOf(fileToRemove), 1)
      }
    } else {
      // remove file from azure.
      Shared.confirmationPopup.open(
        'Are you sure you want to delete this document?',
        '',
        '',
        '',
        '',
        this,
        'deleteUploadedFile',
        file
      )
    }
    const fileUpload = this.$refs.fileUpload as any
    fileUpload.value = null
  }

  private deleteUploadedFile(file: IFile) {
    const self = this
    const fileUrl = (file.fileURL as string).split('?')[0]
    this.uploadDocumentInProgress = true
    const deleteComplaintDocument: DeleteComplaintDocumentModel = new DeleteComplaintDocumentModel()
    deleteComplaintDocument.jobId = this.jobId
    deleteComplaintDocument.complaintId = this.id
    deleteComplaintDocument.documentURL = fileUrl
    ComplaintController.DeleteComplaintDocument(deleteComplaintDocument)
      .then((res: boolean) => {
        if (res) {
          self.selectedFileList = self.selectedFileList.filter((x) => x.fileURL !== file.fileURL)
          this.$emit('deleteDocument', file.fileName)
          this.snackbarText = 'Document Deleted Successfully!'
          this.snackbar = true
        }
        this.uploadDocumentInProgress = false
      })
      .catch((e) => {
        eventBus.$emit('errorHandler', 'Error deleting complaint document, please try again', true)
      })
  }

  private downloadFile(fileURL: string) {
    // get the document of url and open it in new tab
    window.open(fileURL, '_blank')
  }

  private showDownloadButton(fileURL: string | File) {
    // show download button once complaint is saved and document uploaded to server
    return typeof fileURL === 'string' && fileURL.includes(this.jobId) && this.id ? true : false
  }

  private documentTypeWiseThumbnail(fileType: string) {
    if (
      fileType === 'pdf' ||
      fileType === 'doc' ||
      fileType === 'docx' ||
      fileType === 'xls' ||
      fileType === 'xlsx' ||
      fileType === 'mp3' ||
      fileType === 'txt' ||
      fileType === 'ppt' ||
      fileType === 'pptx' ||
      fileType === 'svg' ||
      fileType === 'avi' ||
      fileType === 'csv' ||
      fileType === 'zip' ||
      fileType === 'mp4' ||
      fileType === 'jpg' ||
      fileType === 'jpeg' ||
      fileType === 'png'
    ) {
      fileType =
        fileType === 'jpeg'
          ? 'jpg'
          : fileType === 'xlsx'
          ? 'xls'
          : fileType === 'pptx'
          ? 'ppt'
          : fileType === 'docx'
          ? 'doc'
          : fileType
      return '/img/' + fileType + '.svg'
    }
    return '/img/file.svg'
  }

  @Watch('documentList.length')
  private complaintFileListChanged() {
    if (this.documentList && this.documentList.length > 0) {
      if (this.documentList.length > this.selectedFileList.length) {
        this.setSelectedFileList()
      }
      if (this.documentList.length === this.selectedFileList.length) {
        const selectedFileList = this.selectedFileList.filter((obj) => typeof obj.fileURL === 'object')
        if (selectedFileList.length > 0) {
          this.selectedFileList = this.selectedFileList.filter((obj) => typeof obj.fileURL !== 'object')
          this.setSelectedFileList()
        }
      }
    }
    return this.selectedFileList
  }

  private setSelectedFileList() {
    this.documentList.forEach(async (file) => {
      const url: string = file.substring(file.indexOf(this.jobId))
      const fileIndex = this.selectedFileList.findIndex((e) => e.fileName === url.substring(url.lastIndexOf('/') + 1))
      if (fileIndex === -1) {
        if (file.indexOf('?') > 0) {
          return
        }
        const fileUrl: string = file.substring(file.indexOf(this.jobId))
        DocumentController.GetSASTokenForRecordingUrl(fileUrl)
          .then((res: string | null) => {
            if (res) {
              const fileName: string = fileUrl.substring(fileUrl.lastIndexOf('/') + 1)
              const index = this.selectedFileList.findIndex((e) => e.fileName === fileName)
              if (index === -1) {
                const fileSelected: any = {}
                fileSelected.fileName = fileName
                fileSelected.fileURL = res
                fileSelected.fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
                this.selectedFileList.push(fileSelected)
              }
            }
          })
          .catch((e) => {
            eventBus.$emit('errorHandler', 'Error loading complaint document, please try again', true)
          })
      }
    })
  }

  private saveUploadedDocuments() {
    if (this.successfullyUploadedDocumentList.length > 0) {
      this.addComplaintDocuments('Error in Uploading Document!')
    } else {
      this.showErrorMessage('Error in Uploading Document!')
    }
  }

  private showErrorMessage(error: string) {
    // show error message
    if (error) {
      eventBus.$emit('errorHandler', error, true)
    }
  }

  private addComplaintDocuments(error: string | null) {
    const addComplaintDocuments: AddComplaintDocumentsModel = new AddComplaintDocumentsModel()
    addComplaintDocuments.jobId = this.jobId
    addComplaintDocuments.complaintId = this.id
    addComplaintDocuments.documentURLs = this.uploadedDocumentURL
    ComplaintController.AddComplaintDocuments(addComplaintDocuments)
      .then((res: boolean) => {
        if (res) {
          this.successfullyUploadedDocumentList = []
          if (error !== null) {
            this.showErrorMessage(error)
          }
          this.$emit('uploadDocument', this.uploadedDocumentURL)
          this.uploadedDocumentURL = []
          this.documentsToBeUploaded = []
          this.uploadDocumentInProgress = false
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting complaint document(s), please try again', true)
      })
  }
}
</script>

<style type="text/css" scoped>
.complaint-files span {
  position: relative;
  margin-right: 10px;
  display: inline-flex;
  border-radius: 50%;
  height: 60px;
  border: 2px solid #f1f1f1;
  width: 60px;
  vertical-align: text-bottom;
  margin-bottom: 10px;
  cursor: pointer;
}
.complaint-files span img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.complaint-files span.filedoc img {
  height: 40px;
  width: 40px;
  margin: auto;
}
.image-action {
  min-width: auto !important;
}
.hover-content-section .hover-item {
  display: none;
  position: absolute;
}
.hover-content-section {
  position: relative;
}
.hover-content-section:hover .hover-item {
  display: block;
  position: absolute;
  top: -20px;
  left: 0px;
  right: 0;
  text-align: center;
}
</style>
