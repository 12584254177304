import Vue, { PluginFunction } from 'vue'
import App from './App.vue'
import router from './router'
import StoreWrapper from './store'
import Loader from '@/loader'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '../public/assets/css/style.css'
import { GetTheme } from '@/common/themes'
import VeeValidate from 'vee-validate'
import eventBus from '@/common/bus'
import validationMessages from '@/common/validationMessages'
import loadCustomValidators from '@/common/customValidators'
import * as LogLevel from 'loglevel'

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc'

import { createLaunchDarkly } from './plugins/launch-darkly'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { LDEvaluationDetail } from 'launchdarkly-js-client-sdk'

import { startDatadog } from './plugins/datadog'
import { datadogRum } from '@datadog/browser-rum'

// Import stripe here so it gets loaded before the app starts. This is recommended because the library observes user behavior for fraud detection.
// https://github.com/stripe/stripe-js#ensuring-stripejs-is-available-everywhere
import '@stripe/stripe-js'

// Hides the ridiculous amount of logs from Twilio
LogLevel.getLogger('@twilio/voice-sdk').setDefaultLevel(LogLevel.levels.WARN)

// We used to have a feature that stored recent search results, but it included personal data and was not GDPR compliant.
// We have removed the feature, but we need to clear the data from local storage.
localStorage.removeItem('searchHistory')
localStorage.removeItem('searchContractorJobsHistory')
localStorage.removeItem('searchClientJobsHistory')

// Ensure all traces of old service worker are removed
navigator.serviceWorker.getRegistrations().then((registrations) => {
  for (const registration of registrations) {
    registration.unregister()
  }
})

startDatadog()

// NOTE: Placeholder for VueOffline globals
Vue.prototype.isOffline = false
Vue.prototype.isOnline = true

Vue.config.productionTip = false

Vue.config.errorHandler = (err, vm, info) => {
  // display error from here
  // if the errorCaptured hook itself throws an error, both this error and the original captured error are sent to the global config.errorHandler
  const capturedContext = {
    component: vm.$options.name,
    method: info,
  }
  eventBus.$emit('errorHandler', err, false, '', capturedContext)
}

const env = Loader.InitializeEnvironment()
const oidcSettings = Loader.OidcSettings(env)

if (window.location.pathname.indexOf('/identity/silent-renew') === 0) {
  // process silent renew only, don't init vue app
  vuexOidcProcessSilentSignInCallback(oidcSettings)
  Loader.SetAppLoadingMessage('Silent Renew Complete', true)
} else {
  // for everything else start vue app
  Vue.use(VueFroala)
  Vue.use(Vuetify, { theme: GetTheme(undefined) })
  // field bag name added to resolve console error due to conflicts in 'fields prop' in different node module packages
  Vue.use(VeeValidate as unknown as PluginFunction<unknown>, {
    locale: 'en',
    fieldsBagName: 'vvFields',
    dictionary: validationMessages,
  })

  const store = StoreWrapper.LoadStore(env, oidcSettings)

  const launchDarkly = await createLaunchDarkly({
    envKey: process.env.VUE_APP_LaunchDarklyClientId!,
    store,
    ldOptions: {
      streaming: true,
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key: string, detail: LDEvaluationDetail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value)
          },
        },
      ],
    },
  })
  Vue.use(launchDarkly)

  loadCustomValidators()

  Vue.use(PiniaVuePlugin)
  const pinia = createPinia()

  new Vue({
    router,
    store,
    pinia,
    render: (h) => h(App),
    $_veeValidate: {
      validator: 'new',
    },
  }).$mount('#app')
}
