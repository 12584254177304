import { defineStore } from 'pinia'
import { ref } from 'vue'

import MaterialsController from '@/api/materials-controller'
import MaterialModel from '@/models/material/MaterialModel'

export const useMaterialsStore = defineStore('materials', () => {
  const materials = ref<MaterialModel[]>([])

  async function fetchMaterials() {
    materials.value = await MaterialsController.GetMaterials()
  }
  fetchMaterials()

  return {
    materials,
    fetchMaterials,
  }
})
