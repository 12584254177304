import { AssistedPayCaptureType } from '@/common/enums'
import AssistedPaymentsController from '@/api/assisted-payments-controller'

export default class AgentAssistCardDetail {
  public constructor({ format, type }) {
    this.format = format
    this.type = type

    this.defaultValue = this.format()
    this.value = this.defaultValue
    this.isSet = false
    this.awaiting = false
  }

  private format: (a?: string) => string
  public value: string
  public defaultValue: string
  public formattedValue: string
  public isSet: boolean
  public awaiting: boolean
  public type: AssistedPayCaptureType

  public async update(callSid: string, paymentSid: string, idempotencyKey: string): Promise<boolean> {
    this.reset()
    return await AssistedPaymentsController.Capture(callSid, paymentSid, this.type, idempotencyKey)
  }

  public setValue(value: string) {
    this.value = this.format(value)
  }

  public reset() {
    this.value = this.defaultValue
    this.isSet = false
    this.awaiting = false
  }
}
