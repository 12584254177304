<template>
  <v-flex xs3 class="contractcard-list">
    <v-layout wrap>
      <v-flex
        xs12
        :class="
          statusText === 'Pending'
            ? 'list-pending'
            : statusText === 'Accepted'
            ? 'list-accepted'
            : statusText === 'In progress'
            ? 'list-in-progress'
            : statusText === 'Completed'
            ? 'list-completed'
            : ''
        "
      >
        <template v-if="contractorJobList.length > 0">
          <v-card
            v-for="job in contractorJobList"
            :key="job.id"
            :class="getCardStyle(job)"
            class="card-box-shadow-none"
          >
            <v-card-title class="pa-2" primary-title>
              <span class="pl-1">
                <v-tooltip v-if="job.tradeId && getTradeDescById(job.tradeId)" bottom class="tradeDescription">
                  <template #activator="{ on }">
                    <v-icon small v-on="on">build</v-icon>
                  </template>
                  <span>
                    {{ job.tradeId ? getTradeDescById(job.tradeId) : '-' }}
                  </span>
                </v-tooltip>
              </span>
              <div class="pl-2">
                <h4 v-if="isUserRoleAdministrator">
                  Job: {{ job.jobId }}{{ job.totalVisit > 1 ? '-' + job.visitNumber : '' }}
                </h4>
                <h4 v-else>
                  Job: {{ job.jobId }}{{ contractorHasMultipleVisit(job.jobId) ? '-' + job.visitNumber : '' }}
                </h4>
              </div>
              <v-spacer></v-spacer>
              <h3 class="job-code grey--text text--darken-1">
                {{ job.postcode }}
              </h3>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    v-if="isContractorPageLoaded && statusText === 'Pending' && job.isContractorReAttend"
                    class="part-icon mr-0 btn-addAdditionalRequest"
                    icon
                    flat
                    color="primary"
                    small
                    v-on="on"
                    @click.stop="openAdditionalRequestDialog(job)"
                  >
                    <img src="/img/part.svg" />
                  </v-btn>
                </template>
                <span>
                  {{ job.previousEngineerVisitId ? 'Add additional request' : 'No visit created for the job' }}
                </span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-2 jb-detail-content">
              <div class="primary-title pb-2 primary-card-title">
                <h3 class="date-title">
                  <span style="padding-left: 3px; padding-right: 6px; width: 16px">
                    <v-tooltip v-if="getEmergencyDescription(job.forEmergencyTypeId, job.forEmergencyDetailId)" bottom>
                      <template #activator="{ on }">
                        <img
                          v-if="getEmergencyIcon(job.jobId)"
                          :src="getEmergencyIcon(job.jobId)"
                          class="category-icon-img"
                          alt
                          v-on="on"
                        />
                        <img v-else src="/img/emergency.svg" class="category-icon-img" alt v-on="on" />
                      </template>
                      <span class="emergency-detail">
                        {{ getEmergencyDescription(job.forEmergencyTypeId, job.forEmergencyDetailId) }}
                      </span>
                    </v-tooltip>
                    <template v-else>
                      <img src="/img/emergency.svg" class="category-icon-img emergency-not-found" alt />
                    </template>
                  </span>
                  <a href="javascript:void(0)" @click.prevent="openContractorJobDetailModal(job.jobId, job.id)">
                    {{ job.customerName }}
                  </a>
                </h3>
                <span class="primary-card-icons">
                  <v-tooltip v-if="showCallCustomerButton(job)" bottom>
                    <template #activator="{ on }">
                      <a
                        href="javascript:void(0)"
                        small
                        class="icon call-customer-icon"
                        color="white"
                        v-on="on"
                        @click.prevent="showProxyNumberDialogue(job)"
                      >
                        <v-icon small>phone</v-icon>
                      </a>
                    </template>
                    <span>Call customer</span>
                  </v-tooltip>
                  <v-tooltip v-if="job.isContractorReAttend" top>
                    <template #activator="{ on }">
                      <span class="return-visit" v-on="on">
                        <img src="/img/return-visit.svg" alt="" />
                      </span>
                    </template>
                    <span>Return Visit</span>
                  </v-tooltip>
                </span>
              </div>
              <div class="details-row">
                <v-icon>place</v-icon>
                <address>{{ job.address }}</address>
              </div>
            </v-card-text>
            <v-card-actions :class="getCardActionStyle(job)">
              <div class="card-alert">
                <v-tooltip v-if="job.isVulnerability" top>
                  <template #activator="{ on }">
                    <span :class="getHealthAndSafetyTooltipStyle(job.vulnerabilityLevel)" v-on="on">
                      <img src="/img/shield.svg" />
                    </span>
                  </template>
                  <span>
                    <b>Vulnerability Issue</b>
                  </span>
                </v-tooltip>
                <v-tooltip v-if="job.isHealthAffected" top>
                  <template #activator="{ on }">
                    <span :class="getHealthAndSafetyTooltipStyle(job.healthAndSafetyLevel)" v-on="on">
                      <img src="/img/health-care.svg" />
                    </span>
                  </template>
                  <span>
                    <b>Health and Safety Issue</b>
                  </span>
                </v-tooltip>
                <v-tooltip v-if="job.visitPreviouslyAbandoned" top>
                  <template #activator="{ on }">
                    <span class="red alert-inner-content" v-on="on">
                      <img src="/img/abandon.svg" />
                    </span>
                  </template>
                  <span>
                    <b>Abandoned Visit</b>
                  </span>
                </v-tooltip>
              </div>
              <v-layout wrap>
                <v-flex xs12>
                  <div v-if="!showEngineerDetails">
                    <span class="job-in-process-engineer engineer-Unassigned">
                      <img src="/img/unknownuser.png" />
                      <span>
                        {{ job.engineerName ? job.engineerName : 'Unassigned' }}
                      </span>
                    </span>
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        job.status === jobVisitStatus[jobVisitStatus.Pending] ||
                        job.status === jobVisitStatus[jobVisitStatus.Accepted]
                      "
                      class="select-engineer mb-0"
                    >
                      <v-select
                        v-model="job.engineerId"
                        :items="getEngineers(job.tradeId)"
                        return-object
                        item-value="id"
                        persistent-hint
                        hide-details
                        class="hide-option engineer-list"
                        :disabled="showETASetDialog(job)"
                        @change="assignEngineerToJob(job, $event)"
                        @click.native="onClickAssignEngineer(job)"
                      >
                        <template slot="selection" slot-scope="data">
                          <v-avatar>
                            <img v-if="data.item.profileImageThumbnailUrl" :src="data.item.profileImageThumbnailUrl" />
                            <img v-else src="/img/unknownuser.png" />
                          </v-avatar>
                          &nbsp;
                          {{ data.item.firstName + ' ' + data.item.lastName }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <template v-if="typeof data.item !== 'object'">
                            <v-list-tile-content v-text="data.item"></v-list-tile-content>
                          </template>
                          <template v-else>
                            <v-list-tile-avatar>
                              <img
                                v-if="data.item.profileImageThumbnailUrl"
                                :src="data.item.profileImageThumbnailUrl"
                              />
                              <img v-else src="/img/unknownuser.png" />
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                              <v-list-tile-title>
                                {{ data.item.firstName + ' ' + data.item.lastName }}
                              </v-list-tile-title>
                              <v-list-tile-sub-title>
                                {{ data.item.email }}
                              </v-list-tile-sub-title>
                            </v-list-tile-content>
                          </template>
                        </template>
                      </v-select>
                    </div>
                    <div v-else>
                      <span v-if="getEngineerDetail(job.engineerId)" class="job-in-process-engineer">
                        <img
                          :src="
                            getEngineerDetail(job.engineerId).profileImageThumbnailUrl
                              ? getEngineerDetail(job.engineerId).profileImageThumbnailUrl
                              : '/img/unknownuser.png'
                          "
                        />
                        <span>
                          {{
                            getEngineerDetail(job.engineerId)
                              ? getEngineerDetail(job.engineerId).firstName +
                                ' ' +
                                getEngineerDetail(job.engineerId).lastName
                              : ''
                          }}
                        </span>
                        <span>
                          {{ getEngineerDetail(job.engineerId) ? getEngineerDetail(job.engineerId).email : '' }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="pt-1 eta-info" :class="!showEngineerDetails ? 'mt-1' : ''">
                    <div v-if="!showETASetDialog(job)">
                      <v-icon small>date_range</v-icon>
                      <b>
                        ETA:
                        <span class="eta-date-from">
                          <span class="grey--text text--darken-1">From</span>
                          {{ getFormattedDate(job.etaFrom) }}
                        </span>
                        <span class="eta-date-to">
                          <span class="grey--text text--darken-1">&nbsp;To</span>
                          {{ getFormattedDate(job.etaTo) }}
                        </span>
                      </b>
                    </div>
                    <div v-else>
                      <v-btn small color="primary btn-setETA" @click="() => showETADialog(job)">Set ETA</v-btn>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </template>
        <div
          v-if="
            showLoadMore &&
            contractorJobList.length > 0 &&
            totalJobCount >= defaultLoadMoreRecordCount &&
            contractorJobList.length !== totalJobCount &&
            contractorJobList.length >= defaultLoadMoreRecordCount
          "
          class="task-action text-xs-center grey lighten-2 px-2"
        >
          <v-progress-linear v-if="loadMoreJobsStarted" :indeterminate="true" height="4"></v-progress-linear>
          <v-btn color="primary" flat small block @click="loadMore">
            <b>Load More</b>
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog
      v-if="statusText === 'Pending' && etaDialogOpen"
      v-model="etaDialogOpen"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <ETADialogContent :contractor-appointed-detail="etaDialogJob" @close="closeETADialog" />
    </v-dialog>
    <v-dialog
      v-if="contractorJobDetailDialog"
      v-model="contractorJobDetailDialog"
      max-width="1000"
      persistent
      content-class="v-dialog--scrollable contractorjob-details-dialog"
    >
      <ContractorJobDetail
        :parent-component="'ContractorJobCard'"
        :contractor-job-detail-item="contractorJobDetailModel"
        :engineers="engineers"
        @contractorJobDetailDialogClose="contractorJobDetailDialogClose"
      ></ContractorJobDetail>
    </v-dialog>
    <v-dialog v-if="showProxyNumber" v-model="showProxyNumber" max-width="400" persistent>
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Customer Number</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeProxyNumberDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog pa-3 customer-number">
          <v-flex v-show="!isProxyNumberLoaded" xs12>
            <v-select
              v-model="selectedContactNumber"
              :items="contactNumberList"
              label="Select Contact Number"
              item-text="label"
              item-value="id"
              class="contact-number"
            ></v-select>
          </v-flex>
          <v-flex v-show="isProxyNumberLoaded">
            <h3 v-if="proxyNumber" class="mb-2">Customer Phone: {{ proxyNumber }}</h3>
            <div class="mb-2">
              You may contact the customer from
              <b>{{ contractorNumber }}</b>
            </div>
            <span v-if="proxyExpiration">Session valid until: {{ getFormattedDate(proxyExpiration) }}</span>
          </v-flex>
        </v-card-text>
        <v-divider />
        <v-card-actions v-show="!isProxyNumberLoaded" class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat="flat" @click="closeProxyNumberDialog">Close</v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-saveProxyNumber"
            :loading="proxyInfoLoading"
            :disabled="proxyInfoLoading"
            @click="generateProxyNumber()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog - additional request -->
    <v-dialog
      v-if="additionalRequestDialog"
      v-model="additionalRequestDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <AdditionalRequest
        ref="additionalRequest"
        :previous-engineer-visit-detail="previousEngineerVisitDetail"
        :is-for-contractor-job-card="true"
        @closeAdditionalRequestDialog="closeAdditionalRequestDialog"
      ></AdditionalRequest>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarText }}
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-flex>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import { JobVisitStatus, HealthAndSafetyLevel } from '@/common/enums'
import EngineerController from '@/api/engineerController'
import EngineerModel from '@/models/contractor/EngineerModel'
import UpdateContractorAppointedDetail from '@/models/requests/UpdateContractorAppointedDetail'
import moment, { Moment } from 'moment'
import Shared from '@/common/shared'
import EngineerAvailabilityModel from '@/models/contractor/EngineerAvailabilityModel'
import ContractorJobDetailModel from '@/models/contractor/ContractorJobDetailModel'
import ContractorController from '@/api/contractorController'
import ContractorJobDetail from '@/components/ContractorJobDetail.vue'
import eventBus from '@/common/bus'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import storeGetters from '@/storeGetters'
import store from '@/store'
import InsurerController from '@/api/InsurerController'
import ClientJobModel from '@/models/client/ClientJobModel'
import ETADialogContent from '@/components/ETADialogContent.vue'
import AddMaterialRequest from '@/components/timeline/AddMaterialRequest.vue'
import MakeContractorProxyRequest from '@/models/MakeContractorProxyRequest'
import { ContactPreferenceEnum } from '@/common/enums'
import ContractorProxyModel from '@/models/contractor/ContractorProxyModel'
import TradeModel from '@/models/policyHolder/TradeModel'
import AdditionalRequest from '@/components/timeline/AdditionalRequest.vue'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'

interface IContactNumber {
  id: number
  label: string
  contactNumber: string
}

@Component({
  components: {
    ContractorJobDetail,
    AddMaterialRequest,
    ETADialogContent,
    AdditionalRequest,
  },
})
export default class ContractorJobCard extends Vue {
  @Prop() private statusText: string
  @Prop() private contractorJobsByVisitStatus: ContractorAppointedModel[]
  @Prop({ default: () => [] }) private engineers: EngineerModel[]
  @Prop() private selectedJobToOpen: string
  @Prop({ default: true }) private showEngineerDetails: boolean
  @Prop() private emergencies: EmergencyModel[]
  @Prop() private id: string | number // Id -> ContractorId for ContractorJobs, InsurerId for ClientJobs
  @Prop({ default: 0 }) private totalJobCount: number
  @Prop({ default: false }) private showLoadMore: number
  @Prop() private visitType: string
  private jobVisitStatus = JobVisitStatus
  private engineerModel: EngineerModel | null = null
  private contractorJobDetailDialog = false
  private contractorJobDetailModel: ContractorJobDetailModel | null = null
  private contractorAppointedDetail: any = []
  private emergenciesIcon: EmergencyModel[] = []
  private contractorJobList: ContractorAppointedModel[] = []
  private engineerList: EngineerModel[] = []
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText: string | null = ''
  private loadMoreJobsStarted = false
  private defaultLoadMoreRecordCount: number = Shared.defaultLoadMoreRecordCount
  private loadMoreButtonClickedCount = 1
  private additionalRequestDialog = false
  private isLoading = false
  private previousEngineerVisitDetail: EngineerVisitDetail = new EngineerVisitDetail()

  private etaDialogOpen = false
  private etaDialogJob: ContractorAppointedModel | null = null

  private proxyNumber = ''
  private showProxyNumber = false
  private proxyInfoLoading = false
  private proxyExpiration: Moment | null = null
  private contractorNumber = ''

  private contactPreferenceEnum = ContactPreferenceEnum
  private selectedContactNumber: number | null = null
  private contactNumberList: IContactNumber[] = []
  private selectedJobId = ''
  private contractorAppointedId = ''
  private isProxyNumberLoaded = false

  private created() {
    this.updateEngineers()
  }

  @Watch('engineers')
  private updateEngineers() {
    if (this.engineers) {
      this.engineerList = Object.assign([], this.engineers)
    }
    if (this.engineerList && !this.engineerList.find((e) => e.id === null)) {
      this.engineerList.splice(0, 0, this.getDefaultOption())
    }
  }

  private get getContractorJobs(): ContractorAppointedModel[] {
    return this.contractorJobsByVisitStatus
  }

  @Watch('getContractorJobs')
  private onContractorJobsChange(newValue: ContractorAppointedModel[], oldValue: ContractorAppointedModel[]) {
    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
      return
    }
    this.contractorJobList = this.sortContractorJobs(this.getContractorJobs)
    this.getEmergencyIconList()
    if (this.selectedJobToOpen) {
      const selectedJobDetails = this.contractorJobList.find((x) => x.id === this.selectedJobToOpen)
      if (selectedJobDetails) {
        this.openContractorJobDetailModal(selectedJobDetails.jobId, selectedJobDetails.id)
      }
    }
  }

  private getEmergencyIconList() {
    if (this.emergencies.length > 0 && this.getContractorJobs) {
      const jobIds: string[] = []
      this.getContractorJobs.forEach((a: any) => {
        if (jobIds.indexOf(a.forEmergencyTypeId) < 0) {
          jobIds.push(a.forEmergencyTypeId)
        }
      })
      this.emergencies.forEach((e: EmergencyModel) => {
        if (e.emergencyDetails) {
          e.emergencyDetails.some((d: any) => {
            if (d.typeId && jobIds.indexOf(d.typeId) > -1) {
              this.emergenciesIcon.push(e)
              return true
            }
            return false
          })
        }
      })
    }
  }

  private getHealthAndSafetyTooltipStyle(level: string) {
    if (level === HealthAndSafetyLevel[HealthAndSafetyLevel.High]) {
      return 'red alert-inner-content'
    } else if (level === HealthAndSafetyLevel[HealthAndSafetyLevel.Medium]) {
      return 'orange alert-inner-content'
    } else {
      return 'grey alert-inner-content'
    }
  }

  private getCardStyle(job: ContractorAppointedModel) {
    if (
      (job.isVulnerability && job.vulnerabilityLevel === HealthAndSafetyLevel[HealthAndSafetyLevel.High]) ||
      (job.isHealthAffected && job.healthAndSafetyLevel === HealthAndSafetyLevel[HealthAndSafetyLevel.High])
    ) {
      return 'red lighten-5 elevation-0'
    } else if (
      (job.isVulnerability && job.vulnerabilityLevel === HealthAndSafetyLevel[HealthAndSafetyLevel.Medium]) ||
      (job.isHealthAffected && job.healthAndSafetyLevel === HealthAndSafetyLevel[HealthAndSafetyLevel.Medium])
    ) {
      return 'orange lighten-5 elevation-0'
    } else {
      return 'grey lighten-5 elevation-0'
    }
  }

  private getCardActionStyle(job: ContractorAppointedModel) {
    if (
      (job.isVulnerability && job.vulnerabilityLevel === HealthAndSafetyLevel[HealthAndSafetyLevel.High]) ||
      (job.isHealthAffected && job.healthAndSafetyLevel === HealthAndSafetyLevel[HealthAndSafetyLevel.High])
    ) {
      return 'red lighten-4 px-2 alert-content-icon'
    } else if (
      (job.isVulnerability && job.vulnerabilityLevel === HealthAndSafetyLevel[HealthAndSafetyLevel.Medium]) ||
      (job.isHealthAffected && job.healthAndSafetyLevel === HealthAndSafetyLevel[HealthAndSafetyLevel.Medium])
    ) {
      return 'orange lighten-4 px-2 alert-content-icon'
    } else {
      return 'grey lighten-2 px-2 alert-content-icon'
    }
  }

  private getEmergencyIcon(jobId: string) {
    const job: any = this.contractorJobList.find((e) => e.jobId === jobId)
    const emergency: any = this.emergenciesIcon.find((e) => e.emergencyId === job.forEmergencyTypeId)
    if (emergency !== undefined) {
      return emergency.fileURL
    } else {
      return ''
    }
  }

  private getEmergencyDescription(emergencyTypeId: number, emergencyDetailId: number) {
    const emergency: any = this.emergenciesIcon.find((e) => e.emergencyId === emergencyTypeId)
    if (emergency !== undefined) {
      const emergencyDetail = emergency.emergencyDetails.find((d) => d.emergencyDetailId === emergencyDetailId)
      return emergency.description + (emergencyDetail ? ' (' + emergencyDetail.description + ')' : '')
    }
    return ''
  }

  private getDefaultOption() {
    const engineer = new EngineerModel()
    engineer.id = null
    engineer.firstName = 'Unassigned'
    engineer.lastName = ''
    engineer.profileImageThumbnailUrl = '/img/unknownuser.png'
    engineer.engineerAvailability = []
    return engineer
  }

  private getTradeDescById(tradeId: number) {
    const trade: TradeModel | undefined = storeGetters.getTrades().find((e) => e.tradeId === tradeId)
    return trade ? trade.description : ''
  }

  private assignEngineerToJob(job: ContractorAppointedModel, event: EngineerModel) {
    const isEngineerAvailable = this.checkEngineerAvailability(job.etaFrom, job.etaTo, event) // check engineer availability while assigning Job
    if (!isEngineerAvailable) {
      return
    }
    const engineerid: any = job.engineerId
    const previouslyAssignedEngineerId = engineerid && typeof engineerid === 'object' ? engineerid.id : engineerid
    if (
      (event.id === null && (previouslyAssignedEngineerId === '' || previouslyAssignedEngineerId === null)) ||
      previouslyAssignedEngineerId === event.id
    ) {
      return
    }
    this.engineerModel = event
    const updateContractorAppointed = new UpdateContractorAppointedDetail(job.id, job.jobId)
    updateContractorAppointed.engineerId = event.id
    if (!updateContractorAppointed.engineerId) {
      updateContractorAppointed.status = JobVisitStatus[JobVisitStatus.Pending]
    }
    EngineerController.AssignEngineerToJob(updateContractorAppointed)
      .then((res: boolean) => {
        if (res) {
          job.status = updateContractorAppointed.status
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error assigning job to an engineer, please try again', true)
      })
  }

  private onClickAssignEngineer(job: ContractorAppointedModel) {
    const engineers: any = this.getEngineers(job.tradeId)
    engineers.forEach((engineer) => {
      if (engineer.id === null) {
        engineer.disabled = false
      } else {
        const isEngineerAvailable = this.checkEngineerAvailability(job.etaFrom, job.etaTo, engineer)
        engineer.disabled = !isEngineerAvailable // set disabled property based on engineer availability
      }
    })
  }

  private getEngineers(tradeId: number) {
    if (this.engineerList.length > 0) {
      const engineerList: EngineerModel[] = this.engineerList.filter((e) => e.engineerAvailability !== null)
      return engineerList.filter(
        (e: EngineerModel) =>
          e.engineerAvailability.findIndex((t: EngineerAvailabilityModel) => t.tradeId === tradeId) > -1 ||
          e.id === null
      )
    } else {
      return []
    }
  }

  private checkEngineerAvailability(etaFrom: moment.Moment | null, etaTo: moment.Moment | null, engineer: any) {
    // check engineer availability compared to job eta.
    let isEngineerAvailable = false
    if (etaFrom && etaTo) {
      if (engineer) {
        if (
          engineer.outsideToDate &&
          engineer.outsideFromDate &&
          engineer.outsideToDate.isValid() &&
          engineer.outsideFromDate.isValid()
        ) {
          const etaFromDate: string = etaFrom.toISOString().slice(0, 10)
          const outsideFromDate: string = engineer.outsideFromDate.toISOString().slice(0, 10)
          const outsideToDate: string = engineer.outsideToDate.toISOString().slice(0, 10)
          if (etaFromDate === outsideFromDate || etaFromDate === outsideToDate) {
            isEngineerAvailable = false
          } else {
            isEngineerAvailable = etaFrom.isBetween(engineer.outsideFromDate, engineer.outsideToDate) ? false : true
          }
        } else {
          isEngineerAvailable = true
        }
      }
    }
    return isEngineerAvailable
  }

  private getEngineerDetail(engineerId: string) {
    const assignedEngineer: EngineerModel | undefined = this.engineerList.find((e) => e.id === engineerId)
    if (assignedEngineer) {
      return assignedEngineer
    }
  }

  private getFormattedDate(date: Moment): string {
    if (date) {
      return Shared.getFormatedDate(moment(date), store.Instance.state.Environment.DateTimeFormat)
    }
    return ''
  }

  private contractorJobDetailDialogClose(): void {
    this.contractorJobDetailDialog = false
    this.contractorJobDetailModel = null
  }

  private openContractorJobDetailModal(jobId: string, contractorAppointedDetailId: string): void {
    const self = this
    self.contractorJobDetailDialog = true
    ContractorController.GetContractorJobDetailWithEmergencyDefinition(jobId, contractorAppointedDetailId)
      .then((res: ContractorJobDetailModel | null) => {
        if (res) {
          self.contractorJobDetailModel = res
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor jobDetail, please try again', false)
        this.showSnackbarMessage('Error in loading contractor jobDetail!')
        this.contractorJobDetailDialog = false
      })
  }

  // Check if userRole is Administrartor
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private contractorHasMultipleVisit(jobId: string) {
    if (this.getContractorJobs && this.getContractorJobs.filter((c) => c.jobId === jobId).length > 1) {
      return true
    }
    return false
  }

  private loadMore() {
    // load jobs based on status, insurerId, recordCount for client jobs
    this.loadMoreJobsStarted = true
    const recordCount = this.loadMoreButtonClickedCount * this.defaultLoadMoreRecordCount
    const status =
      this.statusText.toLowerCase() === 'in progress' ? JobVisitStatus[JobVisitStatus.InProgress] : this.statusText
    InsurerController.GetAllClientJobs(Number(this.id), this.visitType, recordCount, status)
      .then((res: ClientJobModel | null) => {
        if (res && res.clientJobs.length > 0) {
          this.loadMoreButtonClickedCount++
          eventBus.$emit('jobsOnLoadMoreClick', res.clientJobs, status)
        }
        this.loadMoreJobsStarted = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading client job(s), please try again', true)
        this.loadMoreJobsStarted = false
      })
  }

  private sortContractorJobs(jobList: ContractorAppointedModel[]) {
    // sort data eta vise
    jobList.sort((a, b) => {
      a.etaFrom = typeof a.etaFrom === 'string' ? moment(a.etaFrom) : a.etaFrom
      b.etaFrom = typeof b.etaFrom === 'string' ? moment(b.etaFrom) : b.etaFrom
      if (a.etaFrom && b.etaFrom && a.etaFrom.isBefore(b.etaFrom)) {
        return -1
      } else if (a.etaFrom && b.etaFrom && a.etaFrom.isAfter(b.etaFrom)) {
        return 1
      } else {
        return 0
      }
    })
    return jobList
  }

  private showETADialog(job: ContractorAppointedModel) {
    this.etaDialogJob = job
    this.etaDialogOpen = true
  }

  private closeETADialog() {
    this.etaDialogJob = null
    this.etaDialogOpen = false
  }

  private openAdditionalRequestDialog(ContractorAppointedDetails: ContractorAppointedModel) {
    if (!ContractorAppointedDetails.previousEngineerVisitId) {
      return
    }
    // assign common property of contractor appointed to Engineer Visit Detail model
    Object.keys(ContractorAppointedDetails).forEach(
      (key) => (this.previousEngineerVisitDetail[key] = ContractorAppointedDetails[key])
    )
    this.previousEngineerVisitDetail.id = ContractorAppointedDetails.previousEngineerVisitId
    this.additionalRequestDialog = true
  }

  private closeAdditionalRequestDialog() {
    this.additionalRequestDialog = false
  }

  private async showProxyNumberDialogue(job: ContractorAppointedModel) {
    // if no contact numbers found, show snackbar message
    if (!job.contactNumbers || job.contactNumbers.length === 0) {
      this.showSnackbarMessage('Contractor contact number(s) not found.')
      return
    }
    // push contact numbers into local array
    if (this.contactNumberList.length === 0 && job.contactNumbers && job.contactNumbers.length > 0) {
      job.contactNumbers.forEach((contractorNumber, index) => {
        this.contactNumberList.push({
          id: index,
          label: contractorNumber.phoneNumber + ' (' + contractorNumber.label + ')',
          contactNumber: contractorNumber.phoneNumber,
        })
      })
    }
    if (!this.selectedContactNumber || this.contractorAppointedId !== job.id) {
      const mainNumberIndex = job.contactNumbers.findIndex((c) => c.label.trim().toLowerCase() === 'main number')
      this.selectedContactNumber =
        mainNumberIndex > -1 ? this.contactNumberList[mainNumberIndex].id : this.contactNumberList[0].id
    }
    this.selectedJobId = job.jobId
    this.contractorAppointedId = job.id
    this.showProxyNumber = true
  }

  private async generateProxyNumber() {
    this.proxyInfoLoading = true
    const req: MakeContractorProxyRequest = new MakeContractorProxyRequest()
    req.jobId = this.selectedJobId
    req.contractorAppointedId = this.contractorAppointedId
    // pass selected contact number in API call
    req.contactNumber = this.contactNumberList[this.selectedContactNumber!].contactNumber
    try {
      const res: ContractorProxyModel | string = await ContractorController.MakeContractorProxyCall(req)
      if (res && typeof res === 'object' && res.proxyNumber && res.dateExpiry) {
        this.proxyNumber = res.proxyNumber
        this.proxyExpiration = res.dateExpiry
        this.contractorNumber = res.contractorNumber
        this.contractorJobList = [...this.contractorJobList]
        this.proxyInfoLoading = false
        this.isProxyNumberLoaded = true
      } else if (typeof res === 'string') {
        this.resetProxyDetails(res)
      }
    } catch (err) {
      this.resetProxyDetails((err as Error).message)
    }
  }

  private closeProxyNumberDialog() {
    // reset values on dialog close
    if (!this.isProxyNumberLoaded) {
      this.selectedContactNumber = null
    }
    this.selectedJobId = ''
    this.isProxyNumberLoaded = false
    this.showProxyNumber = false
    this.resetProxyDetails()
  }

  private resetProxyDetails(errorMessage = '') {
    if (errorMessage) {
      this.showSnackbarMessage(errorMessage)
    }
    this.proxyInfoLoading = false
    this.proxyNumber = ''
    this.proxyExpiration = null
    this.contractorNumber = ''
  }

  private showSnackbarMessage(message: string) {
    this.snackbarText = message
    this.snackbar = true
  }

  private get isUserRoleContractor(): boolean {
    return store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  private showCallCustomerButton(job: ContractorAppointedModel) {
    return this.$route &&
      this.$route.name &&
      this.$route.name.toLowerCase() !== 'clientmanagement' &&
      this.isUserRoleContractor &&
      job.customerContactPreference !== ContactPreferenceEnum[ContactPreferenceEnum.Email] &&
      store.Instance.state.SessionDetail.detailRecord.EngineerDetail.contractorId === job.contractorId
      ? true
      : false
  }

  private get isContractorPageLoaded(): boolean {
    if (
      this.$route &&
      this.$route.name &&
      (this.$route.name.toLowerCase() === 'clientmanagement' ||
        this.$route.name.toLowerCase() === 'clientmanagementwithfinancialsummary' ||
        this.$route.name.toLowerCase() === 'clientmanagementwithbordereaudetail')
    ) {
      return false
    }
    return true
  }

  private showETASetDialog(job): boolean {
    return !this.showEngineerDetails
      ? false
      : !(
          this.statusText !== 'Pending' ||
          (job.etaFrom ? moment(job.etaFrom).isValid() : false) ||
          (job.etaTo ? moment(job.etaTo).isValid() : false)
        )
  }
}
</script>

<style scoped>
.contractcard-list >>> .v-card .details-row {
  position: relative;
  padding-left: 25px;
}
.contractcard-list >>> .v-card .details-row i {
  position: absolute;
  left: -1px;
  top: -3px;
}
.contractcard-list >>> .v-card {
  margin-bottom: 20px;
}
.contractcard-list >>> .layout .v-card {
  margin-bottom: 15px;
}
.contractcard-list {
  margin-bottom: 30px !important;
  min-height: 75vh;
}
.contractcard-list .list-pending .v-card {
  border-left: 3px solid #ff5252 !important;
}
.contractcard-list .list-in-progress .v-card {
  border-left: 3px solid orange !important;
}
.contractcard-list .list-accepted .v-card {
  border-left: 3px solid #3f51b5 !important;
}
.contractcard-list .list-completed .v-card {
  border-left: 3px solid #4caf50 !important;
}
.select-engineer >>> .v-input {
  padding-top: 0px;
  margin-top: 0;
}
.select-engineer >>> .v-avatar img {
  height: 30px;
  width: 30px;
  border: 1px solid #bdbdbd;
}
.select-engineer >>> .v-input__slot:before,
.select-engineer >>> .v-input__slot:after,
.select-engineer >>> .v-input__slot .v-input__append-inner {
  opacity: 0;
}
.select-engineer:hover >>> .v-input__slot:before,
.select-engineer >>> .v-input__slot:after,
.select-engineer:hover >>> .v-input__slot .v-input__append-inner {
  opacity: 1;
}
.select-engineer .v-input,
.v-select .v-select__selections,
.select-engineer >>> .v-input__control {
  max-height: 36px;
}
.select-engineer .v-input .v-avatar {
  height: 35px !important;
  width: 35px !important;
}
.select-engineer >>> .v-autocomplete .v-select__selections {
  color: #000;
  font-size: 14px;
  line-height: 20px;
}
.contractcard-list h3.date-title {
  position: relative;
}
.contractcard-list h3.date-title .category-icon-img {
  width: 17px;
}
.contractcard-list .eta-info {
  font-size: 13px;
  vertical-align: top;
  position: relative;
  padding-left: 30px;
}
.contractcard-list .eta-info >>> .v-icon {
  vertical-align: text-bottom;
  position: absolute;
  left: 5px;
  top: 7px;
}
.job-in-process-engineer {
  position: relative;
  padding-left: 40px;
  display: inline-block;
}
.job-in-process-engineer img {
  position: absolute;
  height: 25px;
  width: 25px;
  border: 1px solid #000;
  top: 5px;
  left: 0px;
  border-radius: 50%;
}
.job-in-process-engineer > span {
  display: inline-block;
  width: 100%;
  word-break: break-all;
}
.job-in-process-engineer.engineer-Unassigned {
  padding-top: 2px;
}
.job-in-process-engineer.engineer-Unassigned img {
  top: 0px !important;
}
/* .contractcard-list .eta-date-to{display: block; padding-left: 73px;} */

/* JobTile alert style */
.contractcard-list h3.date-title {
  position: relative;
  width: 100%;
  vertical-align: top;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.alert-errors >>> .alert,
.alert-errors h3 {
  font-size: 14px;
}

.alert-errors >>> .alert {
  padding: 8px 16px;
  border: 0px;
}

.contractcard-list >>> .v-card__title {
  position: relative;
}

.alert-content-icon {
  position: relative;
  padding-top: 0px;
}

.card-alert {
  position: absolute;
  top: -17px;
  right: 5px;
}

.card-alert >>> .alert-inner-content {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: inline-flex;
}

.card-alert >>> .v-tooltip {
  margin: 0px 3px;
}

.card-alert img {
  max-width: 20px;
  margin: auto;
  height: 100%;
}

.job-search {
  position: absolute;
  min-width: 30%;
  top: -45px;
}
.select-engineer >>> .v-select .v-select__selections {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  flex-wrap: nowrap;
  padding-bottom: 2px;
}
.contractorjob-details-dialog >>> .v-card {
  width: 100%;
}
.task-action >>> .progress-linear {
  position: absolute;
  bottom: -10px;
  left: 0;
}
.part-icon img {
  width: 30px;
}
.jb-detail-content {
  position: relative;
}
.return-visit {
  display: inline-block;
  margin-top: 5px;
}
.return-visit img {
  width: 25px;
}
.loading-spinner {
  position: absolute !important;
  top: 60% !important;
  left: 50% !important;
}
.customer-number {
  min-height: 70px;
}
.primary-card-title {
  display: flex;
  justify-content: space-between;
}
.primary-card-title h3.date-title {
  display: flex;
}
.primary-card-title h3 a {
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
  text-overflow: ellipsis;
}
.primary-card-icons {
  display: inline-flex;
}
.call-customer-icon .v-icon {
  padding: 8px;
  border-radius: 50%;
  color: white;
  background-color: #607d8c;
}
@media only screen and (max-width: 1439px) {
  .contractcard-list >>> .v-card__title h3 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1300px) {
  .contractcard-list >>> .v-card__title h3 {
    font-size: 13px;
  }
  .contractcard-list h3.date-title {
    width: 100%;
    margin-top: 8px;
    font-size: 14px;
  }
  .contractcard-list >>> .v-card__title {
    padding-bottom: 5px !important;
  }
  .eta-date-to {
    font-size: 11px;
  }
  .eta-date-from {
    font-size: 11px;
  }
  .contractcard-list >>> .eta-info {
    font-size: 11px;
    padding-left: 22px;
  }
}
</style>
