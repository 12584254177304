import { InitiatePaymentResponse } from './models/customerToPay/initiate-payment-response'
import { ConfirmPaymentRequest } from './models/customerToPay/confirm-payment-request'
import { ConfirmPaymentResponse } from './models/customerToPay/confirm-payment-response'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'

export default class AssistedPaymentsController {
  public static async Initiate(
    callSid: string,
    jobId: string,
    idempotencyKey: string
  ): Promise<InitiatePaymentResponse | null> {
    const res = await CallCentreApi.post<InitiatePaymentResponse>('assisted-payments/initiate', {
      callSid,
      jobId,
      idempotencyKey,
    })

    return res.status === 200 ? res.data : null
  }

  public static async Capture(
    callSid: string,
    paymentSid: string,
    captureType: string,
    idempotencyKey: string
  ): Promise<boolean> {
    const res = await CallCentreApi.put('assisted-payments/capture', {
      callSid,
      paymentSid,
      captureType,
      idempotencyKey,
    })

    return res.status === 204
  }

  public static async Complete(callSid: string, paymentSid: string, idempotencyKey: string): Promise<boolean> {
    const res = await CallCentreApi.post('assisted-payments/complete', {
      callSid,
      paymentSid,
      idempotencyKey,
    })

    return res.status === 204
  }

  public static async Cancel(callSid: string, paymentSid: string, idempotencyKey: string): Promise<boolean> {
    const res = await CallCentreApi.post('assisted-payments/cancel', {
      callSid,
      paymentSid,
      idempotencyKey,
    })

    return res.status === 204
  }

  public static async Confirm(request: ConfirmPaymentRequest): Promise<ConfirmPaymentResponse> {
    const res = await CallCentreApi.post<ConfirmPaymentResponse>('assisted-payments/confirm', request)
    return res.data
  }
}
