<template>
  <v-flex xs12>
    <v-text-field
      v-model="detail.value"
      :class="{ 'font-weight-bold': detail.isSet }"
      :label="label"
      :readonly="true"
      :loading="detail.awaiting"
    >
      <template #append>
        <v-flex>
          <AgentAssistRetrievalButton :is-set="detail.isSet" :disabled="disabled" @click="$emit('retrieve')" />
        </v-flex>
      </template>
    </v-text-field>
  </v-flex>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import CardDetail from '@/models/twilio/assistedPay/CardDetail'
import AgentAssistRetrievalButton from './AgentAssistRetrievalButton.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { AgentAssistRetrievalButton },
})
export default class AgentAssistTextField extends Vue {
  @Prop() public label: string
  @Prop() public detail: CardDetail
  @Prop() public disabled: boolean
}
</script>
