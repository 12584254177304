import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import TwilioController from '@/api/twilioController'
import { Workspace } from 'twilio-taskrouter'
import WorkerModel from '@/models/twilio/Worker'
import { useTwilioWorker } from './worker'

export const useTwilioWorkspace = defineStore('twilio-workspace', () => {
  const worker = useTwilioWorker()

  const initialized = ref<boolean>(false)
  const instance = ref<Workspace>()
  const contacts = ref<WorkerModel[]>([])

  async function init() {
    if (initialized.value) {
      return
    }

    const workspaceToken = await TwilioController.GetWorkspaceAccessToken()
    instance.value = new Workspace(workspaceToken)

    await refreshContacts()

    initialized.value = true
  }

  async function refreshContacts() {
    if (!instance.value) {
      throw new Error('Attempted to refresh contacts without workspace')
    }

    const newContacts: WorkerModel[] = []
    const allWorkers = await instance.value.fetchWorkers()
    allWorkers.forEach((w) => {
      if (w.sid !== worker.instance.sid) {
        return
      }
      const contact = new WorkerModel()
      contact.name = w.friendlyName
      contact.available = w.available
      contact.activity = (w as any).activityName // Typings are wrong.
      contact.userId = w.attributes.userId
      contact.sid = w.sid
      contact.teamName = w.attributes.teamName
      contact.contactUri = w.attributes.contact_uri
      contact.text = w.friendlyName
      contact.value = w.attributes.contact_uri
      contact.disabled = !w.available
      newContacts.push(contact)
    })

    newContacts.sort((a, b) => {
      let comparison = 0
      if (a.name > b.name) {
        comparison = 1
      } else if (a.name < b.name) {
        comparison = -1
      }
      return comparison
    })
    contacts.value = newContacts
  }

  return {
    init,
    initialized,
    instance: computed(() => {
      if (!instance.value) {
        throw new Error('Attempted to access workspace before init.')
      }
      return instance.value
    }),
    contacts,
  }
})
