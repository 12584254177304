<template>
  <v-menu v-if="apps.length" offset-y left :close-on-content-click="true">
    <template #activator="{ on }">
      <v-btn icon large v-on="on">
        <v-avatar size="38px">
          <v-icon>apps</v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-card :elevation="16" style="width: 500px">
      <div class="application-list">
        <div v-for="app in apps" :key="app.id" :title="app.description" class="application">
          <v-card :href="app.applicationUrl" class="application__button">
            <v-img :src="app.logoUrl" :width="64" :height="64" class="mt-3" />
            <v-card-title class="text-subtitle-1">
              {{ app.friendlyName }}
            </v-card-title>
          </v-card>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { Application } from '@/models/session/session'
import { useSessionStore } from '@/pinia/session'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class AppSwitcher extends Vue {
  private sessionStore = useSessionStore()

  get apps(): Application[] {
    return this.sessionStore.session?.applications || []
  }
}
</script>

<style type="text/css" scoped>
.application-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  max-width: 100%;
  width: 100%;
  padding: 16px;
}

.application {
  flex: 0 0 33%;
  background-color: green;

  display: block;
  overflow: hidden;
  overflow-wrap: break-word;
  position: relative;
  padding: 0;
}

.application__button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
