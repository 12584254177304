<template>
  <div>
    <v-progress-circular
      v-show="!ready"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(50)"
    ></v-progress-circular>
    <v-layout v-show="ready" row wrap>
      <v-flex xs12>
        <v-card class="elevation-1 pa-0">
          <v-layout row wrap>
            <v-flex xs3>
              <div class="job-type-select px-3 pt-1">
                <v-select
                  v-model="selectedJobType"
                  :items="getJobTypes"
                  label="Job Type"
                  item-text="jobType"
                  item-value="jobType"
                  hide-details
                  single-line
                  class="pt-0 mt-0"
                  multiple
                  @change="onJobTypeChange(selectedJobType, $event)"
                >
                  <template slot="item" slot-scope="data">
                    <v-list-tile-action>
                      <v-checkbox
                        v-model="jobTypeCheckboxes[getJobTypes.indexOf(data.item)].checked"
                        color="primary"
                      ></v-checkbox>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ data.item.jobTypeDescription }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </v-select>
              </div>
              <v-divider class="mt-1"></v-divider>
              <div v-bar>
                <div class="specialization-list-content pa-3">
                  <div v-if="trades && trades.length === 0" class="text-xs-center">No Data Available</div>
                  <v-checkbox
                    v-for="trade in trades"
                    v-else
                    :key="trade.tradeId"
                    v-model="tradeList"
                    :hide-details="true"
                    :label="trade.description"
                    :value="trade"
                    color="primary"
                    class="que-checkbox"
                    name="Trade"
                    :class="trade.isDeleted ? 'deleted-item' : ''"
                    @change="onTradeSelectionChange($event, trade)"
                  ></v-checkbox>
                </div>
              </div>
            </v-flex>
            <v-flex xs9 class="pa-3 mb-2">
              <h3>Availability</h3>
              <v-divider class="mt-2"></v-divider>
              <v-layout v-if="trades && trades.length > 0" wrap row class="inblock">
                <v-flex v-bar="{ useScrollbarPseudo: true }" xs12>
                  <v-layout wrap class="cta-content pa-3">
                    <v-flex v-for="trade in tradeList" :key="trade.tradeId" xs12 mb-3>
                      <v-select
                        :items="getContractorStatusTypes"
                        label="Contractor Status"
                        class="contractor_status"
                        :value="getContractorStatus(trade.tradeId)"
                        hide-details
                        @change="onContractorStatusChange($event, trade)"
                      ></v-select>
                      <TimeSlotPicker
                        ref="refTimeSlotPicker"
                        class="contractor-availability"
                        :header-text="trade.description"
                        :filter-by-date="false"
                        slot-type="today"
                        :model="trade"
                        :slot-items="getTradeAvailabilityByTrade(trade.tradeId)"
                        @updateSlotItems="onSlotSelectionChange"
                      ></TimeSlotPicker>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 text-xs-right mr-0>
                  <v-btn
                    color="primary white--text"
                    class="mt-3 mr-3"
                    :disabled="isLoading || isAnyTimeSlotExists()"
                    :loading="isLoading"
                    @click.stop="save"
                  >
                    Save
                  </v-btn>
                </v-flex>
                <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
                  {{ saveSnackbarText }}
                  <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
                </v-snackbar>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TradeModel from '@/models/policyHolder/TradeModel'
import PolicyHolderController from '@/api/policyHolderController'
import TimeSlotPicker from '@/components/TimeSlotPicker.vue'
import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import TimeSlot from '@/models/claim/TimeSlot'
import ContractorController from '@/api/contractorController'
import ContractorModel from '@/models/contractor/ContractorModel'
import { JobType } from '@/models/types'
import eventBus from '@/common/bus'
import Shared from '../common/shared'
import { ContractorTradeStatus } from '@/common/enums'

interface IJobTypeEnum {
  jobType: JobType
  jobTypeDescription: string
}

@Component({
  components: { TimeSlotPicker },
})
export default class ContractorSpecialization extends Vue {
  // @Prop() private selectedTrades: TradeModel[];
  @Prop() private contractorId: string
  @Prop() private contractor: ContractorModel | null
  private isLoading = false
  private tradeList: TradeModel[] = []
  private trades: TradeModel[] = []
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private saveSnackbarText = 'Availability saved successfully.'
  private jobtype: JobType = 'HE'
  private selectedJobType: JobType[] = []
  private localTrades: TradeModel[] = [] // temp variable to manage all trade list
  private localSelectedTradeList: TradeModel[] = [] // temp variable to manage selected trade list
  private jobTypeCheckboxes: any = []
  private ready = true
  private availability: ContractorTradeAvailabilityModel[] = []
  private allTradeList: TradeModel[] = [] // manage list of deleted and non deleted trades

  private get getJobTypes(): IJobTypeEnum[] {
    return Shared.jobTypeList
  }

  private get getAvailability() {
    return this.availability
  }

  private set getAvailability(newValue: ContractorTradeAvailabilityModel[]) {
    this.availability = newValue
  }

  private created() {
    const self = this
    this.ready = false
    PolicyHolderController.GetTrades().then((res: TradeModel[]) => {
      self.allTradeList = res
      self.trades = self.allTradeList.filter(
        (t) =>
          !t.isDeleted ||
          (t.isDeleted &&
            this.contractor &&
            (this.contractor.contractorAvailability || []).findIndex((c) => c.tradeId === t.tradeId) !== -1)
      )
      self.localTrades = JSON.parse(JSON.stringify(self.trades))
      if (this.contractor) {
        this.getAvailability = JSON.parse(JSON.stringify(this.contractor.contractorAvailability))
        for (const item of this.getAvailability) {
          const trade = this.trades.find((a) => a.tradeId === item.tradeId)
          if (trade) {
            self.tradeList.push(trade)
          }
        }
        this.ready = true
        self.localSelectedTradeList = JSON.parse(JSON.stringify(self.tradeList))
      }
    })
    this.selectAllJobType()
  }

  private selectAllJobType() {
    this.selectedJobType = []
    this.selectedJobType = ['HE', 'US', 'SI']
    // show all checkboxes of jobType checked on page load
    this.jobTypeCheckboxes = this.selectedJobType.map((code) => {
      return {
        checked: true,
      }
    })
  }

  @Watch('contractor')
  private onContractorChange(newValue: any) {
    this.ready = false
    this.contractor = newValue
    this.tradeList = []
    this.localSelectedTradeList = []
    this.trades = this.allTradeList.filter(
      (t) =>
        !t.isDeleted ||
        (t.isDeleted &&
          this.contractor &&
          (this.contractor.contractorAvailability || []).findIndex((c) => c.tradeId === t.tradeId) !== -1)
    )
    this.localTrades = JSON.parse(JSON.stringify(this.trades))
    setTimeout(() => {
      if (this.contractor) {
        this.getAvailability = JSON.parse(JSON.stringify(this.contractor.contractorAvailability))
        for (const item of this.getAvailability) {
          const trade = this.trades.find((a) => a.tradeId === item.tradeId)
          if (trade) {
            this.tradeList.push(trade)
          }
        }
        this.localSelectedTradeList = JSON.parse(JSON.stringify(this.tradeList))
        this.selectAllJobType()
        this.ready = true
      }
    }, 0)
  }

  private onSlotSelectionChange(slotItems: TimeSlot[], model: TradeModel, slotType: string) {
    let contractorAvailability: ContractorTradeAvailabilityModel | undefined = this.contractor
      ? this.getAvailability.find((a: ContractorTradeAvailabilityModel) => a.tradeId === model.tradeId)
      : undefined
    if (!contractorAvailability) {
      contractorAvailability = new ContractorTradeAvailabilityModel()
      contractorAvailability.tradeId = model.tradeId
      contractorAvailability.description = model.description
      contractorAvailability.contractorId = this.contractorId
      contractorAvailability.timeSlot = slotItems
      contractorAvailability.preferredContractor = ContractorTradeStatus.Approved
      if (this.contractor) {
        this.getAvailability.push(contractorAvailability)
      }
    } else {
      Vue.set(contractorAvailability, 'timeSlot', slotItems)
    }
  }

  private getTradeAvailabilityByTrade(id: number): TimeSlot[] {
    if (this.contractor && this.getAvailability) {
      const contractorAvailability: ContractorTradeAvailabilityModel | undefined = this.getAvailability.find(
        (a: ContractorTradeAvailabilityModel) => a.tradeId === id
      )
      if (contractorAvailability) {
        return contractorAvailability.timeSlot
      } else {
        return []
      }
    } else {
      return []
    }
  }

  private onTradeSelectionChange(event: any, trade: TradeModel) {
    if (trade && trade.tradeId) {
      const availability: ContractorTradeAvailabilityModel | undefined = this.contractor
        ? this.getAvailability.find((e: ContractorTradeAvailabilityModel) => e.tradeId === trade.tradeId)
        : undefined
      if (availability) {
        if (this.contractor) {
          const index = this.getAvailability.indexOf(availability)
          this.getAvailability.splice(index, 1)
        }
      }
    }
    const selectedTradeIndex = this.localSelectedTradeList.findIndex((e) => e.tradeId === trade.tradeId)
    if (selectedTradeIndex === -1) {
      // trade is selected, add to temp variable
      this.localSelectedTradeList.push(trade)
    } else {
      // trade is removed, remove from temp variable
      this.localSelectedTradeList.splice(selectedTradeIndex, 1)
    }
    // this.$emit('update:selectedTrades', event);
  }

  private save() {
    this.validate().then((result) => {
      if (result) {
        this.isLoading = true
        this.saveContractorAvailability(null)
      }
    })
  }

  private saveContractorAvailability(error: string | null) {
    const co: ContractorModel = new ContractorModel()
    co.id = this.contractorId
    if (this.contractor) {
      co.contractorAvailability = [...this.getAvailability]
    }
    for (const availability of co.contractorAvailability || []) {
      // remove All and Daytime slots
      const availableTimeSlots: TimeSlot[] = availability.timeSlot.filter((a: TimeSlot) => a.slotId > 0)
      availability.timeSlot = availableTimeSlots
    }
    delete co.coverage
    ContractorController.SaveContractorAvailability(co)
      .then((res: boolean) => {
        if (res) {
          this.isLoading = false
          this.saveSnackbar = true
          if (error !== null) {
            this.showErrorMessage(error)
          }
          this.saveSnackbar = true
          this.$emit('updateContractorAvailability', co.contractorAvailability)
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error saving contractor availability, please try again', true)
        this.isLoading = false
      })
  }

  private onJobTypeChange(selectedItems: JobType[], items: JobType[]) {
    if (items.length === 0) {
      // no jobtype is selected
      this.trades = []
    } else {
      // set data as per selected jobType
      const typeWiseTrades: TradeModel[] = []
      const typeWiseSelectedTradeList: TradeModel[] = []
      items.forEach((type) => {
        const filteredData = this.localTrades.filter((e) => e.jobType === type)
        typeWiseTrades.push(...filteredData)
        const filteredSelectedTradeList = this.localSelectedTradeList.filter((t) => t.jobType === type)
        typeWiseSelectedTradeList.push(...filteredSelectedTradeList)
      })
      this.trades = typeWiseTrades
      this.tradeList = typeWiseSelectedTradeList
    }
    // set 'checked' property of checkbox (check or uncheck) based on item selection
    if (selectedItems.length < items.length) {
      const selectedItem = this.getJobTypes.find((e) => e.jobType === items[items.length - 1])
      if (selectedItem !== undefined) {
        this.jobTypeCheckboxes[this.getJobTypes.indexOf(selectedItem)].checked = true
      }
    } else if (selectedItems.length > items.length) {
      const itemToRemoveFromList = selectedItems.find((e) => !(items as any).includes(e))
      const item = this.getJobTypes.find((e) => e.jobType === itemToRemoveFromList)
      if (item !== undefined) {
        this.jobTypeCheckboxes[this.getJobTypes.indexOf(item)].checked = false
      }
    }
  }

  private async validate(): Promise<boolean> {
    const self = this
    let result = true
    if (self.contractor) {
      let isValidate = true
      // validate timeslots
      if (self.getAvailability.length > 0) {
        const isSlotExists = self.getAvailability.filter((x) => x.timeSlot.length > 0).length > 0 ? true : false
        if (!isSlotExists) {
          isValidate = false
        }
      }
      if (!isValidate) {
        result = isValidate
      }
    }
    return result
  }

  private showErrorMessage(error: string) {
    // show error message
    if (error) {
      eventBus.$emit('errorHandler', error, true)
    }
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private isAnyTimeSlotExists() {
    const self = this
    if (self.contractor) {
      const selectedTradesLength = self.tradeList.length
      const selectedAvailabilityLength = self.getAvailability.filter(
        (e) => self.tradeList.findIndex((x) => x.tradeId === e.tradeId) !== -1 && e.timeSlot.length > 0
      ).length
      if (self.getAvailability.length > 0 && selectedTradesLength === selectedAvailabilityLength) {
        return false
      }
    }
    return true
  }

  private get getContractorStatusTypes(): string[] {
    return [ContractorTradeStatus.Approved, ContractorTradeStatus.Preferred, ContractorTradeStatus.Platinum]
  }

  private getContractorStatus(tradeId: number) {
    const availability = this.getAvailability.find((c) => c.tradeId === tradeId)
    return availability
      ? availability.preferredContractor &&
        this.getContractorStatusTypes.findIndex((status) => status === availability.preferredContractor) !== -1
        ? availability.preferredContractor
        : ''
      : this.getContractorStatusTypes[0]
  }

  private onContractorStatusChange(status: string, model: TradeModel) {
    const index = this.getAvailability.findIndex((c) => c.tradeId === model.tradeId)
    if (index !== -1) {
      this.getAvailability[index].preferredContractor = ContractorTradeStatus[status]
    } else {
      const contractorAvailability = new ContractorTradeAvailabilityModel()
      contractorAvailability.tradeId = model.tradeId
      contractorAvailability.description = model.description
      contractorAvailability.contractorId = this.contractorId
      contractorAvailability.timeSlot = []
      contractorAvailability.preferredContractor = ContractorTradeStatus[status]
      if (this.contractor) {
        this.getAvailability.push(contractorAvailability)
      }
    }
  }
}
</script>

<style scoped>
.specialization-list-content {
  min-height: 611px;
  max-height: 611px;
  background-color: #f9f9f9;
}
.cta-content >>> .select-slots .v-select__selections {
  max-height: 30px;
  opacity: 0;
}
.inblock {
  display: inline-block;
  width: 100%;
  max-height: 550px;
}
.cta-content {
  max-height: 575px;
  min-height: 575px;
}
.cta-content .v-card {
  background-color: #f9f9f9;
}
.contractor-availability >>> .cnt-time-head.select-date .v-icon {
  margin-top: 27px !important;
}
.job-type-select >>> .v-select {
  width: 100%;
}
.contractor_status {
  width: 160px;
  left: 210px;
  z-index: 1;
  top: 59px;
}
.contractor_status >>> .v-select__selections {
  max-height: 30px;
}
@media all and (min-width: 1200px) and (max-width: 1300px) {
  .contractor_status {
    left: 190px !important;
    width: 140px;
  }
}
@media all and (min-width: 1100px) and (max-width: 1200px) {
  .contractor_status {
    left: 145px !important;
    width: 140px;
  }
}
@media all and (max-width: 1100px) {
  .contractor_status {
    left: 135px !important;
    width: 130px;
  }
}
</style>
