<template>
  <div>
    <v-icon
      v-show="showFullscreenControl && !showFullScreenMap"
      ref="fullScreenIcon"
      class="fullScreenIcon"
      :class="showFullScreenMap ? 'fullScreenIconFixedStyle' : ''"
      @click="toggleFullScreenMap"
    >
      fullscreen
    </v-icon>
    <v-icon
      v-show="showFullscreenControl && showFullScreenMap"
      ref="fullScreenIcon"
      class="fullScreenIcon"
      :class="showFullScreenMap ? 'fullScreenIconFixedStyle' : ''"
      @click="toggleFullScreenMap"
    >
      fullscreen_exit
    </v-icon>
    <div v-if="showStreetView" :id="mapId" ref="mapView" class="google-map-street-view"></div>
    <div v-else class="map-view">
      <div v-if="mapLoaded && showTravelMode && !showFullScreenMap" class="map-card-direction">
        <v-card class="container__map-card container__map-card-direction">
          <v-layout wrap>
            <v-flex v-model="travelMode" xs12 class="map-icons pa-2">
              <v-tooltip bottom fixed>
                <template #activator="{ on }">
                  <v-icon
                    :class="travelMode === 'DRIVING' ? 'active' : ''"
                    v-on="on"
                    @click="onTravelModeChanged('DRIVING')"
                  >
                    directions_car
                  </v-icon>
                </template>
                <span>Driving</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    :class="travelMode === 'TRANSIT' ? 'active' : ''"
                    v-on="on"
                    @click="onTravelModeChanged('TRANSIT')"
                  >
                    train
                  </v-icon>
                </template>
                <span>Transit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    :class="travelMode === 'WALKING' ? 'active' : ''"
                    v-on="on"
                    @click="onTravelModeChanged('WALKING')"
                  >
                    directions_walk
                  </v-icon>
                </template>
                <span>Walking</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    :class="travelMode === 'BICYCLING' ? 'active' : ''"
                    v-on="on"
                    @click="onTravelModeChanged('BICYCLING')"
                  >
                    directions_bike
                  </v-icon>
                </template>
                <span>Bicycling</span>
              </v-tooltip>
            </v-flex>
            <div class="pa-3">
              <v-flex v-if="directionNotFound">
                <v-flex xs12>
                  <div class="left">{{ errorMessage }}</div>
                </v-flex>
              </v-flex>
              <v-flex v-else>
                <v-layout wrap>
                  <v-flex xs3>
                    <div class="left">Distance:</div>
                  </v-flex>
                  <v-flex xs9>
                    <div class="map-card-direction-value">{{ distance }}</div>
                  </v-flex>
                  <v-flex xs3>
                    <div class="left">Duration:</div>
                  </v-flex>
                  <v-flex xs9>
                    <div class="left">{{ duration }}</div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </div>
          </v-layout>
        </v-card>
      </div>
      <div v-if="showPlaceCard" class="map-container">
        <div v-show="!showFullScreenMap" class="placeDiv">
          <div class="container__map-card placecard__container">
            <div class="placecard__left">
              <p class="placecard__business-name">
                {{
                  sourceAddress !== '' && sourceAddress !== null && sourceAddress !== undefined
                    ? sourceAddress
                    : formattedAddress
                }}
              </p>
              <p class="placecard__info">{{ formattedAddress }}</p>
              <star-rating
                v-if="totalRatings > 0"
                :star-size="13"
                :rating="totalRatings"
                :read-only="true"
                :increment="0.01"
                active-color="#e7711b"
              ></star-rating>
              <a class="placecard__view-large" target="_blank" :href="viewLargerMapUrl">View larger map</a>
            </div>
            <div class="placecard__right">
              <a class="placecard__direction-link" target="_blank" :href="viewDirectionLinkUrl">
                <v-icon>directions</v-icon>
                Directions
              </a>
            </div>
          </div>
        </div>
      </div>
      <div :id="mapId" ref="mapView" class="google-map"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator'
import ContractorCoverageModel from '@/models/contractor/ContractorCoverageModel'
import TradeModel from '@/models/policyHolder/TradeModel'
import PolicyHolderController from '@/api/policyHolderController'
import StarRating from 'vue-star-rating'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import GoogleMap from '@/components/GoogleMap.vue'
import eventBus from '@/common/bus'
import { MarkerClusterer, Marker } from '@googlemaps/markerclusterer'

declare let google: any

@Component({
  components: { StarRating },
})
export default class Map extends GoogleMap {
  @Prop() private sourceAddress: string
  @Prop() private destinationAddress: string
  @Prop() private mapId: string
  @Prop() private zoomLevel: number
  @Prop() private showCircle: boolean
  @Prop() private showDirection: boolean
  @Prop() private showPlaceCard: boolean
  @Prop() private showTravelMode: boolean
  @Prop() private showStreetView: boolean
  @Prop() private sourceLatitude: number
  @Prop() private sourceLongitude: number
  @Prop() private destinationLatitude: number
  @Prop() private destinationLongitude: number
  @Prop() private disableDefaultUIControl: boolean
  @Prop({ default: true }) private showFullscreenControl: boolean
  @Prop() private coverage: ContractorCoverageModel[]
  @Prop() private plotContractorOpenJobAddresses: string[]
  @Prop() private outstandingJobsJobsLocation: ContractorAppointedModel[]
  @Prop({ default: true }) private showMarker: boolean
  @Prop() private previousLatitude: number
  @Prop() private previousLongitude: number
  @Prop() private headingMagneticNorth: number
  @Prop({ default: false }) private showLiveLocation: boolean
  @Prop({ default: null }) private engineerJobStartLatitude: number | null
  @Prop({ default: null }) private engineerJobStartLongitude: number | null

  public viewLargerMapUrl = ''
  public viewDirectionLinkUrl = ''
  public totalRatings = 0
  public formattedAddress = ''
  private directionsDisplay = new google.maps.DirectionsRenderer()
  private directionsService = new google.maps.DirectionsService()
  private travelMode = 'DRIVING'
  private distance = ''
  private duration = ''
  private sAddress = ''
  private sLatLng: any = null
  private dLatLng: any = null
  private errorMessage = ''
  private directionNotFound = false
  private mapLoaded = false
  public showFullScreenMap = false
  private contractorMarker: any = null
  private trades: TradeModel[] = []
  private tradeList: any = []
  private bounds: any
  private tradeDescriptions: any = {}
  private isAddressAlreadyChange = false
  private polygonList: any[] = []
  private circleList: any[] = []
  private markerCluster: MarkerClusterer | null = null
  private carImage: any =
    'M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z'
  private engineerMarker: any = null

  private async loadAreas() {
    await new Promise<void>((r) => this.resultMap.data.loadGeoJson('/mapdata/areas.json', undefined, () => r()))
    this.resultMap.data.setStyle({
      fillColor: 'transparent',
      opacity: 0,
      strokeWeight: 1.2,
    })
    this.resultMap.data.addListener('click', this.featureClickedHandler)

    // Show Tooltip
    this.resultMap.data.addListener('mouseover', (event) => {
      if (event.feature.getProperty('o')) {
        const tooltipData: any = {}
        tooltipData.postCode = event.feature.getProperty('o')
        const area = this.coverage.find((c) => c.postcode === event.feature.getProperty('a'))
        const trades: string[] = []
        if (area && area.district) {
          const outcodes = area.district.find(
            (c) =>
              c.postcode.split(' - ')[0] === event.feature.getProperty('o') ||
              c.postcode === event.feature.getProperty('o')
          )
          if (outcodes) {
            outcodes.trades.forEach((t: number) => trades.push(this.tradeDescriptions[t]))
          }
        }
        tooltipData.tradeList = trades
        this.injectTooltip(event, tooltipData)
      }
    })
    this.resultMap.data.addListener('mousemove', (e) => {
      this.moveTooltip(e)
    })
    this.resultMap.data.addListener('mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  @Watch('sourceAddress')
  @Watch('sourceLatitude')
  @Watch('sourceLongitude')
  @Watch('destinationAddress')
  @Watch('destinationLatitude')
  @Watch('destinationLongitude')
  private onAddressAndLatLngChange() {
    if (!this.isAddressAlreadyChange) {
      this.isAddressAlreadyChange = true
      this.onAddressChange()
      setTimeout(() => {
        this.isAddressAlreadyChange = false
      }, 1000)
    }
  }

  private onAddressChange() {
    this.markerPoints()
    if (this.contractorMarker) {
      this.contractorMarker.setMap(null)
    }
    if (this.showStreetView) {
      this.setStreetView()
    } else {
      this.renderMapWithMarker(this.resultMap)
    }
  }

  private async created() {
    const trades: TradeModel[] = storeGetters.getTrades()
    if (trades.length === 0) {
      await PolicyHolderController.GetTrades()
        .then((res: TradeModel[]) => {
          storeMutations.setTrades(res)
          const getHETrades = res.filter((e) => e.jobType === 'HE')
          getHETrades.forEach((trade: TradeModel) => (this.tradeDescriptions[trade.tradeId] = trade.description))
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading trade list, please try again', true)
        })
    } else {
      const getHETrades = trades.filter((e) => e.jobType === 'HE')
      getHETrades.forEach((trade: TradeModel) => (this.tradeDescriptions[trade.tradeId] = trade.description))
    }

    this.bounds = new google.maps.LatLngBounds()
    await this.fillOutcodesByArea()
    this.markerPoints()

    if (this.showStreetView) {
      this.setStreetView()
    } else {
      this.initMap()
    }
    if (this.emergencyTypes.length === 0) {
      await this.setEmergencies()
    }
  }

  private markerPoints() {
    this.sAddress =
      this.sourceAddress !== null && this.sourceAddress !== undefined && this.sourceAddress !== ''
        ? this.sourceAddress
        : ''
    this.sLatLng =
      this.sourceLatitude !== null && this.sourceLongitude !== null
        ? new google.maps.LatLng(this.sourceLatitude, this.sourceLongitude)
        : null
    this.dLatLng =
      this.destinationLatitude !== null && this.destinationLongitude !== null
        ? new google.maps.LatLng(this.destinationLatitude, this.destinationLongitude)
        : null
  }

  private destroyed() {
    if (this.contractorMarker) {
      this.contractorMarker.setMap(null)
    }

    if (this.engineerMarker) {
      this.engineerMarker.setMap(null)
    }
    this.polygonList.forEach((polygon) => {
      polygon.setMap(null)
      google.maps.event.clearInstanceListeners(polygon)
    })
    this.circleList.forEach((polygon) => {
      polygon.setMap(null)
      google.maps.event.clearInstanceListeners(polygon)
    })
    this.polygonList = []
    this.circleList = []
    delete this.resultMap
  }

  private async initMap() {
    this.resultMap = this.initGoogleMap(this.mapId, this.zoomLevel, this.disableDefaultUIControl)
    this.renderMapWithMarker(this.resultMap)
    if (this.coverage && this.coverage.length > 0) {
      this.onContractorCoverageChanged()
    }
  }

  private renderMapWithMarker(resultMap: any) {
    if (this.sLatLng !== null) {
      resultMap.setCenter(this.sLatLng)
      this.contractorMarker = new google.maps.Marker({
        map: resultMap,
        position: this.sLatLng,
      })
    } else {
      resultMap.setCenter(new google.maps.LatLng(51.509865, -0.118092))
    }
    if (!this.showMarker) {
      this.contractorMarker.visible = false
    }
    this.formattedAddress = this.sAddress
    this.viewLargerMapUrl = 'https://www.google.com/maps?q=' + this.sAddress

    this.viewDirectionLinkUrl = 'https://www.google.com/maps?daddr=' + this.sAddress
    if (this.showDirection) {
      this.directionsDisplay.setMap(resultMap)
      this.setDirectionDisplay(this.travelMode)
    }

    if (this.showCircle) {
      this.mapCircle(resultMap, this.contractorMarker)
    }

    if (this.showLiveLocation) {
      const carIcon: any = {
        path: this.carImage,
        scale: 0.5,
        strokeColor: 'white',
        strokeWeight: 0.1,
        fillOpacity: 1,
        fillColor: '#404040',
        offset: '5%',
        rotation: 0,
        anchor: new google.maps.Point(10, 25),
      }
      this.contractorMarker.setIcon(carIcon)
      const engineerJobStartLocation =
        this.engineerJobStartLatitude !== null && this.engineerJobStartLongitude !== null
          ? new google.maps.LatLng(this.engineerJobStartLatitude, this.engineerJobStartLongitude)
          : null
      if (engineerJobStartLocation) {
        resultMap.setCenter(engineerJobStartLocation)
        this.contractorMarker.setMap(null)
        this.engineerMarker = new google.maps.Marker({
          map: resultMap,
          position: engineerJobStartLocation,
        })
        this.bindMarkerListenerEvents(this.engineerMarker, this.destinationAddress)
      } else {
        this.getRouteToMoveMarker()
      }
    }
  }

  private mapCircle(mapCircle: any, marker: any) {
    const circleOptions = {
      strokeColor: 'red',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: 'red',
      fillOpacity: 0.35,
      map: mapCircle,
      center: { lat: -34.397, lng: 150.644 },
      radius: 100, // in meters 300
    }

    const circle = new google.maps.Circle(circleOptions)
    circle.bindTo('center', marker, 'position')
  }

  private onTravelModeChanged(selectedTravelMode: any) {
    this.travelMode = selectedTravelMode
    this.setDirectionDisplay(selectedTravelMode)
  }

  private setDirectionDisplay(selectedTravelMode: any) {
    this.directionsService.route(
      {
        origin: this.sLatLng,
        destination: this.dLatLng,
        travelMode: selectedTravelMode,
      },
      (response: any, status: any) => {
        if (status === 'OK') {
          this.mapLoaded = true
          this.directionNotFound = false
          this.directionsDisplay.setDirections(response)
          this.distance = response.routes[0].legs[0].distance.text
          this.duration = response.routes[0].legs[0].duration.text
        } else {
          this.directionNotFound = true
          this.errorMessage =
            'Sorry, we could not calculate ' + selectedTravelMode.toLowerCase() + ' directions for the specified route.'
        }
      }
    )
  }

  private setStreetView() {
    this.setStreetViewPanorama(this.sourceLatitude, this.sourceLongitude)
  }

  private setStreetViewPanorama(latitude, longitude) {
    const panorama = new google.maps.StreetViewPanorama(document.getElementById(this.mapId), {
      position: { lat: latitude, lng: longitude },
      pov: { heading: 165, pitch: 0 },
      zoom: 1,
      fullscreenControl: false,
    })
  }

  @Watch('coverage')
  private async onContractorCoverageChanged() {
    this.polygonList.forEach((polygon) => {
      polygon.setMap(null)
      google.maps.event.clearInstanceListeners(polygon)
    })
    this.circleList.forEach((polygon) => {
      polygon.setMap(null)
      google.maps.event.clearInstanceListeners(polygon)
    })
    this.polygonList = []
    this.circleList = []
    this.internalAreas = []
    this.resultMap.setZoom(5.5)
    if (this.resultMap.data && !this.resultMap.data.getStyle() && this.coverage.filter((c) => c.postcode).length > 0) {
      await this.loadAreas()
    }
    this.onCoverageAndAvailabilityChange()
  }

  private onCoverageAndAvailabilityChange() {
    this.bindCoverageCoordinates()
    if (this.dLatLng !== null) {
      this.resultMap.setCenter(this.dLatLng)
      const marker = new google.maps.Marker({
        map: this.resultMap,
        position: this.dLatLng,
        visible: true,
        icon: '/img/map-marker.png',
      })
    }
  }

  @Watch('plotContractorOpenJobAddresses')
  private plotOpenJobAddresses(jobDetails: ContractorAppointedModel[] | null) {
    if (jobDetails && jobDetails.length > 0) {
      let openJobPinIcon = '/img/emergency.svg'
      const emergencyList: EmergencyModel[] | undefined = this.emergencyTypes
      jobDetails.forEach((jobDetail) => {
        if (jobDetail.latitude && jobDetail.longitude) {
          // bind pin icon Image
          if (emergencyList) {
            const emergencyToDisplay = emergencyList.find((x) => x.emergencyId === jobDetail.forEmergencyTypeId)
            if (emergencyToDisplay && emergencyToDisplay.fileURL !== null) {
              // emergency Image
              openJobPinIcon = emergencyToDisplay.fileURL
            } else {
              // default Image
              openJobPinIcon = '/img/emergency.svg'
            }
          }
          // create marker
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(jobDetail.latitude, jobDetail.longitude),
            map: this.resultMap,
            icon: {
              url: openJobPinIcon,
              scaledSize: new google.maps.Size(27, 27),
            },
          })
          // bind listeners
          this.bindOpenJobsListenerEvents(marker, jobDetail)
        }
      })
      this.toggleFullScreenView(this.showFullScreenMap, this.disableDefaultUIControl)
    }
  }

  @Watch('outstandingJobsJobsLocation')
  private outstandingJobsLocationChange(outstandingJobDetails: ContractorAppointedModel[] | null) {
    if (this.markerCluster) {
      this.markerCluster.clearMarkers()
    }
    const bound = new google.maps.LatLngBounds()
    if (outstandingJobDetails && outstandingJobDetails.length > 0) {
      const markers = outstandingJobDetails.map((jobDetail, i) => {
        const emergencyToDisplay = this.emergencyTypes.find((x) => x.emergencyId === jobDetail.forEmergencyTypeId)
        const marker: Marker = new google.maps.Marker({
          position: new google.maps.LatLng(jobDetail.latitude, jobDetail.longitude),
          icon: {
            url:
              emergencyToDisplay && emergencyToDisplay.fileURL !== null
                ? emergencyToDisplay.fileURL
                : '/img/emergency.svg',
            scaledSize: new google.maps.Size(27, 27),
          },
        })
        bound.extend(new google.maps.LatLng(jobDetail.latitude, jobDetail.longitude))
        // bind listeners
        this.bindOutStandingJobsListenerEvents(marker, jobDetail)
        return marker
      })
      this.resultMap.setCenter(bound.getCenter())
      this.resultMap.fitBounds(bound)
      // Add a marker clusterer to manage the markers.
      this.markerCluster = new MarkerClusterer({
        map: this.resultMap,
        markers,
        algorithmOptions: {
          maxZoom: 8,
        },
      })
    } else {
      this.resultMap.setCenter(new google.maps.LatLng(51.509865, -0.118092))
      this.resultMap.setZoom(8)
    }
  }

  private toggleFullScreenMap() {
    this.showFullScreenMap = !this.showFullScreenMap
    this.toggleFullScreenView(this.showFullScreenMap, this.disableDefaultUIControl)
  }

  private bindMarkerListenerEvents(marker: any, data: ContractorAppointedModel | string) {
    // Show Tooltip
    google.maps.event.addListener(marker, 'mouseover', (e) => {
      this.injectOpenJobTooltip(e, data)
    })
    google.maps.event.addListener(marker, 'mousemove', (e) => {
      this.moveTooltip(e)
    })
    google.maps.event.addListener(marker, 'mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  private bindOpenJobsListenerEvents(marker: any, jobDetails: ContractorAppointedModel) {
    this.bindMarkerListenerEvents(marker, jobDetails)
  }

  private bindOutStandingJobsListenerEvents(marker: any, jobDetails: ContractorAppointedModel) {
    // Show Tooltip
    google.maps.event.addListener(marker, 'mouseover', (e) => {
      this.injectOutstandingJobTooltip(e, jobDetails)
    })
    google.maps.event.addListener(marker, 'mousemove', (e) => {
      this.moveTooltip(e)
    })
    google.maps.event.addListener(marker, 'mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  private bindCoverageCoordinates() {
    const self = this
    if (self.coverage) {
      for (const coverageItem of self.coverage) {
        if (coverageItem.postcode) {
          this.bindDistrictWithMap(coverageItem)
        } else if (coverageItem.location && coverageItem.location.type === 'Polygon') {
          const arrCoords: any[] = []
          coverageItem.location.coordinates.forEach((coordinate) => {
            coordinate.forEach((point) => {
              const lat = point[0]
              const long = point[1]
              arrCoords.push(new google.maps.LatLng(lat, long))
              self.bounds.extend(new google.maps.LatLng(lat, long))
            })
          })

          const polygon = new google.maps.Polygon({
            geodesic: true,
            editable: false,
            paths: arrCoords,
            strokeColor: '#1B5E20',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#1B5E20',
            suppressUndo: true,
            id: coverageItem.id,
          })
          polygon.setMap(self.resultMap)
          const trades: string[] = []
          coverageItem.trades.forEach((t: number) => trades.push(this.tradeDescriptions[t]))
          self.bindPolygonListenerEvents(polygon, trades)
          this.polygonList.push(polygon)
        } else if (coverageItem.location && coverageItem.location.type === 'Point') {
          const latitude: number = coverageItem.location.coordinates[0]
          const longitude: number = coverageItem.location.coordinates[1]

          const circleMarker = new google.maps.Marker({
            position: new google.maps.LatLng(latitude, longitude),
            map: this.resultMap,
            visible: false,
          })
          const circleOptions = {
            strokeColor: '#1B5E20',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#1B5E20',
            map: self.resultMap,
            radius: coverageItem.radius,
            id: coverageItem.id,
          }
          const circle = new google.maps.Circle(circleOptions)
          circle.bindTo('center', circleMarker, 'position')
          const trades: string[] = []
          coverageItem.trades.forEach((t: number) => trades.push(this.tradeDescriptions[t]))
          self.bindCircleListenerEvents(circle, trades)
          this.circleList.push(circle)
        }
      }
      this.updateRenderedAreas()
    }
  }

  private bindPolygonListenerEvents(element: any, trades: string[]) {
    google.maps.event.addListener(element, 'mouseover', (e) => {
      const tooltipData: any = {}
      tooltipData.postCode = ''
      tooltipData.tradeList = trades
      if (trades.length > 0) {
        this.injectTooltip(e, tooltipData)
      }
    })
    google.maps.event.addListener(element, 'mousemove', (e) => {
      this.moveTooltip(e)
    })
    google.maps.event.addListener(element, 'mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  private bindCircleListenerEvents(circle, trades: string[]) {
    circle.addListener('mouseover', (e) => {
      const tooltipData: any = {}
      tooltipData.postCode = ''
      tooltipData.tradeList = trades
      if (trades.length > 0) {
        this.injectTooltip(e, tooltipData)
      }
    })
    circle.addListener('mousemove', (e) => {
      this.moveTooltip(e)
    })
    circle.addListener('mouseout', (e) => {
      this.deleteTooltip(e)
    })
  }

  private bindDistrictWithMap(item: ContractorCoverageModel) {
    const districts: string[] = []
    const postCode: any = this.outcodesByArea.find((e) => item.postcode === e.areaCode)
    if (postCode !== undefined) {
      if (item.district) {
        item.district.forEach((d) => {
          districts.push(d.postcode.split(' - ')[0])
        })
      }
      const areaObj: any = {}
      areaObj.AreaCode = postCode.areaCode
      areaObj.OutCodes = districts
      areaObj.Description = postCode.description
      this.internalAreas.push(areaObj)
    }
  }

  private injectTooltip(event, data) {
    if (!this.tooltipObject && event) {
      // create the tooltip object
      this.tooltipObject = document.createElement('div')
      this.tooltipObject.style.width = 'auto'
      this.tooltipObject.style.maxWidth = '200px'
      this.tooltipObject.style.background = 'white'
      this.tooltipObject.style.borderRadius = '5px'
      this.tooltipObject.style.padding = '5px'
      this.tooltipObject.style.display = 'inline-block'
      this.tooltipObject.style.lineHeight = '1.5'
      this.tooltipObject.style.fontFamily = 'Arial,Helvetica'
      this.tooltipObject.style.zIndex = 3
      if (data.postCode !== null && data.postCode !== '') {
        this.tooltipObject.innerHTML =
          "<b style='color:#4c4c4c'>" + data.postCode + '</b>' + '<br />' + data.tradeList.join(', ')
      } else {
        this.tooltipObject.innerHTML = data.tradeList.join(', ')
      }
      const eventPropNames: any = Object.keys(event)
      if (!this.coordPropName) {
        // discover the name of the prop with MouseEvent
        for (const i of eventPropNames) {
          const name = i
          if (event[name] instanceof MouseEvent) {
            this.coordPropName = name
            break
          }
        }
      }
      if (this.coordPropName !== null) {
        // position it
        this.tooltipObject.style.position = 'absolute'
        if (event[this.coordPropName] !== undefined) {
          this.tooltipObject.style.top = event[this.coordPropName].clientY + window.scrollY + this.offset.y + 'px'
          this.tooltipObject.style.left = event[this.coordPropName].clientX + window.scrollX + this.offset.x + 'px'
        }
        // add it to the body
        document.body.appendChild(this.tooltipObject)
      }
    }
  }

  private get emergencyTypes() {
    return storeGetters.getEmergencies()
  }

  private async setEmergencies() {
    await PolicyHolderController.GetEmergencies().then((res: EmergencyModel[]) => {
      if (res) {
        storeMutations.setEmergencies(res)
      }
    })
  }

  private injectOpenJobTooltip(event, data: ContractorAppointedModel | string) {
    if (!this.tooltipObject && event) {
      // create the tooltip object
      this.tooltipObject = document.createElement('div')
      this.tooltipObject.style.width = 'auto'
      this.tooltipObject.style.maxWidth = '200px'
      this.tooltipObject.style.background = 'white'
      this.tooltipObject.style.borderRadius = '5px'
      this.tooltipObject.style.padding = '5px'
      this.tooltipObject.style.display = 'inline-block'
      this.tooltipObject.style.lineHeight = '1.5'
      this.tooltipObject.style.fontFamily = 'Arial,Helvetica'
      this.tooltipObject.style.zIndex = typeof data === 'object' ? 4 : 250
      if (typeof data === 'object') {
        if (data.customerName) {
          this.tooltipObject.innerHTML = "<b style='color:#4c4c4c'>Name: </b>" + data.customerName + '<br />'
        }
        if (data.address) {
          this.tooltipObject.innerHTML += "<b style='color:#4c4c4c'>Address: </b>" + data.address + '<br />'
        }
        if (data.forEmergencyTypeId) {
          const emergencyToDisplay: EmergencyModel | undefined = this.emergencyTypes.find(
            (x) => x.emergencyId === data.forEmergencyTypeId
          )
          if (emergencyToDisplay !== undefined) {
            this.tooltipObject.innerHTML += "<b style='color:#4c4c4c'>Emergency: </b>" + emergencyToDisplay.description
          }
        }
      }
      if (typeof data === 'string') {
        this.tooltipObject.innerHTML = "<b style='color:#4c4c4c;font-size:16px;'>" + data + '</b>'
      }

      const eventPropNames: any = Object.keys(event)
      if (!this.coordPropName) {
        // discover the name of the prop with MouseEvent
        for (const i of eventPropNames) {
          const name = i
          if (event[name] instanceof MouseEvent) {
            this.coordPropName = name
            break
          }
        }
      }
      if (this.coordPropName !== null) {
        // position it
        this.tooltipObject.style.position = 'absolute'
        if (event[this.coordPropName] !== undefined) {
          this.tooltipObject.style.top = event[this.coordPropName].clientY + window.scrollY + this.offset.y + 'px'
          this.tooltipObject.style.left = event[this.coordPropName].clientX + window.scrollX + this.offset.x + 'px'
        }
        // add it to the body
        document.body.appendChild(this.tooltipObject)
      }
    }
  }

  private injectOutstandingJobTooltip(event, data: ContractorAppointedModel) {
    if (!this.tooltipObject && event) {
      // create the tooltip object
      this.tooltipObject = document.createElement('div')
      this.tooltipObject.style.width = 'auto'
      this.tooltipObject.style.maxWidth = '200px'
      this.tooltipObject.style.background = 'white'
      this.tooltipObject.style.borderRadius = '5px'
      this.tooltipObject.style.padding = '5px'
      this.tooltipObject.style.display = 'inline-block'
      this.tooltipObject.style.lineHeight = '1.5'
      this.tooltipObject.style.fontFamily = 'Arial,Helvetica'
      this.tooltipObject.style.zIndex = 4

      if (data.customerName) {
        this.tooltipObject.innerHTML = "<b style='color:#4c4c4c'>Name: </b>" + data.customerName + '<br />'
      }
      if (data.companyName) {
        this.tooltipObject.innerHTML += "<b style='color:#4c4c4c'>Company: </b>" + data.companyName + '<br />'
      }
      if (data.address) {
        this.tooltipObject.innerHTML += "<b style='color:#4c4c4c'>Address: </b>" + data.address + '<br />'
      }
      if (data.forEmergencyTypeId) {
        const emergencyToDisplay: EmergencyModel | undefined = this.emergencyTypes.find(
          (x) => x.emergencyId === data.forEmergencyTypeId
        )
        if (emergencyToDisplay !== undefined) {
          this.tooltipObject.innerHTML += "<b style='color:#4c4c4c'>Emergency: </b>" + emergencyToDisplay.description
        }
      }

      const eventPropNames: any = Object.keys(event)
      if (!this.coordPropName) {
        // discover the name of the prop with MouseEvent
        for (const i of eventPropNames) {
          const name = i
          if (event[name] instanceof MouseEvent) {
            this.coordPropName = name
            break
          }
        }
      }
      if (this.coordPropName !== null) {
        // position it
        this.tooltipObject.style.position = 'absolute'
        if (event[this.coordPropName] !== undefined) {
          this.tooltipObject.style.top = event[this.coordPropName].clientY + window.scrollY + this.offset.y + 'px'
          this.tooltipObject.style.left = event[this.coordPropName].clientX + window.scrollX + this.offset.x + 'px'
        }
        // add it to the body
        document.body.appendChild(this.tooltipObject)
      }
    }
  }

  private getRouteToMoveMarker() {
    const frames: any[] = []
    let curLat: any
    let curLng: any
    for (let percent = 0; percent < 1; percent += 0.003) {
      curLat = this.sourceLatitude + percent * (this.previousLatitude - this.sourceLatitude)
      curLng = this.sourceLongitude + percent * (this.previousLongitude - this.sourceLongitude)
      frames.push(new google.maps.LatLng(curLat, curLng))
    }
    // move marker direction
    this.moveMarkerDirection().then((result) => {
      // smoothly move marker by creating break points
      this.moveMarker(this.contractorMarker, frames, 0, 20, this.contractorMarker.position)
    })
  }

  private async moveMarkerDirection(): Promise<boolean> {
    if (this.contractorMarker) {
      const heading = this.headingMagneticNorth
      const icon = this.contractorMarker.getIcon()
      icon.rotation = Number(heading)
      this.contractorMarker.setIcon(icon)
      return true
    } else {
      return false
    }
  }

  private moveMarker(marker: any, latlngs: any, index: any, wait: any, newDestination: any) {
    const self = this
    // set marker position
    marker.setPosition(latlngs[index])
    // set marker as a center of a map on each transaction
    if (
      this.resultMap &&
      this.resultMap.getBounds() &&
      this.resultMap.getBounds().contains(this.contractorMarker.getPosition()) === false
    ) {
      this.resultMap.setCenter(this.contractorMarker.getPosition())
    }
    if (index !== latlngs.length - 1) {
      setTimeout(() => {
        self.moveMarker(marker, latlngs, index + 1, wait, newDestination)
      }, wait)
    } else {
      // assign last reached lat long
      marker.position = newDestination
    }
  }
}
</script>

<style scoped>
.google-map,
.google-map-street-view {
  height: 100%;
  width: 100%;
  max-width: 100%;
  position: relative;
}
.map-view {
  height: 100%;
}

/* Place Card */

.map-container {
  position: relative;
}

.placeDiv {
  z-index: 2;
  position: absolute;
}

.left {
  float: left;
}

.container__map-card {
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  width: 100%;
  background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box;
  border-radius: 2px 2px 2px 2px;
  overflow: hidden;
}

.placecard__container {
  max-width: 330px;
  font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
  padding: 9px 9px 9px 11px;
  margin: 10px;
}

.placecard__left {
  float: left;
  width: 82%;
}

.placecard__right {
  text-align: center;
  float: left;
  width: 18%;
}

.placecard__business-name {
  cursor: default;
  height: 19px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
  perspective-origin: 100px 9.5px;
  transform-origin: 100px 9.5px;
  font: normal normal 500 normal 14px / normal Roboto, Arial;
  overflow: hidden;
  margin: 0;
  display: block;
  text-align: left;
}

.placecard__info {
  color: rgb(91, 91, 91);
  cursor: default;
  height: auto;
  width: 250px;
  column-rule-color: rgb(91, 91, 91);
  perspective-origin: 100px 16px;
  transform-origin: 100px 16px;
  border: 0px none rgb(91, 91, 91);
  font: normal normal normal normal 12px / normal Roboto, Arial;
  margin: 6px 0px 0px;
  outline: rgb(91, 91, 91) none 0px;
  text-align: left;
}

.placecard__direction-link .v-icon {
  height: 30px;
  width: 30px;
  margin: 0px 5px;
  text-align: center;
  color: rgb(58, 132, 223);
  cursor: pointer;
}

.placecard__direction-link {
  color: rgb(58, 132, 223);
  display: block;
  height: 43px;
  text-decoration: none;
  width: 54.7344px;
}

.placecard__view-large {
  display: inline-block;
  margin-top: 10px;
  color: rgb(58, 132, 223);
  text-decoration: none;
  font-size: 12px;
}

.placecard__review {
  font-size: 12px;
  padding-left: 10px;
}

@media only screen and (max-width: 1650px) {
  .placecard__info,
  .placecard__direction-link {
    display: none;
  }
  .placecard__info + div {
    padding-top: 0px !important;
  }
}
/* END Place Card */

.container__map-card-direction {
  max-width: 270px;
  font: normal normal normal 14px / normal Roboto, Arial;
  margin: 10px 15px;
}

.map-card-direction {
  z-index: 4;
  position: absolute;
  right: 10px;
}

.map-card-direction-value {
  font-weight: 500;
  float: left;
}

.map-icons .v-icon {
  height: 40px;
  width: 40px;
  margin: 0px 5px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}
.map-icons .v-icon.active {
  background-color: #3367d6;
}
.map-icons {
  text-align: center;
  background: #4285f4;
}
/* END Travel Card */

/* star-rating CSS */
.placecard__left >>> .vue-star-rating-rating-text {
  font-size: 14px;
  color: #e7711b;
  font-weight: 500;
  margin-top: 0px !important;
  margin-left: 0px !important;
  position: absolute;
}

.placecard__left >>> .vue-star-rating {
  padding-top: 5px;
}

.placecard__left >>> .vue-star-rating span:first-of-type {
  margin-left: 28px;
}
/* END star-rating CSS */
/*Responsive*/
@media (max-width: 1280px) {
  .map-view {
    overflow: hidden;
  }
}

@media (max-width: 1500px) {
  .placeDiv,
  .placeDiv >>> .container__map-card {
    width: calc(100% - 30px);
  }
  .placecard__business-name {
    width: 100%;
  }
}
</style>
