<template>
  <div
    :class="
      !emergency.isEmergencyAccepted && (emergency.acceptRejectNote === null || emergency.acceptRejectNote === '')
        ? 'questions-list-content'
        : ''
    "
  >
    <EmergencyQAEditControl
      v-for="item in items"
      :key="item.id"
      :job-id="jobId"
      :emergency-q-a-id="item.id"
      :get-accept-reject-question-criteria="getAcceptRejectQuestionCriteria"
      @setSyncing="setSyncing"
    />

    <div>
      <HealthAndSafetyQA
        :health-and-safety-q-a="vulnerabilityQAItem"
        :get-accept-reject-question-criteria="getAcceptRejectQuestionCriteria"
        :embedded="false"
        :mandatory="true"
        @setSyncing="setSyncing"
      ></HealthAndSafetyQA>
    </div>

    <v-expansion-panel class="elevation-0">
      <div class="collapse-list">
        <v-expansion-panel-content :lazy="true">
          <div slot="header">
            <span class="additional-information">Additional Information</span>
          </div>
          <v-card class="elevation-0">
            <AccessNote
              :access-note-question="getAccessNoteItem"
              :embedded="true"
              @setSyncing="setSyncing"
            ></AccessNote>
            <HealthAndSafetyQA
              :health-and-safety-q-a="healthAndSafetyQAItem"
              :get-accept-reject-question-criteria="getAcceptRejectQuestionCriteria"
              :embedded="true"
              :mandatory="false"
              @setSyncing="setSyncing"
            ></HealthAndSafetyQA>
          </v-card>
        </v-expansion-panel-content>
      </div>
    </v-expansion-panel>

    <div v-if="showActionButtons()" class="claim-btn-content grey lighten-3">
      <v-card wrap class="elevation-0 grey lighten-3">
        <v-card-actions xs12>
          <v-btn
            class="blue white--text btn-soft-fix"
            :disabled="
              isEmergencyRejected || getAcceptButtonCriteria || isSyncing || isActionPerformed || questionMissing
            "
            @click="onSoftFixEmergencyDialogOpen()"
          >
            Soft Fix Emergency
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="btn-reject"
            :class="getRejectButtonCriteria || abilityToAccept ? 'red white--text' : 'grey'"
            :disabled="
              isEmergencyRejected ||
              (!getRejectButtonCriteria && !abilityToAccept) ||
              isSyncing ||
              isActionPerformed ||
              questionMissing
            "
            small-xs
            @click="onRejectEmergencyDialogOpen"
          >
            {{ isEmergencyRejected ? 'Emergency Rejected' : 'Reject Emergency' }}
          </v-btn>
          <v-btn
            class="btn-accept"
            :class="!getAcceptButtonCriteria ? 'green white--text' : isAcceptEmergencyLoading ? 'grey' : ''"
            :disabled="
              getAcceptButtonCriteria || isAcceptEmergencyLoading || isSyncing || isActionPerformed || questionMissing
            "
            :loading="isAcceptEmergencyLoading"
            @click="onAcceptEmergency"
          >
            {{ isEmergencyRejected ? 'Overwrite Rejection' : 'Accept Emergency' }}
          </v-btn>
          <v-btn
            v-if="isEmergencyRejected"
            :class="!getAcceptButtonCriteria ? 'green white--text' : 'grey'"
            class="btn-pay"
            :disabled="
              emergency.ctpCompletedForRejectedEmergency ||
              isSyncing ||
              rejectEmergencyAsyncLoading ||
              isActionPerformed
            "
            :loading="rejectEmergencyAsyncLoading"
            @click="ctpForRejectedEmergency"
          >
            Pay for Emergency
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-dialog v-model="rejectEmergencyDialog" max-width="650" content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Reject Emergency</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="onRejectEmergencyDialogClose">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 scroll-content-dialog">
          <v-form>
            <v-layout row wrap>
              <v-flex xs12>
                <v-autocomplete
                  v-model="emergencyAcceptOrReject.repudiationDescription"
                  v-validate="'required'"
                  label="Repudiation reason"
                  :items="repudiations"
                  :loading="repudiationsLoading"
                  item-text="description"
                  item-value="description"
                  required
                  class="required repudiation-reason"
                  data-vv-scope="frmRejectEmergency"
                  data-vv-name="Repudiation reason"
                  :error-messages="errors.collect('Repudiation reason')"
                  :search-input.sync="emergencyAcceptOrReject.repudiationDescription"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="emergencyAcceptOrReject.acceptRejectNote"
                  label="Description"
                  rows="7"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="btn-cancel" flat @click="onRejectEmergencyDialogClose">Cancel</v-btn>
          <v-btn color="primary" class="mr-0 btn-reject-emergency" @click="onRejectEmergency">Reject</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="acceptEmergencyDialog" max-width="650" content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Accept Emergency</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="onAcceptEmergencyDialogClose">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog px-3">
          <v-form>
            <v-layout row wrap>
              <v-flex xs12>
                <v-textarea
                  v-model="emergencyAcceptOrReject.acceptRejectNote"
                  v-validate="'required'"
                  label="Description"
                  required
                  class="required"
                  name="acceptRejectNote"
                  data-vv-scope="frmAcceptEmergency"
                  data-vv-name="Description"
                  :error-messages="errors.collect('Description')"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="btn-cancel-accept" flat @click="onAcceptEmergencyDialogClose">Cancel</v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-accept-emergency"
            :loading="isLoading"
            :disabled="isLoading"
            @click="onAcceptEmergencyWithoutCriteria"
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="softFixEmergencyDialog" max-width="650" content-class="v-dialog--scrollable">
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Soft Fix Emergency</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="onSoftFixEmergencyDialogClose">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />
        <v-card-text class="scroll-content-dialog px-3">
          <v-form>
            <v-layout row wrap>
              <v-flex>
                <v-select
                  v-model="softFixedEmergency.title"
                  v-validate="'required'"
                  :items="softFixTypeList"
                  item-text="description"
                  item-value="id"
                  label="What is the type of soft fix"
                  required
                  name="softFixType"
                  class="required"
                  data-vv-scope="frmSoftFixEmergency"
                  data-vv-name="Soft Fix Type"
                  :error-messages="errors.collect('Soft Fix Type')"
                  @change="onSoftFixTypeChange"
                >
                  <template slot="item" slot-scope="data">
                    <v-list-tile>
                      <v-list-tile-content>
                        <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                          {{ data.item.description }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="softFixedEmergency.description"
                  label="Description"
                  data-vv-name="Description"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="btn-cancel-soft-fix" flat @click="onSoftFixEmergencyDialogClose">Cancel</v-btn>
          <v-btn
            color="primary"
            class="mr-0 btn-soft-accept"
            :loading="isLoading"
            :disabled="isLoading"
            @click="onSoftFixEmergencyAccept"
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="configureDropdownDialog" max-width="700" persistent content-class="v-dialog--scrollable">
      <ConfigureDropdown
        ref="configureDropdown"
        :record-type="configureDropdownType"
        @CloseConfigureDropdownDialog="onConfigureDropdownDialogClose"
      ></ConfigureDropdown>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
    <v-snackbar v-model="showEscalateCTPProcessSnackbar" :timeout="snackbarTimeout" bottom left>
      CTP process escalated to team leader.
      <v-btn flat color="secondary" @click="showEscalateCTPProcessSnackbar = false"><v-icon>close</v-icon></v-btn>
    </v-snackbar>
    <!-- confirmation dialog for customer want to use Extra Balance -->
    <v-dialog
      v-if="useExtraBalanceConfirmationDialog"
      v-model="useExtraBalanceConfirmationDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="pa-3">
          <h3>
            {{
              'You have ' +
              getFormatedCurrency(emergencyResponseModel.extraBalanceLeft) +
              ' extra balance. Do you want to use this balance to proceed further?'
            }}
          </h3>
        </v-card-title>
        <v-card-actions class="pt-2 px-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn class="btn-no" small flat @click="onCancelExtraBalanceConfirmationDialog">No</v-btn>
          <v-btn
            class="btn-yes"
            color="primary"
            small
            :disabled="useExtraBalanceLoading"
            :loading="useExtraBalanceLoading"
            @click="onConfirmUseExtraBalance"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="doCTPForRejectedEmergency" v-model="doCTPForRejectedEmergency" persistent max-width="400">
      <v-card>
        <v-card-title class="pa-3">
          <h3>{{ 'This emergency is rejected, do you want to trigger CTP and proceed further?' }}</h3>
        </v-card-title>
        <v-card-actions class="pt-2 px-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn
            class="btn-no"
            small
            flat
            :loading="rejectEmergencyAsyncLoading"
            :disabled="rejectEmergencyAsyncLoading"
            @click="onCancelDoCTPForRejectedEmergency"
          >
            No
          </v-btn>
          <v-btn
            class="btn-yes"
            color="primary"
            small
            :disabled="isLoading"
            :loading="isLoading"
            @click="onConfirmDoCTPForRejectedEmergency"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import EmergencyQA from '@/models/EmergencyQA'
import storeGetters from '@/storeGetters'
import moment from 'moment'

import EmergencyQAEditControl from '@/components/timeline/EmergencyQAEditControl.vue'
import HealthAndSafetyQA from '@/components/timeline/HealthAndSafetyQA.vue'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import store from '@/store'
import JobController from '@/api/jobController'
import UpdateEmergencyAcceptedOrRejected from '@/models/requests/UpdateEmergencyAcceptedOrRejected'
import Shared from '@/common/shared'
import RepudiationModel from '@/models/policyHolder/RepudiationModel'
import PolicyHolderController from '@/api/policyHolderController'
import MasterRecord from '@/models/MasterRecord'
import { RecordType } from '@/common/enums'
import MasterRecordController from '@/api/masterRecordController'
import SoftFixedEmergency from '@/models/claim/SoftFixedEmergencyModel'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import eventBus from '@/common/bus'
import AccessNote from '@/components/timeline/AccessNote.vue'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import AddRequestToEscalateCTPProcessModel from '@/models/requests/AddRequestToEscalateCTPProcessModel'
import CheckPolicyLimitResponse from '@/models/claim/CheckPolicyLimitResponse'
import EscalateCTPResponseModel from '@/models/claim/EscalateCTPResponseModel'
import PaymentController from '@/api/paymentController'
import { useCustomerToPayStore } from '@/pinia/customer-to-pay'

@Component({
  components: { EmergencyQAEditControl, HealthAndSafetyQA, ConfigureDropdown, AccessNote },
})
export default class EmergencyQAPreview extends TimeLineItemBase {
  private rejectEmergencyDialog = false
  private acceptEmergencyDialog = false
  private softFixEmergencyDialog = false
  private configureDropdownDialog = false
  private isLoading = false
  private isAcceptEmergencyLoading = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private emergencyAcceptOrReject: UpdateEmergencyAcceptedOrRejected = new UpdateEmergencyAcceptedOrRejected()
  private syncing = false
  private repudiations: RepudiationModel[] = []
  private repudiationsLoading = false
  private softFixTypeList: MasterRecord[] = []
  private softFixedEmergency: SoftFixedEmergency = new SoftFixedEmergency()
  private configureDropdownType = ''
  private showEscalateCTPProcessSnackbar = false
  private useExtraBalanceConfirmationDialog = false
  private doCTPForRejectedEmergency = false
  private emergencyResponseModel: CheckPolicyLimitResponse = new CheckPolicyLimitResponse()
  private useExtraBalanceLoading = false
  private isActionPerformed = false // if true disable all buttons to accept/reject/soft fix emergency
  private rejectEmergencyAsyncLoading = false
  private abilityToAccept = false
  private questionMissing = false

  private readonly repudiationMessage =
    'From review of the information provided, this is not covered under the wording and definition of your Home Emergency insurance policy and the claim has been declined. ' +
    'Unless needed, there will be no further correspondence sent to you regarding this decision. ' +
    'We do have a specialist team who will be happy to discuss with you, all your options on the next steps and provide you with a quote on a payable basis. ' +
    'I am happy to transfer you over to the team now so they can discuss your options with you?'

  // isSyncing disable accept/reject button until sync is not completed
  private get isSyncing(): boolean {
    return this.syncing
  }

  private setSyncing(sync: boolean) {
    this.syncing = sync
  }

  private get items(): EmergencyQA[] {
    if (this.jobId && this.emergencyId) {
      return storeGetters
        .getQAsForEmergency(this.jobId, this.emergencyId)
        .sort((a, b) => a.orderByForDisplay - b.orderByForDisplay)
    }
    return []
  }

  private get isUserRoleSupervisor(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor'
    )
  }

  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private get isUserRoleIM(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'IncidentManager'
    )
  }

  private get getUserAbilityToAcceptEmergency() {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.canAcceptRejectEmergency
    )
  }

  private get isEmergencyRejected(): boolean {
    if (!this.emergency || this.emergency.isEmergencyAccepted) {
      return false
    }
    return !!(this.emergency.repudiationDescription || this.emergency.ctpCompletedForRejectedEmergency)
  }

  private mounted() {
    eventBus.$off('sendQuestionLink').$on('sendQuestionLink', () => {
      this.sendQuestionModeLink()
    })
  }

  private get getRejectButtonCriteria(): boolean {
    if (this.emergency && this.emergency.isEmergencyAccepted) {
      return false
    } else if (this.isUserRoleAdministrator) {
      return true
    } else if (!this.isEmergencyCovered && !this.getUserAbilityToAcceptEmergency) {
      return true
    } else if (this.isUserRoleSupervisor || this.isUserRoleClientManager) {
      if (this.getUserAbilityToAcceptEmergency) {
        this.abilityToAccept = true
        return true
      } else {
        const result: boolean = this.criteriaToAcceptEmergency() === true ? false : true
        return result
      }
    } else if (this.isUserRoleIM) {
      if (this.getUserAbilityToAcceptEmergency) {
        this.abilityToAccept = true
        return true
      } else {
        const result: boolean = this.criteriaToAcceptEmergency() === true ? false : true
        return result
      }
    } else {
      return true
    }
  }

  private get getAcceptButtonCriteria(): boolean {
    if (this.emergency && this.emergency.isEmergencyAccepted) {
      return true
    } else if (this.isUserRoleAdministrator) {
      return false
    } else if (!this.isEmergencyCovered && !this.getUserAbilityToAcceptEmergency) {
      return true
    } else if (this.isUserRoleSupervisor || this.isUserRoleClientManager) {
      if (this.getUserAbilityToAcceptEmergency) {
        this.abilityToAccept = true
        return false
      } else {
        const result: boolean = this.criteriaToAcceptEmergency() === true ? false : true
        return result
      }
    } else if (this.isUserRoleIM) {
      if (this.getUserAbilityToAcceptEmergency) {
        this.abilityToAccept = true
        return false
      } else {
        const result: boolean = this.criteriaToAcceptEmergency() === true ? false : true
        return result
      }
    } else {
      return true
    }
  }

  private get healthAndSafetyQAItem(): HealthAndSafetyQAModel {
    if (this.jobId && this.emergencyId) {
      return storeGetters.getHealthAndSafetyQAForEmergency(this.jobId, this.emergencyId)
    }
    return new HealthAndSafetyQAModel()
  }

  private get vulnerabilityQAItem(): VulnerabilityQAModel {
    return storeGetters.getVulnerabilityQA(this.jobId)
  }

  private get getAccessNoteItem(): AccessNoteModel {
    return storeGetters.getAccessNote(this.jobId)
  }

  // Accept Emergency with criteria
  private onAcceptEmergency() {
    if (this.criteriaToAcceptEmergency() === false) {
      this.acceptEmergencyDialog = true
    } else {
      this.isAcceptEmergencyLoading = true
      this.AcceptEmergency()
    }
  }

  private onAcceptEmergencyDialogClose() {
    this.questionMissing = false
    this.acceptEmergencyDialog = false
    this.$validator.errors.items = []
    this.emergencyAcceptOrReject = new UpdateEmergencyAcceptedOrRejected()
  }

  private onRejectEmergencyDialogClose() {
    this.rejectEmergencyDialog = false
    this.$validator.errors.items = []
    this.emergencyAcceptOrReject = new UpdateEmergencyAcceptedOrRejected()
  }

  private onSoftFixEmergencyDialogClose() {
    this.softFixEmergencyDialog = false
  }

  private get getAbilityToAccessCustomerToPay(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.hasCustomerToPayAccess
    )
  }

  // Reject Emergency
  private onRejectEmergency() {
    this.validate('frmRejectEmergency')
      .then((result: boolean) => {
        if (result) {
          this.doCTPForRejectedEmergency = true
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  // Accept Emergency without criteria
  private onAcceptEmergencyWithoutCriteria() {
    this.validate('frmAcceptEmergency')
      .then((result: boolean) => {
        if (result) {
          this.isLoading = true
          this.AcceptEmergency()
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  // Accept Emergency
  private AcceptEmergency() {
    this.emergencyAcceptOrReject.id = this.emergencyId
    this.emergencyAcceptOrReject.jobId = this.jobId
    this.emergencyAcceptOrReject.isEmergencyAccepted = true
    JobController.UpdateEmergencyAcceptedOrRejected(this.emergencyAcceptOrReject)
      .then((res: CheckPolicyLimitResponse | null) => {
        if (res) {
          if (res.requiredToTriggerCTP) {
            this.isLoading = false
            this.acceptEmergencyDialog = false
            this.isAcceptEmergencyLoading = false
            this.completeCTPProcess(res)
          } else {
            this.emergencyAcceptOrReject = new UpdateEmergencyAcceptedOrRejected()
            this.isAcceptEmergencyLoading = false
            this.isLoading = false
            this.snackbarText = 'Emergency Accepted.'
            this.snackbar = true
            this.acceptEmergencyDialog = false
            this.isActionPerformed = true
          }
        }
      })
      .catch((err: any) => {
        this.isAcceptEmergencyLoading = false
        this.isLoading = false
      })
  }

  private completeCTPProcess(emergencyResponseModel: CheckPolicyLimitResponse) {
    if (this.getAbilityToAccessCustomerToPay) {
      if (emergencyResponseModel.extraBalanceLeft > 0) {
        // open confirmation popup, whether customer want to use extra balance or not
        this.emergencyResponseModel = emergencyResponseModel
        this.useExtraBalanceConfirmationDialog = true
      } else {
        // open confirmation popup for trigger CTP process
        Shared.confirmationPopup.open(
          'Do you want to pay ' +
            this.getFormatedCurrency(emergencyResponseModel.extraAmountNeedToPay) +
            ' to proceed further?',
          '',
          '',
          'No',
          'Yes',
          this,
          'makeCustomerToPay',
          emergencyResponseModel
        )
        return
      }
    } else {
      // open confirmation popup to escalate CTP process to team leader
      const escalateCTPProcess: AddRequestToEscalateCTPProcessModel = new AddRequestToEscalateCTPProcessModel()
      escalateCTPProcess.jobId = this.jobId
      escalateCTPProcess.escalateBy = store.Instance.state.SessionDetail.userName
      escalateCTPProcess.description =
        'Customer To Pay Process is required as a consequence of total cost of emergency goes beyond the policy limit.'
      Shared.confirmationPopup.open(
        'You do not have permission to perform customer to pay. Do you want to escalate this to your team leader?',
        '',
        '',
        'No',
        'Yes',
        this,
        'escalateCTPProcess',
        escalateCTPProcess
      )
    }
  }

  private escalateCTPProcess(escalateCTPProcess: AddRequestToEscalateCTPProcessModel) {
    // user agreed to escalate CTP process, add request to add audit log
    JobController.AddRequestToEscalateCTPProcess(escalateCTPProcess)
      .then((response: boolean) => {
        if (response) {
          this.isAcceptEmergencyLoading = false
          this.emergencyAcceptOrReject = new UpdateEmergencyAcceptedOrRejected()
          this.showEscalateCTPProcessSnackbar = true
        }
        return
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting request to Escalate CTP Process, please try again', true)
      })
  }

  private makeCustomerToPay(model: CheckPolicyLimitResponse) {
    // trigger CTP process
    eventBus.$emit('makeCustomerToPay')
    let amountNeedToPay = 0
    // set repudiation reason
    this.emergencyAcceptOrReject.repudiationDescription =
      this.emergency && this.emergency.repudiationDescription ? this.emergency.repudiationDescription : ''
    const escalateCTPResponseModel: EscalateCTPResponseModel = new EscalateCTPResponseModel()
    escalateCTPResponseModel.extraAmountNeedToPay = model.extraAmountNeedToPay
    escalateCTPResponseModel.useExtraBalance = false
    escalateCTPResponseModel.recordType = 'EmergencyDetail'
    escalateCTPResponseModel.acceptRejectEmergency = this.emergencyAcceptOrReject
    amountNeedToPay = model.extraAmountNeedToPay
    useCustomerToPayStore().setAmount(amountNeedToPay)
    // wait till the customerToPay Card Added to timeline
    setTimeout(() => {
      eventBus.$emit('escalateCTPResponse', escalateCTPResponseModel, amountNeedToPay, this.emergencyId)
    }, 0)
  }

  private onConfirmUseExtraBalance() {
    let amountNeedToPay = 0
    const escalateCTPResponseModel: EscalateCTPResponseModel = new EscalateCTPResponseModel()
    escalateCTPResponseModel.extraAmountNeedToPay = this.emergencyResponseModel.extraAmountNeedToPay
    escalateCTPResponseModel.useExtraBalance = true
    escalateCTPResponseModel.recordType = 'EmergencyDetail'
    escalateCTPResponseModel.acceptRejectEmergency = this.emergencyAcceptOrReject
    // customer agree to use extra balance, check CTP process required or not
    if (this.emergencyResponseModel.extraAmountNeedToPay <= this.emergencyResponseModel.extraBalanceLeft) {
      this.useExtraBalanceLoading = true
      PaymentController.ApproveCTPTriggerRequest(escalateCTPResponseModel, this.jobId)
        .then((res: boolean) => {
          if (res) {
            this.emergencyAcceptOrReject = new UpdateEmergencyAcceptedOrRejected()
            if (this.isAcceptEmergencyLoading) {
              this.isAcceptEmergencyLoading = false
              this.snackbarText = 'Emergency Accepted.'
              this.isActionPerformed = true
            } else if (this.rejectEmergencyDialog) {
              this.rejectEmergencyDialog = false
              this.snackbarText = 'Emergency Rejected.'
              this.isActionPerformed = true
            }
            this.useExtraBalanceLoading = false
            this.snackbar = true
            this.useExtraBalanceConfirmationDialog = false
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error submitting approve CTP Request, please try again', true)
          this.useExtraBalanceLoading = false
        })
    } else {
      // trigger CTP process for amount (extraAmountNeedToPay - extraBalanceLeft)
      eventBus.$emit('makeCustomerToPay')
      amountNeedToPay = this.emergencyResponseModel.extraAmountNeedToPay - this.emergencyResponseModel.extraBalanceLeft
      // wait till the customerToPay Card Added to timeline
      setTimeout(() => {
        eventBus.$emit('escalateCTPResponse', escalateCTPResponseModel, amountNeedToPay, this.emergencyId)
      }, 0)
    }
  }

  private onCancelExtraBalanceConfirmationDialog() {
    // trigger CTP process
    this.makeCustomerToPay(this.emergencyResponseModel)
  }

  private async validate(scope: string): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll(scope)

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  // criteria to accept emergency
  private criteriaToAcceptEmergency(): boolean {
    let mandatoryQA = true
    let invalidQA = true
    let fulfilledCutOff = true
    this.questionMissing = false

    // All mandatory questions have been answered.
    this.items.forEach((element: EmergencyQA) => {
      if (!element.isValid && element.validationStatus === 'missing') {
        mandatoryQA = false
        this.questionMissing = true
        return
      }
    })

    if (this.vulnerabilityQAItem.isHealthAffected === null) {
      mandatoryQA = false
      this.questionMissing = true
    }

    // There are no invalid responses to any questions.
    this.items.forEach((element: EmergencyQA) => {
      if (!element.isValid && element.validationStatus === 'invalid') {
        invalidQA = false
        return
      }
    })

    // The emergency was noticed within the FNOL cut-off period.
    if (this.emergency) {
      const createdAt = moment(this.emergency.createdAt)
      const duration = moment.duration(createdAt.diff(this.emergency.firstNoticedAt))
      const hours = duration.asHours()
      if (this.emergency.cutOffHours && hours > this.emergency.cutOffHours) {
        fulfilledCutOff = false
      }
    }

    // Check all criteria to accept emergency
    if (mandatoryQA && invalidQA && fulfilledCutOff) {
      return true
    }

    return false
  }

  // Open Reject Emergency Dialog
  private onRejectEmergencyDialogOpen(): void {
    const self = this
    self.rejectEmergencyDialog = true
    self.repudiationsLoading = true
    PolicyHolderController.GetRepudiationReasons()
      .then((res: RepudiationModel[]) => {
        if (res) {
          self.repudiations = res
          for (const element of self.items) {
            if (element.validationStatus === 'invalid' && element.repudiationDescription) {
              const item: RepudiationModel | undefined = self.repudiations.find(
                (e: RepudiationModel) => e.description === element.repudiationDescription
              )
              if (item) {
                self.emergencyAcceptOrReject.repudiationDescription = item.description
                self.emergencyAcceptOrReject.acceptRejectNote = this.repudiationMessage
                break
              }
            }
          }
          self.repudiationsLoading = false
          if (self.emergency && !self.emergency.isCovered && !self.emergencyAcceptOrReject.repudiationDescription) {
            const repudiation = self.repudiations.find(
              (r) => r.id === store.Instance.state.Environment.EmergencyNotCoveredRepudiationId
            )
            if (repudiation) {
              self.emergencyAcceptOrReject.repudiationDescription = repudiation.description
              self.emergencyAcceptOrReject.acceptRejectNote = this.repudiationMessage
            }
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading repudiation list, please try again', true)
      })
  }

  private onSoftFixEmergencyDialogOpen() {
    this.GetSoftFixTypeList()
    this.softFixEmergencyDialog = true
  }

  // Get list of soft fix type.
  private GetSoftFixTypeList(): void {
    const recordType: string = RecordType[RecordType.SoftFixedEmergencyTitle]
    this.configureDropdownType = RecordType[RecordType.SoftFixedEmergencyTitle]
    MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
      if (res) {
        if (this.softFixedEmergency.type !== undefined) {
          this.softFixTypeList = res.filter(
            (e) => e.isDeleted === false || (e.isDeleted && this.softFixedEmergency.type === e.id)
          )
        } else {
          this.softFixTypeList = res.filter((e) => e.isDeleted === false)
        }
        if (this.isUserRoleAdministrator) {
          const softFixTypeConfigure: any = {}
          softFixTypeConfigure.description = 'Configure'
          this.softFixTypeList.push(softFixTypeConfigure)
        }
      }
    })
  }

  private onConfigureDropdownDialogClose(Items: any[]) {
    this.configureDropdownDialog = false
    this.softFixedEmergency.title = ''
    const deletedRecord: MasterRecord | undefined = this.softFixTypeList.find((e) => e.isDeleted === true)
    this.softFixTypeList = Items.slice(0)
    if (deletedRecord !== undefined) {
      this.softFixTypeList.push(deletedRecord)
    }
    if (this.isUserRoleAdministrator) {
      const softFixType: any = {}
      softFixType.description = 'Configure'
      this.softFixTypeList.push(softFixType)
    }
  }

  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on SoftFixedType.
  private onSoftFixTypeChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.SoftFixedEmergencyTitle]
      this.configureDropdownDialog = true
    }
  }

  private onSoftFixEmergencyAccept() {
    this.validate('frmSoftFixEmergency')
      .then((result: boolean) => {
        if (result) {
          this.isLoading = true
          this.softFixedEmergency.jobId = this.jobId
          this.softFixedEmergency.emergencyId = this.emergency ? this.emergency.id : ''
          JobController.AddSoftFixedEmergency(this.softFixedEmergency)
            .then((res: boolean) => {
              if (res) {
                this.snackbarText = 'Emergency Soft Fixed.'
                this.snackbar = true
                this.isLoading = false
                this.softFixEmergencyDialog = false
                this.isActionPerformed = true
              }
            })
            .catch((err: any) => {
              this.isLoading = false
              eventBus.$emit('errorHandler', 'Error submitting SoftFixed Emergency detail, please try again', true)
            })
        }
      })
      .catch((err: any) => {
        this.isLoading = false
        eventBus.$emit('validationErrorHandler')
      })
  }

  private showActionButtons() {
    if (!this.job || !this.emergency) {
      return false
    }
    if (this.job.status === 'Completed' || this.job.status === 'Cancelled' || this.job.cancellationReason) {
      return false
    }
    if (this.isEmergencyRejected) {
      return true
    }
    return !this.emergency.isEmergencyAccepted && !this.emergency.isEmergencySoftFixed
  }

  private get getAcceptRejectQuestionCriteria(): boolean {
    if (
      this.emergency &&
      (this.emergency.isEmergencyAccepted ||
        (!this.emergency.isEmergencyAccepted && this.emergency.acceptRejectNote) ||
        this.emergency.isEmergencySoftFixed)
    ) {
      return true
    }
    return false
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private onConfirmDoCTPForRejectedEmergency() {
    this.emergencyAcceptOrReject.id = this.emergencyId
    this.emergencyAcceptOrReject.jobId = this.jobId
    this.emergencyAcceptOrReject.isEmergencyAccepted = false
    this.isLoading = true
    JobController.UpdateEmergencyAcceptedOrRejected(this.emergencyAcceptOrReject)
      .then((res: CheckPolicyLimitResponse | null) => {
        if (res) {
          if (res.requiredToTriggerCTP) {
            this.isLoading = false
            this.doCTPForRejectedEmergency = false
            this.rejectEmergencyDialog = false
            this.completeCTPProcess(res)
          } else {
            this.emergencyAcceptOrReject = new UpdateEmergencyAcceptedOrRejected()
            this.doCTPForRejectedEmergency = false
            this.rejectEmergencyDialog = false
            this.isLoading = false
            this.snackbarText = 'Emergency Rejected.'
            this.snackbar = true
            this.isActionPerformed = true
          }
        }
      })
      .catch((err: any) => {
        this.isLoading = false
      })
  }

  private onCancelDoCTPForRejectedEmergency() {
    this.emergencyAcceptOrReject.id = this.emergencyId
    this.emergencyAcceptOrReject.jobId = this.jobId
    this.emergencyAcceptOrReject.isEmergencyAccepted = false
    this.rejectEmergencyAsyncLoading = true
    JobController.RejectEmergencyAsync(this.emergencyAcceptOrReject)
      .then((res: boolean) => {
        if (res) {
          this.emergencyAcceptOrReject = new UpdateEmergencyAcceptedOrRejected()
          this.doCTPForRejectedEmergency = false
          this.rejectEmergencyDialog = false
          this.rejectEmergencyAsyncLoading = false
          this.snackbarText = 'Emergency Rejected.'
          this.snackbar = true
          this.isActionPerformed = true
        }
      })
      .catch((err: any) => {
        this.rejectEmergencyAsyncLoading = false
      })
  }

  private ctpForRejectedEmergency() {
    const emergencyAcceptOrRejectModel = new UpdateEmergencyAcceptedOrRejected()
    emergencyAcceptOrRejectModel.id = this.emergencyId
    emergencyAcceptOrRejectModel.jobId = this.jobId
    emergencyAcceptOrRejectModel.isEmergencyAccepted = false
    emergencyAcceptOrRejectModel.repudiationDescription =
      this.emergency && this.emergency.repudiationDescription ? this.emergency.repudiationDescription : ''
    this.rejectEmergencyAsyncLoading = true
    JobController.UpdateEmergencyAcceptedOrRejected(emergencyAcceptOrRejectModel)
      .then((res: CheckPolicyLimitResponse | null) => {
        if (res) {
          this.emergencyAcceptOrReject = Object.assign(
            new UpdateEmergencyAcceptedOrRejected(),
            emergencyAcceptOrRejectModel
          )
          if (res.requiredToTriggerCTP) {
            this.rejectEmergencyAsyncLoading = false
            this.completeCTPProcess(res)
          } else {
            this.emergencyAcceptOrReject = new UpdateEmergencyAcceptedOrRejected()
            this.rejectEmergencyAsyncLoading = false
            this.isActionPerformed = true
          }
        }
      })
      .catch((err: any) => {
        this.rejectEmergencyAsyncLoading = false
      })
  }

  private get isUserRoleClientManager(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'ClientManager'
    )
  }

  private sendQuestionModeLink() {
    JobController.SendQuestionModeLink(this.jobId)
      .then((res: boolean) => {
        this.snackbarText = res ? 'Question Mode Link Sent to Customer.' : 'Something went wrong. Please try again!'
        this.snackbar = true
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error sending question link to customer, please try again', true)
        this.snackbarText = 'Something went wrong. Please try again!'
        this.snackbar = true
      })
  }

  private get isEmergencyCovered(): boolean {
    if (this.emergency) {
      return this.emergency.isCovered
    }
    return false
  }
}
</script>

<style scoped>
.questions-list-content {
  position: relative;
  padding-bottom: 50px;
}
.bold-select {
  font-weight: 600;
}
.v-text-field label {
  position: absolute;
  top: 18px;
  left: 0;
  white-space: inherit;
  word-wrap: break-word;
  height: auto;
  line-height: 1.4;
  max-width: 100%;
}
.collapse-list {
  flex: 1;
}
.additional-information {
  font-weight: 600;
  font-size: large;
}
</style>
