<template>
  <div>
    <v-select
      v-model="selectedPaymentTerm"
      v-validate="'required'"
      label="Set Payment Terms"
      :items="paymentTermOptions"
      data-vv-scope="sendInvoiceValidator"
      required
      data-vv-name="Payment Terms"
      :error-messages="errors.collect('Payment Terms')"
    ></v-select>
    <p class="text-left font-weight-bold mt-3">Payment Script</p>
    <p class="text-left font-italic mb-3"
      >As we have been unable to take a card payment, I will now issue an invoice to the email address you have
      provided. The invoice can be paid securely using a debit or credit card, Apple Pay, or Google Pay. Please note
      that we may not be able to proceed with the scheduled work until the payment has been received. Are you happy for
      me to issue this invoice?</p
    >
    <v-checkbox
      v-validate="'required'"
      data-vv-scope="sendInvoiceValidator"
      required
      class="required prepend-label"
      data-vv-name="Confirmation"
      :error-messages="errors.collect('Confirmation')"
    >
      <template #label>
        <span class="invoice-text">
          I confirm that I have read the above to the customer and have been authorized to send the invoice via email
        </span>
      </template></v-checkbox
    >
    <v-layout class="justify-end mt-2">
      <v-flex xs4 class="d-flex justify-end">
        <v-btn color="error" class="white--text" @click="$emit('cancelInvoice')">Cancel</v-btn>
        <v-btn :color="brandColour" class="white--text" @click="onSendInvoicePress">Send Invoice</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { colours } from '@/common/themes'
import { useCustomerToPayStore } from '@/pinia/customer-to-pay'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class PaymentInvoice extends Vue {
  public store = useCustomerToPayStore()
  public paymentTermOptions = ['Today', 'Tomorrow', '7 days', '14 days', '30 days', '45 days', '60 days', '90 days']
  public selectedPaymentTerm = 'Tomorrow'
  public brandColour = colours.brand

  public async onSendInvoicePress() {
    const result = await this.$validator.validateAll('sendInvoiceValidator')

    if (!result) return
  }
}
</script>

<style scoped>
.invoice-text {
  font-size: 0.75em;
}

.prepend-label:deep(label) {
  order: -1;
  margin-right: 0.5em;
}
</style>
