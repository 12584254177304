import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import EventEmitter from 'events'
import TwilioUpdateDTO from '@/api/models/assistedPay/TwilioUpdateDTO'
import TwilioTokenDTO from '@/api/models/assistedPay/TwilioTokenDTO'
import TwilioCardBrandDTO from '@/api/models/assistedPay/TwilioCardBrandDTO'
import { logger } from '@/plugins/datadog'

const UPDATE_RECEIVED_HANDLER = 'UpdateReceived'
const PAYMENT_COMPLETED_HANDLER = 'PaymentCompleted'
const BRAND_RECEIVED_HANDLER = 'BrandReceived'

// OBSOLETE: Please use AgentAssistSignalRWatcher.ts instead
export default class AssistedPaySignalRWatcher extends EventEmitter {
  public constructor() {
    super()
    this.signalRHub = new SignalRHubConnection('TwilioAssistedPay')
    this.connect()
  }

  public disconnect() {
    if (this.signalRHub != null) {
      this.signalRHub.disconnect()
    }
  }

  private signalRHub: SignalRHubConnection

  private connect() {
    this.signalRHub.addHandler(UPDATE_RECEIVED_HANDLER, (error: string, payload: TwilioUpdateDTO) => {
      logger.info(`${UPDATE_RECEIVED_HANDLER}HandlerTriggered`)
      this.emit('updateReceived', error, payload)
    })

    this.signalRHub.addHandler(PAYMENT_COMPLETED_HANDLER, (error: string, payload: TwilioTokenDTO) => {
      logger.info(`${PAYMENT_COMPLETED_HANDLER}HandlerTriggered`)
      this.emit('tokenReceived', error, payload)
    })

    this.signalRHub.addHandler(BRAND_RECEIVED_HANDLER, (error: string, payload: TwilioCardBrandDTO) => {
      logger.info(`${BRAND_RECEIVED_HANDLER}HandlerTriggered`)
      this.emit('brandReceived', error, payload)
    })

    this.signalRHub.connect()
  }
}
