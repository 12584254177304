<template>
  <v-btn color="secondary" class="action-button" :small="false" :disabled="disabled" @click="$emit('click')">{{
    isSet ? 'Update' : 'Capture'
  }}</v-btn>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class AgentAssistRetrievalButton extends Vue {
  @Prop() public disabled: boolean
  @Prop() public isSet: boolean
}
</script>

<style scoped>
.action-button {
  border-radius: 28px;
  font-size: 11px;
  size: 24;
}
</style>
