import * as Twilio from '@twilio/voice-sdk'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import TwilioController from '@/api/twilioController'
import eventBus from '@/common/bus'

export const useTwilioDevice = defineStore('twilio-device', () => {
  const initialized = ref<boolean>(false)
  const instance = ref<Twilio.Device>()

  function onRegistered() {
    // Clear any twilio warnings
    eventBus.$emit('clearTwilioSnackbar')

    if (!instance.value?.audio) {
      return
    }
    // Play the call audio through the communications device
    instance.value.audio.speakerDevices.set(['communications'])
    // Play incoming call sounds (ringing sounds) through both the default and communications devices
    instance.value.audio.ringtoneDevices.set(['default', 'communications'])
  }

  async function onTokenWillExpire() {
    await refreshToken()
  }

  async function onUnregistered() {
    await refreshToken()
  }

  async function onError(error: Twilio.TwilioError.TwilioError) {
    const errorMessage = `Twilio Device. Error Code: ${error.code}. Error Message: ${error.message}`
    eventBus.$emit('errorHandler', errorMessage)

    // https://www.twilio.com/docs/voice/sdks/error-codes
    switch (error.code) {
      case 20101: // Other Twilio JWT no longer valid
      case 20104: // Access Token expired or expiration date invalid
      case 31204: // Invalid JWT token
      case 31205: // JWT token expired
        await refreshToken()
        break
      case 31201: // Generic unknown error
      case 31208: // User denied access to microphone
        eventBus.$emit('showTwilioSnackbar', 'Error accessing microphone', false)
        console.error('Could not access the microphone')
        break
      default:
        console.error(errorMessage)
    }
  }

  async function init() {
    if (initialized.value) {
      return
    }

    const clientToken = await TwilioController.GetClientToken()
    instance.value = new Twilio.Device(clientToken, {
      closeProtection: true,
      //edge: 'ie1',
      codecPreferences: [Twilio.Call.Codec.Opus, Twilio.Call.Codec.PCMU],
    })

    instance.value.on('registered', onRegistered)
    instance.value.on('tokenWillExpire', onTokenWillExpire)
    instance.value.on('error', onError)
    instance.value.on('unregistered', onUnregistered)
    instance.value.register()

    initialized.value = true
  }

  async function refreshToken() {
    if (!instance.value) {
      throw new Error('Attempted to refresh device token without device.')
    }

    const token = instance.value.token
    if (!token || tokenExpired(token)) {
      const clientToken = await TwilioController.GetClientToken()
      instance.value.updateToken(clientToken)
    }
  }

  function tokenExpired(token: string): boolean {
    const decodedToken = JSON.parse(window.atob(token.split('.')[1]))
    const currentDate = Date.now() / 1000 + 15
    const result = decodedToken.exp < currentDate
    return result
  }

  return {
    init,
    initialized,
    refreshToken,
    instance: computed(() => {
      if (!instance.value) {
        throw new Error('Attempted to access device before init.')
      }
      return instance.value
    }),
  }
})
