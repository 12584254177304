import store from '@/store'
import ClientAuthPolicies from './models/auth/client-auth-policies'
import ContractorAuthPolicies from './models/auth/contractor-auth-policies'
import { RawLocation, Route } from 'vue-router'
import { useSessionStore } from './pinia/session'
import { useLaunchDarkly } from '@/plugins/launch-darkly'

export default class RouterAuth {
  // used by routing, if the route requires authentication
  public static async BeforeEach(to: Route, from: Route, next: (to?: RawLocation | false | void) => void) {
    try {
      const hasAccess = await store.Instance.dispatch('oidcCheckAccess', to)
      if (hasAccess) {
        this.AuthorizationCheck(to, next)
      } else {
        next(false)
      }
    } catch (err) {
      console.error(err)
      next(false)
    }
  }

  private static AuthorizationCheck(to: Route, next: (to?: RawLocation | false | void) => void) {
    if (useLaunchDarkly().variation('client-app-force-redirect')) {
      if (useSessionStore().session?.userType === 'ClientUserDetail') {
        next(false)
        window.location.href = store.Instance.state.Environment.ClientAppUrl
        return
      }
    }

    // check role based access
    if (to.meta && to.meta.allowedDetailRecordTypes) {
      if (to.meta.allowedDetailRecordTypes.indexOf(store.Instance.state.SessionDetail.detailRecordType) === -1) {
        // not found, user detail of this type not allowed access to this route
        next(false)
        return
      }
    }

    // Block all affinity partners.
    if (store.Instance.state.SessionDetail.isAffinityPartner) {
      next(false)
      return
    }

    if (to.meta?.requiredPermission && !useSessionStore().hasPermission(to.meta.requiredPermission)) {
      next(false)
      return
    }

    if (
      to.meta &&
      to.meta.allowedUserDetailRoleNames &&
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail'
    ) {
      // user detail type is user, check for matching roleName
      if (
        to.meta.allowedUserDetailRoleNames.indexOf(
          store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName
        ) === -1
      ) {
        // not found, user detail with this role not allowed access to this route
        next(false)
        return
      }
    }

    if (to.meta && to.meta.requiresDashboardAuth) {
      if (
        store.Instance.state.SessionDetail.detailRecordType !== 'UserDetail' ||
        !store.Instance.state.SessionDetail.detailRecord.UserDetail.dashboardUserRights ||
        !store.Instance.state.SessionDetail.detailRecord.UserDetail.dashboardUserRights.find((d) => d.name === to.name)
      ) {
        // user does not have rights to this dashboard, block
        next(false)
        return
      }
    }

    // if engineer is not allowed to access portal, redirect to default page
    if (
      to.meta &&
      to.meta.allowedUserDetailRoleNames &&
      ((store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail' &&
        (!store.Instance.state.SessionDetail.detailRecord.EngineerDetail.hasPortalAccess ||
          store.Instance.state.ContractorRegistrationStatus === 'Archived')) ||
        (store.Instance.state.SessionDetail.detailRecordType === 'ClientUserDetail' &&
          !store.Instance.state.SessionDetail.detailRecord.ClientUserDetail.hasPortalAccess))
    ) {
      next('/unauthorized')
      return
    }

    if (to.name === 'job') {
      const jobTypes = store.Instance.state.SessionDetail.detailRecord.UserDetail.jobTypes
      const jobType = to.params.jobId.toString().substr(0, 2)
      if (jobTypes && !jobTypes.includes(jobType !== 'US' && jobType !== 'SI' ? 'HE' : jobType)) {
        // prevent to access job
        next('/unauthorized')
        return
      }
    }

    // special condition check for home route
    if (to.name === 'home') {
      if (store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail') {
        if (
          (store.Instance.state.ContractorRegistrationStatus &&
            store.Instance.state.ContractorRegistrationStatus === 'ApplicationPending') ||
          store.Instance.state.ContractorRegistrationStatus === 'ApplicationInProgress'
        ) {
          next('/contractorRegistration')
        } else {
          next('/contractorManagement')
        }
        return
      }
      if (store.Instance.state.SessionDetail.detailRecordType === 'UserDetail') {
        const dhp = store.Instance.state.SessionDetail.detailRecord.UserDetail.defaultHomePage
        if (dhp) {
          next(`/${dhp}`)
          return
        }
      }
      if (store.Instance.state.SessionDetail.detailRecordType === 'ClientUserDetail') {
        next('/clientManagement')
        return
      }
      next()
      return
    }
    if (to.name === 'contractorRegistration') {
      if (store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail') {
        // next();
        if (
          (store.Instance.state.ContractorRegistrationStatus &&
            store.Instance.state.ContractorRegistrationStatus === 'ApplicationPending') ||
          store.Instance.state.ContractorRegistrationStatus === 'ApplicationInProgress'
        ) {
          next()
        } else {
          next('/contractorManagement')
        }
      }
      if (store.Instance.state.SessionDetail.detailRecordType === 'UserDetail') {
        next('/contractorManagement')
      }
      return
    }
    if (to.name === 'clientInvoiceDashboard') {
      if ((store.Instance.getters['authModule/clients'] as ClientAuthPolicies).readFinancial) {
        next()
      }
      return
    }
    if (to.name === 'contractorPODashboard') {
      if ((store.Instance.getters['authModule/contractors'] as ContractorAuthPolicies).readFinancial) {
        next()
      }
      return
    }
    next()
  }
}
