import { UserSession } from '@/models/session/session'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

export function startDatadog() {
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  const site = 'datadoghq.eu'
  const clientToken = process.env.VUE_APP_DatadogClientToken || ''
  const service = 'simplifi.callcentre.app'
  const env = process.env.VUE_APP_EnvironmentName || 'development'

  // Specify a version number to identify the deployed version of your application in Datadog
  const version = process.env.VUE_APP_DatadogCiBuildId

  datadogRum.init({
    applicationId: process.env.VUE_APP_DatadogApplicationId || '',
    clientToken,
    site,
    service,
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [process.env.VUE_APP_CallCentreApiBaseUrl || ''],
    enableExperimentalFeatures: ['feature_flags'],
  })

  datadogLogs.init({
    clientToken,
    site,
    service,
    env,
    version,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: 'all',
  })
}

export function getSessionId() {
  return datadogLogs.getInternalContext()?.session_id
}

export const logger = datadogLogs.logger

export function onSessionChanged(session: UserSession) {
  const context = {
    user: {
      id: session.userId || 'Unknown',
      name: session.userName,
      full_name: session.userName,
      email: session.email || '',
      roles: session.roles,
    },
    git: {
      repository_url: process.env.VUE_APP_RepositoryUrl,
      commit: {
        sha: process.env.VUE_APP_GitCommitSha,
      },
    },
    simplifi: {
      user: {
        type: session.userType,
        id: session.userId,
        contractor_id: session.contractorId,
        client_id: session.clientId,
        permissions: session.permissions,
        job_types: session.jobTypes,
      },
    },
  }
  datadogRum.setGlobalContext(context)
  datadogLogs.setGlobalContext(context)
}
