export enum Permissions {
  FixStuckTasks = 'CallCentre.FixStuckTasks',
}

export type Session = {
  userType: string
  userId: string
  userName: string
  email: string
  profilePictureUrl: string
  profilePictureThumbnailUrl: string
  claims: {
    JobType: string[] | undefined
    ClientId: string[] | undefined
    ContractorId: string[] | undefined
  }
  roles: string[]
  permissions: Permissions[]
  applications: Application[]
}

export type Application = {
  id: string
  friendlyName: string
  description: string
  applicationUrl: string | undefined
  logoUrl: string | undefined
}

export type UserSession = {
  userId: string
  userType: string
  userName: string
  email: string

  roles: string[]
  permissions: Permissions[]
  jobTypes: string[]

  clientId: string | null
  contractorId: string | null
  applications: Application[]
}
