import TimeLineCardModel from '@/models/TimeLineCardModel'
import Job from '@/models/Job'
import CallContractorModel from '@/models/claim/CallContractorModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import { JobVisitStatus } from '@/common/enums'
import store from '@/store'
import Emergency from '@/models/Emergency'
import moment from 'moment'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import ComplaintModel from '@/models/claim/ComplaintModel'
import SMSModel from '@/models/claim/SMSModel'
import JobNoteModel from '@/models/claim/JobNoteModel'
import TimeSlot from '@/models/claim/TimeSlot'
import { TimeLineHelper } from './TimeLineHelper'
import Shared from '@/common/shared'
import EmailModel from '@/models/claim/EmailModel'
import JobEscalationDetail from '@/models/claim/JobEscalationDetailModel'
import ClientInvoiceDetailModel from '@/models/claim/ClientInvoiceDetailModel'
import { useTimelineStore } from '@/pinia/timeline'
import { useLaunchDarkly } from '@/plugins/launch-darkly'
import { useDeploymentStore } from '@/pinia/deployment'
import { DeploymentStatus } from '@/models/autoDeployment/auto-deployment'

class HETimeLineHelper extends TimeLineHelper {
  public generateCards(job: Job): TimeLineCardModel[] {
    const cards: TimeLineCardModel[] = []

    // fnol card
    cards.push(
      new TimeLineCardModel({
        title: job.policyEnquiry ? 'Enquiry' : 'FNOL',
        colour: 'purple',
        icon: job.policyEnquiry ? 'info' : 'email',
        createdDate: job.createdAt,
        forJobId: job.id,
        cardBodyComponent: 'FNOLCard',
        cardFooterComponent: 'FNOLCardFooter',
        detailComponent: 'FNOLPreview',
      })
    )

    // Policy Wide Question Card
    if (job.helplinePolicy) {
      cards.push(
        new TimeLineCardModel({
          title: 'Policy Wide Questions',
          colour: 'yellow',
          icon: 'forum',
          createdDate: job.createdAt,
          forJobId: job.id,
          cardBodyComponent: 'PolicyWideQACard',
          detailComponent: 'PolicyWideQAPreview',
          cardClass: '',
        })
      )
    }

    // picture upload card
    const jobClaimPictures = Object.assign([], job.claimPictures)
    const sortedClaimPictures = jobClaimPictures ? Shared.sortArrayByDate(jobClaimPictures, 'createdAt') : []
    if (sortedClaimPictures && sortedClaimPictures.length > 0) {
      cards.push(this.bindPictureUploadCard(sortedClaimPictures))
    }

    // push in cards for emergencies (only if display in timeline true)
    job.emergencies
      .filter((e) => e.displayInTimeline)
      .forEach((emergency) => {
        if (
          emergency.thirdPartyContractor === undefined ||
          emergency.thirdPartyContractor === null ||
          emergency.thirdPartyContractor === ''
        ) {
          cards.push(
            new TimeLineCardModel({
              title: 'Emergency Questions',
              colour: 'yellow',
              icon: 'forum',
              createdDate: emergency.createdAt,
              forJobId: emergency.jobId,
              forEmergencyId: emergency.id,
              cardBodyComponent: 'EmergencyQACard',
              cardAdditionalChipsComponent: 'EmergencyQACardChip',
              detailComponent: 'EmergencyQAPreview',
              cardClass: '',
            })
          )
        }

        if (job) {
          const softFixedEmergency = job.softFixedEmergency.find((s) => s.emergencyId === emergency.id)
          if (softFixedEmergency) {
            cards.push(
              new TimeLineCardModel({
                title: 'Soft Fix',
                colour: 'purple',
                icon: 'check_circle',
                forEmergencyId: emergency.id,
                createdDate: softFixedEmergency.createdAt,
                forJobId: job.id,
                forItemId: softFixedEmergency.id,
                cardBodyComponent: 'SoftFixCard',
                detailComponent: 'SoftFixPreview',
                cardFooterComponent: 'SoftFixFooter',
              })
            )
          }
          if (
            emergency.isEmergencyAccepted ||
            (emergency.ctpCompletedForRejectedEmergency &&
              (emergency.thirdPartyContractor === undefined ||
                emergency.thirdPartyContractor === null ||
                emergency.thirdPartyContractor === ''))
          ) {
            const emergencyDate = new Date(emergency.createdAt.toString())
            const callContractor: CallContractorModel | undefined = job.callContractors.find(
              (a: CallContractorModel) => a.forEmergencyTypeId === emergency.typeId
            )
            // bind pending or cancelled trade appointed cards
            const appointedGeneralContractors: ContractorAppointedModel[] = job.contractorAppointed.filter(
              (c) => c.emergencyId === emergency.id && c.status !== JobVisitStatus[JobVisitStatus.Unavailable]
            )
            appointedGeneralContractors.forEach((appointedContractor) => {
              if (
                appointedContractor.status !== JobVisitStatus[JobVisitStatus.AutoDeploying] ||
                this.isLegacyAutoDeploymentEnabled
              )
                cards.push(
                  new TimeLineCardModel({
                    title: appointedContractor.companyName,
                    status: appointedContractor.status,
                    colour: appointedContractor.status === JobVisitStatus[JobVisitStatus.Cancelled] ? 'red' : 'green',
                    icon: appointedContractor.status === JobVisitStatus[JobVisitStatus.Cancelled] ? 'block' : 'check',
                    createdDate: appointedContractor.createdAt,
                    forJobId: appointedContractor.jobId,
                    forItemId: appointedContractor.id,
                    forEmergencyId: appointedContractor.emergencyId,
                    cardBodyComponent: 'TradeAppointedCard',
                    detailComponent: 'TradeAppointedPreview',
                    cardClass: '',
                  })
                )
            })
            const sortedContractors = Shared.sortArrayByDate(appointedGeneralContractors, 'createdAt')
            // only allow skilled agents to deploy
            const showCallContractor: boolean =
              store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
              store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.deployment > 0 &&
              store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.deployment >= job.complexity &&
              !emergency.autoDeploymentRunning

            // get customerToPay Cards for Emergency
            const customerToPayCards = job.customerToPay.filter((c) => c.emergencyId === emergency.id)
            const sortedCustomerToPayCards = Shared.sortArrayByDate(customerToPayCards, 'createdAt')
            // if job status is 'Cancelled' or Cancellation reason is added or customer availability not set, CallContractor card should not be added
            if (
              !appointedGeneralContractors.find(
                (c) =>
                  c.status === JobVisitStatus[JobVisitStatus.Pending] ||
                  c.status === JobVisitStatus[JobVisitStatus.Unavailable] ||
                  c.status === JobVisitStatus[JobVisitStatus.InProgress] ||
                  c.status === JobVisitStatus[JobVisitStatus.Completed] ||
                  c.status === JobVisitStatus[JobVisitStatus.Accepted] ||
                  c.status === JobVisitStatus[JobVisitStatus.AutoDeploying]
              ) &&
              showCallContractor &&
              !(job.status === 'Cancelled' || job.cancellationReason) &&
              !emergency.isEmergencyCompleted &&
              job.customerAvailability &&
              job.customerAvailability.timeSlot &&
              job.customerAvailability.timeSlot.length > 0
            ) {
              cards.push(
                new TimeLineCardModel({
                  title: '',
                  colour: 'yellow',
                  icon: 'call',
                  createdDate:
                    (emergency.isEmergencyAccepted || emergency.ctpCompletedForRejectedEmergency) &&
                    sortedCustomerToPayCards.length > 0
                      ? moment(
                          new Date(
                            sortedCustomerToPayCards[sortedCustomerToPayCards.length - 1].createdAt.toString()
                          ).setSeconds(
                            new Date(
                              sortedCustomerToPayCards[sortedCustomerToPayCards.length - 1].createdAt.toString()
                            ).getSeconds() + 5
                          )
                        )
                      : callContractor
                      ? callContractor.createdAt
                      : sortedContractors && sortedContractors.length > 0
                      ? moment(
                          new Date(sortedContractors[sortedContractors.length - 1].createdAt.toString()).setSeconds(
                            new Date(
                              sortedContractors[sortedContractors.length - 1].createdAt.toString()
                            ).getSeconds() + 5
                          )
                        )
                      : moment(emergencyDate.setSeconds(emergencyDate.getSeconds() + 5)),
                  forJobId: callContractor ? callContractor.jobId : job.id,
                  forItemId: callContractor ? callContractor.id : '',
                  forEmergencyId: emergency.id,
                  cardBodyComponent: 'CallContractorCard',
                  detailComponent: 'CallContractorPreview',
                  cardClass: 'flatCard',
                })
              )
            }

            // bind UnavailableContractors
            const unavailableContractors: ContractorAppointedModel[] | undefined = job.contractorAppointed.filter(
              (c) => c.emergencyId === emergency.id && c.status === JobVisitStatus[JobVisitStatus.Unavailable]
            )
            const sortedUnavailableContractors = Shared.sortArrayByDate(unavailableContractors, 'createdAt')
            if (unavailableContractors && unavailableContractors.length > 0) {
              cards.push(this.bindFailedToContactCallCard(emergency, sortedUnavailableContractors))
            }
          }

          // third Party Contractor Card
          if (
            emergency.thirdPartyContractor !== undefined &&
            emergency.thirdPartyContractor !== null &&
            emergency.thirdPartyContractor !== ''
          ) {
            const emergencyDate = new Date(emergency.createdAt.toString())
            if (job.thirdPartyAppointedContractor) {
              if (
                job.thirdPartyAppointedContractor.status !== JobVisitStatus[JobVisitStatus.AutoDeploying] ||
                this.isLegacyAutoDeploymentEnabled
              )
                cards.push(
                  new TimeLineCardModel({
                    title: job.thirdPartyAppointedContractor.companyName,
                    status: job.thirdPartyAppointedContractor.status,
                    colour: 'green',
                    icon: 'check',
                    createdDate: job.thirdPartyAppointedContractor.createdAt,
                    forJobId: job.thirdPartyAppointedContractor.jobId,
                    forItemId: job.thirdPartyAppointedContractor.id,
                    forEmergencyId: job.thirdPartyAppointedContractor.emergencyId,
                    cardBodyComponent: 'TradeAppointedCard',
                    detailComponent: 'TradeAppointedPreview',
                    cardClass: '',
                  })
                )
            } else {
              cards.push(
                new TimeLineCardModel({
                  title: '',
                  colour: 'yellow',
                  icon: 'call',
                  createdDate: job.createdAt,
                  forJobId: job.id,
                  forItemId: '',
                  forEmergencyId: emergency.id,
                  cardBodyComponent: 'CallContractorCard',
                  detailComponent: 'CallContractorPreview',
                  cardClass: 'flatCard',
                })
              )
            }
          }

          // bind Engineer Visit Detail
          if (job.engineerVisitDetail.length > 0) {
            job.engineerVisitDetail.forEach((element: EngineerVisitDetail) => {
              if (
                element.forEmergencyTypeId === emergency.typeId &&
                element.forEmergencyDetailId === emergency.detailId
              ) {
                cards.push(this.bindEngineerVisitCard(element, emergency, job.jobType))
              }
            })
          }

          // Customer To Pay Card
          if (job.customerToPay && job.customerToPay.length > 0) {
            job.customerToPay.forEach((element: CustomerToPayModel) => {
              if (element.emergencyId === emergency.id) {
                cards.push(this.bindCustomerToPayCard(job.jobId, element))
              }
            })
          }

          // bind customer appointed tradespeople card
          const customerAppointedTrade = job.customerAppointedTradespeople.find((s) => s.emergencyId === emergency.id)
          if (customerAppointedTrade) {
            cards.push(
              new TimeLineCardModel({
                title: 'Customer Appointed Tradespeople',
                colour: customerAppointedTrade.invoiceImageUrl ? 'green' : 'red',
                icon: 'check',
                createdDate: customerAppointedTrade.createdAt ? customerAppointedTrade.createdAt : moment(new Date()),
                forJobId: job.jobId,
                forItemId: customerAppointedTrade.id ? customerAppointedTrade.id : '',
                forEmergencyId: customerAppointedTrade.emergencyId,
                cardBodyComponent: 'CustomerAppointedTradespeopleCard',
                detailComponent: 'CustomerAppointedTradespeoplePreview',
                cardFooterComponent: '',
              })
            )
          }
        }
      })

    // Any one of the emergency is accepted then vulnerability and availability card should be push
    if (job && job.emergencies.length > 0 && job.emergencies.find((c) => c.isEmergencyAccepted === true)) {
      // VulnerabilityQA Card
      if (job.vulnerabilityQA) {
        cards.push(
          new TimeLineCardModel({
            title: 'Vulnerability Question',
            colour: 'yellow',
            icon: 'forum',
            createdDate: job.vulnerabilityQA.createdAt,
            forJobId: job.vulnerabilityQA.jobId,
            forItemId: job.vulnerabilityQA.id,
            cardBodyComponent: 'VulnerabilityQACard',
            detailComponent: 'VulnerabilityQAPreview',
            cardFooterComponent: '',
          })
        )
      }
    }

    if (
      job &&
      job.emergencies.length > 0 &&
      !job.thirdPartyAppointedContractor &&
      job.emergencies.find((c) => c.isEmergencyAccepted || c.ctpCompletedForRejectedEmergency)
    ) {
      const emergencyId = job.emergencies[0].id
      const customerToPayCard = job.customerToPay.find((c) => c.emergencyId === emergencyId)
      // set created date based on conditions
      let availabilityCardCreatedDate: moment.Moment | null = null
      if (job.customerAvailability) {
        if (job.customerAvailability.timeSlot && job.customerAvailability.timeSlot.length > 0) {
          availabilityCardCreatedDate = job.customerAvailability.createdAt
        } else if (customerToPayCard) {
          availabilityCardCreatedDate = moment(
            new Date(customerToPayCard.createdAt.toString()).setSeconds(
              new Date(customerToPayCard.createdAt.toString()).getSeconds() + 3
            )
          )
        } else {
          availabilityCardCreatedDate = moment(
            new Date(job.customerAvailability.createdAt.toString()).setSeconds(
              new Date(job.customerAvailability.createdAt.toString()).getSeconds() + 3
            )
          )
        }
      }
      cards.push(
        new TimeLineCardModel({
          title: 'Customer Availability',
          colour: 'yellow',
          icon: 'person',
          createdDate: availabilityCardCreatedDate ? availabilityCardCreatedDate : moment(new Date()),
          forJobId: job.customerAvailability ? job.customerAvailability.jobId : job.id,
          forItemId: job.customerAvailability ? job.customerAvailability.id : '',
          cardBodyComponent: 'CustomerAvailabilityCard',
          detailComponent: 'CustomerAvailabilityPreview',
          cardFooterComponent: '',
        })
      )
    }

    // Job Complete Card
    if ((job.status === 'Completed' || job.status === 'Cancelled') && !job.helplinePolicy) {
      cards.push(this.bindJobCompleteCard(job))
      // Job Financial Summary Card
      cards.push(this.bindJobFinancialSummaryCard(job))
    }

    // Financial Summary Card
    if (job.financialSummary) {
      cards.push(
        new TimeLineCardModel({
          title: 'Financial Summary',
          colour: 'yellow',
          icon: 'insert_drive_file',
          createdDate: job.financialSummary.createdAt,
          forJobId: job.financialSummary.jobId,
          forItemId: job.financialSummary.id,
          cardBodyComponent: 'FinancialSummaryCard',
          detailComponent: 'FinancialSummaryPreview',
          cardFooterComponent: '',
        })
      )
    }

    // Complaint Card
    if (job.complaint && job.complaint.length > 0) {
      job.complaint.forEach((complaint: ComplaintModel) => {
        cards.push(this.bindComplaintCard(job, complaint))
      })
    }

    // SMS Card
    if (job.SmsTexts && job.SmsTexts.length > 0) {
      job.SmsTexts.forEach((sms: SMSModel) => {
        cards.push(
          new TimeLineCardModel({
            title: sms.direction === 'outbound-api' ? 'Outgoing SMS' : 'Incoming SMS',
            colour: 'yellow',
            icon: 'message',
            createdDate: sms.createdAt,
            forJobId: sms.jobId,
            forItemId: sms.id,
            cardBodyComponent: 'SMSCard',
            detailComponent: 'SMSPreview',
            cardFooterComponent: '',
          })
        )
      })
    }

    // Email Card
    if (job.Emails && job.Emails.length > 0) {
      job.Emails.forEach((email: EmailModel) => {
        cards.push(this.addEmailCardToList(email))
      })
    }

    // Job Note Card
    if (job.jobNotes && job.jobNotes.length > 0) {
      job.jobNotes.forEach((note: JobNoteModel) => {
        cards.push(this.bindJobNoteCard(job, note))
      })
    }

    // add card to allow user to complete the job
    if (this.showCompleteJobConfirmationCard(job) && !job.helplinePolicy) {
      cards.push(this.bindJobCompleteConfirmationCard(job))
    }

    // Other Fees Detail Card
    if (job && job.clientInvoiceDetails && job.clientInvoiceDetails.length > 0) {
      job.clientInvoiceDetails.forEach((clientInvoiceDetail: ClientInvoiceDetailModel) => {
        if (clientInvoiceDetail.otherJobFeeDetail) {
          cards.push(this.bindOtherFeesDetailCard(job, clientInvoiceDetail))
        }
      })
    }

    // Reopen Job Card
    if (job && job.status === 'Completed' && !job.helplinePolicy) {
      const jobCompletionDate = job.jobCompletedAtUtc ? new Date(job.jobCompletedAtUtc.toString()) : new Date()
      cards.push(
        new TimeLineCardModel({
          title: '',
          colour: 'green',
          icon: 'work',
          createdDate: moment(jobCompletionDate.setSeconds(jobCompletionDate.getSeconds() + 10)),
          forJobId: job.id,
          forItemId: '',
          forEmergencyId: '',
          cardBodyComponent: 'JobReopenCard',
          detailComponent: 'JobReopenPreview',
          cardClass: 'flatCard',
        })
      )
    }

    // Escalation Card
    if (job.jobEscalationDetails && job.jobEscalationDetails.length > 0) {
      job.jobEscalationDetails.forEach((element: JobEscalationDetail) => {
        cards.push(this.bindEscalationCard(element))
      })
    }

    // Add the non legacy timeline cards which are data driven.
    useTimelineStore().cards.forEach((x) => {
      const addCardFn = this.timelineCardEvents[x.type]
      if (addCardFn) {
        const card = addCardFn(x.id)
        if (card) cards.push(card)
      }
    })
    return cards
  }

  public timelineCardEvents: { [key: string]: (cardId: string) => TimeLineCardModel | null } = {
    ClientClaimSubmitted: this.addClientClaimSubmittedCard,
    AutoDeploymentCreated: this.addAutoDeploymentCard,
  }

  public addClientClaimSubmittedCard(cardId: string): TimeLineCardModel | null {
    const newCard = useTimelineStore().cards.find((x) => x.type === 'ClientClaimSubmitted' && x.id === cardId)
    if (!newCard) {
      return null
    }

    return new TimeLineCardModel({
      title: newCard.title,
      colour: 'green',
      icon: 'message',
      createdDate: moment(newCard.createdAt),
      forJobId: newCard.jobId,
      forItemId: newCard.id,
      cardBodyComponent: `ClientClaimSubmittedCard`,
      detailComponent: `ClientClaimSubmittedPreview`,
      cardClass: '',
      cardFooterComponent: undefined,
    })
  }

  public addAutoDeploymentCard(cardId: string): TimeLineCardModel | null {
    const newCard = useTimelineStore().cards.find((x) => x.type === 'AutoDeploymentCreated' && x.id === cardId)
    if (!newCard) {
      return null
    }

    return new TimeLineCardModel({
      title: newCard.title,
      colour: 'green',
      icon: 'message',
      createdDate: moment(newCard.createdAt),
      forJobId: newCard.jobId,
      forItemId: newCard.id,
      cardBodyComponent: `AutoDeploymentCard`,
      detailComponent: `AutoDeploymentCardPreview`,
      cardClass: '',
      cardFooterComponent: undefined,
      forEmergencyId: newCard.data['emergencyId'],
    })
  }

  public displaySelectedEmergencyCard(job: Job, card: any): boolean {
    let result = false
    if (card.cardBodyComponent === 'FNOLCard') {
      result = true
    } else {
      if (card.cardBodyComponent === 'CallContractorCard') {
        result = this.showCallContractorCard(job, card)
      } else if (card.cardBodyComponent === 'CustomerAvailabilityCard') {
        result = this.showCustomerAvailabilityCard(job)
      } else if (card.cardBodyComponent === 'JobCompleteConfirmationCard') {
        result = this.showCompleteJobConfirmationCard(job)
      } else {
        result = true
      }
    }
    return result
  }

  // show complete job confirmation card if last remaining open visit on a job is closed
  public showCompleteJobConfirmationCard(job: Job): boolean {
    if (!job) {
      return false
    }

    if (job.status === 'Completed' || (job.status === 'Cancelled' && job.cancellationReason)) {
      return false
    }

    return true
  }

  public showCustomerAvailabilityCard(job: Job): boolean {
    let emergencies: Emergency[] = []
    if (job && job.emergencies) {
      emergencies = job.emergencies.filter(
        (e) => e.thirdPartyContractor === '' || e.thirdPartyContractor === null || e.thirdPartyContractor === undefined
      )
    }
    const getAcceptedEmergencies = emergencies.find((c) => c.isEmergencyAccepted === true)
    if (getAcceptedEmergencies && job && job.customerAvailability) {
      return true
    }
    const getRejectedEmergencies = emergencies.find((c) => c.ctpCompletedForRejectedEmergency)
    if (getRejectedEmergencies && job && job.customerAvailability) {
      return true
    }
    return false
  }

  public bindOtherFeesDetailCard(job: Job, clientInvoiceDetail: ClientInvoiceDetailModel | null = null) {
    return new TimeLineCardModel({
      title: 'Other Fees',
      colour: 'yellow',
      icon: 'post_add',
      createdDate:
        clientInvoiceDetail &&
        clientInvoiceDetail.otherJobFeeDetail &&
        clientInvoiceDetail.otherJobFeeDetail.otherFeesDetailCreatedAt
          ? moment(clientInvoiceDetail.otherJobFeeDetail.otherFeesDetailCreatedAt)
          : moment(new Date()),
      forJobId: job.id,
      forItemId: clientInvoiceDetail && clientInvoiceDetail.otherJobFeeDetail ? clientInvoiceDetail.id : '',
      forEmergencyId: '',
      cardBodyComponent: 'OtherFeesDetailCard',
      detailComponent: 'OtherFeesDetailPreview',
      cardFooterComponent: '',
    })
  }

  public bindEscalationCard(element: JobEscalationDetail) {
    return new TimeLineCardModel({
      title: 'Escalation Details',
      colour: element.deEscalationDescription ? 'blue' : 'red',
      icon: 'forward',
      createdDate: element.createdAt ? moment(element.createdAt) : moment(new Date()),
      forJobId: element.jobId,
      forItemId: element.id,
      forEmergencyId: '',
      cardBodyComponent: 'JobEscalationCard',
      detailComponent: 'JobEscalationPreview',
      cardFooterComponent: '',
    })
  }

  // show call contractor card if any availability is set
  public showCallContractorCard(job: Job, card: any): boolean {
    if (job && (job.cancellationReason || job.status === 'Cancelled' || job.status === 'Completed')) {
      return false
    }
    if (this.isCATAssignedForEmergency(job, card.forEmergencyId)) {
      return false
    }
    let emergenciesWithThirdPatyContractors: Emergency[] = []
    if (job && job.emergencies) {
      emergenciesWithThirdPatyContractors = job.emergencies.filter(
        (e) => e.thirdPartyContractor !== '' && e.thirdPartyContractor !== null && e.thirdPartyContractor !== undefined
      )
    }
    const emergency: Emergency | undefined = emergenciesWithThirdPatyContractors.find(
      (e) => e.id === card.forEmergencyId
    )
    let isAutoDeploymentRunning = false
    if (job && job.emergencies) {
      const ldClient = useLaunchDarkly()
      const deploymentStore = useDeploymentStore()
      isAutoDeploymentRunning = ldClient?.variation('fnol-401-multiple-contractor-notifications')
        ? deploymentStore.autoDeployment.emergencyId === card.forEmergencyId &&
          deploymentStore.autoDeployment.status === DeploymentStatus.InProgress
        : job.emergencies.some((x) => x.id === card.forEmergencyId && x.autoDeploymentRunning)
    }
    if (emergency !== undefined) {
      return true
    } else if (isAutoDeploymentRunning) {
      return false
    } else {
      const timeslots: TimeSlot[] = job && job.customerAvailability ? job.customerAvailability.timeSlot : []
      if (timeslots && timeslots.length > 0) {
        const todayDate: Date = new Date()
        const timeSlot: any = timeslots.find((e: TimeSlot) =>
          new Date(e.startTime) >= todayDate ? true : new Date(e.endTime) >= todayDate
        )
        if (timeSlot) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }

  private isCATAssignedForEmergency(job: Job, emergencyId: string) {
    const index = job.customerAppointedTradespeople.findIndex((s) => s.emergencyId === emergencyId)
    return index !== -1 ? true : false
  }

  private get isLegacyAutoDeploymentEnabled(): boolean {
    const ldClient = useLaunchDarkly()
    return ldClient !== undefined ? !ldClient.variation('fnol-401-multiple-contractor-notifications') : false
  }
}

const heTimeLineHelper: HETimeLineHelper = new HETimeLineHelper()
export default heTimeLineHelper
