<template>
  <div>
    <!-- Upload Document - button -->
    <v-flex xs12 text-xs-right mb-2 mr-0>
      <v-btn color="primary" class="mr-0 upload-btn" @click="openAddDocumentDialog">Upload Document</v-btn>
    </v-flex>
    <!-- Upload Document - data-table -->
    <v-flex xs12 grid-view upload-document-section>
      <v-data-table
        :headers="headerColumns"
        :items="filteredDocumentList"
        hide-actions
        item-key="id"
        :loading="loading"
        class="elevation-1 uploadedDocument"
      >
        <template slot="items" slot-scope="props">
          <tr :key="itemKey(props.item)" class="sortableRow">
            <td>{{ props.item.documentName }}</td>
            <td>
              <v-select
                v-if="index === props.item.documentUrl"
                v-model="props.item.documentTypeId"
                :items="getDocumentTypeItems"
                label="Document Type"
                item-text="description"
                item-value="id"
                data-vv-scope="formReference"
                single-line
                hide-details
                class="document-list"
                @change="errorMessage = ''"
              >
                <template slot="item" slot-scope="data">
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title
                        :class="data.item.isDeleted ? 'deleted-item' : ''"
                        v-html="data.item.description"
                      ></v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
              </v-select>
              <span v-else>{{ props.item.documentType }}</span>
              <div v-if="index === props.item.documentUrl" class="input-group__details">
                <div class="input-group__messages input-group__error input-group--error error--text">
                  <span v-if="typeValidationMessage(errorMessage)">
                    <span>{{ errorMessage }}</span>
                  </span>
                </div>
              </div>
            </td>
            <td>{{ props.item.uploadedByUserName }}</td>
            <td>{{ getFormattedDate(props.item.uploadedAt) }}</td>
            <td class="text-xs-right action-td-col">
              <!-- Available For Engineer -->
              <!-- if in edit mode show checkbox, else show icon to indicate document is available For Engineer -->
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-checkbox
                    v-if="index === props.item.documentUrl"
                    v-model="props.item.availableForEngineer"
                    primary
                    hide-details
                    color="primary"
                    class="d-inline-block engineer-checkbox"
                    v-on="on"
                  ></v-checkbox>
                  <span v-else v-show="props.item.availableForEngineer" class="engineer-icon primary--text" v-on="on">
                    <svg
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 535.806 535.807"
                      style="enable-background: new 0 0 535.806 535.807"
                      xml:space="preserve"
                      :fill="getEngineerIconColor"
                    >
                      <g>
                        <path
                          d="M440.956,373.932c-11.934-13.158-26.315-19.584-44.676-19.584h-38.686l-25.398-24.479   c-18.666,15.3-41.31,24.174-65.791,24.174c-22.95,0-44.676-7.956-62.424-21.726l-22.645,21.726h-40.262   c-20.502,0-36.414,7.038-48.96,21.421c-36.414,42.227-30.294,132.498-27.54,160.344h407.592   C474.31,507.654,477.982,415.242,440.956,373.932z"
                        />
                        <path
                          d="M160.343,182.376c-7.344,6.12-12.24,15.912-12.24,27.234c0,16.83,11.016,30.6,25.092,33.048   c3.06,25.398,13.464,47.736,29.07,64.26c3.365,3.366,6.731,6.732,10.403,9.486c4.591,3.672,9.486,6.732,14.688,9.486   c11.628,6.119,24.479,9.485,38.25,9.485c13.77,0,26.623-3.366,38.25-9.485c5.202-2.754,10.098-5.814,14.688-9.486   c3.673-2.754,7.038-6.12,10.404-9.486c15.3-16.523,26.01-38.556,28.764-63.954c0.307,0,0.612,0,0.918,0   c16.219,0,29.07-14.994,29.07-33.354c0-11.322-4.896-21.114-12.24-27.234H160.343L160.343,182.376z"
                        />
                        <path
                          d="M377.409,118.116c-9.486-31.518-34.578-63.648-66.402-80.172v71.91v9.792c0,0.612,0,0.918,0,1.224   c-0.306,3.366-0.918,6.426-2.447,9.486c-3.979,7.65-11.935,13.158-21.114,13.158h-4.896h-33.66c-8.568,0-16.219-4.59-20.196-11.322   c-1.836-2.754-2.754-5.813-3.366-9.18c-0.306-1.224-0.306-2.142-0.306-3.366v-8.568v-73.44   c-31.824,16.83-56.916,48.96-66.402,80.478l-2.142,6.732h-17.442v38.25h19.278h26.928h11.322h147.493h11.016h41.7v-1.836v-36.414   h-17.22L377.409,118.116z"
                        />
                        <path
                          d="M248.777,134.028h38.25c5.508,0,10.098-3.06,12.546-7.65c1.224-2.142,1.836-4.284,1.836-6.732v-2.754V105.57V33.354V22.95   v-3.978c0-2.448-0.612-4.59-1.224-6.732C297.432,5.202,290.394,0,282.438,0h-33.661c-7.344,0-13.464,5.508-14.076,12.546   c0,0.612-0.306,1.224-0.306,1.836v8.568v10.404v73.44v11.628v1.224c0,3.06,0.918,5.814,2.448,8.262   C239.598,131.58,243.881,134.028,248.777,134.028z"
                        />
                      </g>
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                    </svg>
                  </span>
                </template>
                <span>Available For Engineer</span>
              </v-tooltip>

              <!-- If edit mode allow to add note, else show Note on hover  -->
              <span v-if="index === props.item.documentUrl" class="mr-1">
                <v-menu left :close-on-content-click="false" :nudge-width="350" offset-x>
                  <template #activator="{ on }">
                    <v-btn
                      small
                      icon
                      :flat="props.item.note ? false : true"
                      color="primary"
                      class="ma-0 add-note"
                      v-on="on"
                    >
                      <v-icon :color="!props.item.note ? 'primary' : ''">notes</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="px-2 pb-2">
                    <v-textarea
                      v-model.trim="props.item.note"
                      label="Note"
                      hide-details
                      class="document-note"
                    ></v-textarea>
                  </v-card>
                </v-menu>
              </span>
              <v-tooltip
                v-else
                v-show="props.item.note"
                left
                class="hover-menu-content"
                content-class="notes-tooltip-content"
              >
                <template #activator="{ on }">
                  <v-btn small icon class="ma-0 show-note-btn" v-on="on" @click="show = true">
                    <v-icon color="primary">notes</v-icon>
                  </v-btn>
                </template>
                <div class="yellow lighten-4 hover-menu-item text-xs-left">
                  <h4 class="m1-2">Notes</h4>
                  <p>{{ props.item.note }}</p>
                </div>
              </v-tooltip>
              <!-- If in edit mode show Save, Cancel button -->
              <!-- else show button, by clicking that button, Download, Delete, Edit button will visible -->
              <span v-if="index === props.item.documentUrl">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      flat
                      color="primary"
                      class="ma-0 save-btn"
                      v-on="on"
                      @click="onUpdateDocumentMetadata(props.item)"
                    >
                      <v-icon class="md-icon">save</v-icon>
                    </v-btn>
                  </template>
                  <span>Save</span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      flat
                      color="primary"
                      class="ma-0 cancel-btn"
                      v-on="on"
                      @click="onCancelEditDocumentDetail(props.item)"
                    >
                      <v-icon class="md-icon">close</v-icon>
                    </v-btn>
                  </template>
                  <span>Cancel</span>
                </v-tooltip>
              </span>
              <v-menu v-else offset-y left class="menu-edit">
                <template #activator="{ on }">
                  <v-btn icon flat color="primary" class="ma-0 action-btn" v-on="on">
                    <v-icon :color="props.item.documentTypeId === typeOfSIReport ? 'secondary' : 'primary'">
                      more_vert
                    </v-icon>
                  </v-btn>
                </template>
                <div class="pa-2 white">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        flat
                        color="primary"
                        class="ma-0 download-btn"
                        v-on="on"
                        @click="downloadDocument(props.item.documentUrl)"
                      >
                        <v-icon
                          :color="props.item.documentTypeId === typeOfSIReport ? 'secondary' : 'primary'"
                          class="md-icon"
                        >
                          get_app
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        flat
                        color="primary"
                        class="ma-0 delete-btn"
                        v-on="on"
                        @click="deleteDocument(props.item)"
                      >
                        <v-icon
                          :color="props.item.documentTypeId === typeOfSIReport ? 'secondary' : 'primary'"
                          class="md-icon"
                        >
                          delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                  <v-tooltip v-if="props.item.documentTypeId !== typeOfSIReport" top>
                    <template #activator="{ on }">
                      <v-btn
                        icon
                        flat
                        color="primary"
                        class="ma-0 edit-btn"
                        v-on="on"
                        @click.native="onEditDocumentDetail(props.item)"
                      >
                        <v-icon class="md-icon">edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </div>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
    <!-- Upload Document - dialog -->
    <v-dialog
      v-if="showAddDocumentDialog"
      v-model="showAddDocumentDialog"
      content-class="wizard-dialog v-dialog--scrollable"
      max-width="800"
      persistent
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Upload Documents</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="close-btn" @click="closeAddDocumentDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />

        <v-card-text class="add-dialog-content scroll-content-dialog pa-0">
          <v-layout wrap>
            <v-flex xs12 px-2 py-1>
              <UploadDocument
                ref="uploadDocument"
                :job-id="jobId"
                :document-types="getDocumentTypes"
                :is-any-documents-uploaded.sync="isAnyDocumentsUploaded"
                :is-loading.sync="isDocumentUploadInProgress"
                @documentUploadComplete="onDocumentUploadComplete"
              ></UploadDocument>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="text-xs-right pa-3">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isDocumentUploadInProgress || !isAnyDocumentsUploaded"
            :loading="isDocumentUploadInProgress"
            color="primary"
            class="mr-0 upload-document-btn"
            @click="uploadDocumentClicked"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
      {{ saveSnackbarText }}
      <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { JobType } from '@/models/types'
import UploadedDocumentTypeModel from '@/models/UploadedDocumentTypeModel'
import UploadedJobDocumentDetail from '@/models/UploadedJobDocumentDetailModel'
import moment from 'moment'
import Shared from '@/common/shared'
import Store from '@/store'
import JobController from '@/api/jobController'
import DocumentController from '@/api/documentController'
import UploadDocument from '@/components/UploadDocument.vue'
import eventBus from '@/common/bus'
import { GetTheme } from '@/common/themes'
import Sortable from 'sortablejs'
import ReportMetadata from '@/models/ReportMetadata'
import { UploadedDocumentTypeEnum } from '@/common/enums'
import storeGetters from '@/storeGetters'
import Job from '@/models/Job'
import { BlobServiceClient } from '@azure/storage-blob'
import { logger } from '@/plugins/datadog'

@Component({
  components: { UploadDocument },
})
export default class DocumentList extends Vue {
  @Prop() private jobId: string
  @Prop({ default: () => [] })
  private excludedDocumentTypes: UploadedDocumentTypeEnum[]

  private Sortable = require('sortablejs')
  private jobCardHeaders: any = [
    {
      text: 'Name',
      value: 'documentName',
      sortable: false,
      isAction: false,
    },
    {
      text: 'Type',
      value: 'documentType',
      sortable: false,
      isAction: false,
    },
    {
      text: 'Uploaded By',
      value: 'uploadedByUserName',
      sortable: false,
      isAction: false,
    },
    {
      text: 'Uploaded At',
      value: 'uploadedAt',
      sortable: false,
      isAction: false,
    },
    {
      text: '',
      value: '',
      align: 'right',
      class: 'action-col',
      sortable: false,
      isAction: false,
    },
  ]
  private showAddDocumentDialog = false
  private uploadedDocumentTypes: UploadedDocumentTypeModel[] = []
  private uploadedDocuments: UploadedJobDocumentDetail[] = []
  private tempUploadedDocuments: UploadedJobDocumentDetail[] = []
  private getAllUploadedJobDocumentRunning = false
  private index: any = null
  private previousItem: UploadedJobDocumentDetail = new UploadedJobDocumentDetail()
  private saveSnackbar = false
  private saveSnackbarTimeout = 3000
  private saveSnackbarText = ''
  private loading = false
  private buildReportLoader = false
  private showEditLoader = false
  private itemKeys = new WeakMap()
  private currentItemKey = 0
  private isAnyDocumentsUploaded = false
  private isDocumentUploadInProgress = false
  private errorMessage = ''

  private typeOfSIReport: number = UploadedDocumentTypeEnum.SIReport.valueOf()

  private get jobType(): JobType {
    const job: Job | null = storeGetters.getJob(this.jobId)
    if (job) {
      return job.jobType
    }
    return 'US'
  }

  private async created() {
    // get list of upload type
    this.loading = true
    await this.getUploadedDocumentTypes(this.jobId)
    this.getAllUploadedJobDocument()
  }

  private async mounted() {
    eventBus.$on('documentUploaded', (hasDocumentUploaded: boolean) => {
      if (hasDocumentUploaded && this.uploadedDocuments.length < 1) {
        this.getAllUploadedJobDocument()
      }
    })
    this.loading = false
  }

  private get headerColumns(): any[] {
    return this.jobCardHeaders.filter((x) => !x.isAction)
  }

  private get filteredDocumentList() {
    return this.uploadedDocuments.filter((d) => !this.excludedDocumentTypes.includes(d.documentTypeId))
  }

  private uploadDocumentClicked(): void {
    const uploadDocument = this.$refs.uploadDocument as UploadDocument
    if (uploadDocument) {
      uploadDocument.uploadDocuments()
    }
  }

  private async getUploadedDocumentTypes(newJobId: string) {
    this.uploadedDocumentTypes = []
    const res = await DocumentController.GetUploadedDocumentTypes(this.jobType)
    this.uploadedDocumentTypes = res.filter(
      (t) => t.id !== UploadedDocumentTypeEnum.SIReport && t.id !== UploadedDocumentTypeEnum.ReportCover
    )

    this.uploadedDocumentTypes.unshift(
      Object.assign(new UploadedDocumentTypeModel(), {
        id: UploadedDocumentTypeEnum.Other.valueOf(),
        description: 'Other',
        jobType: this.jobType,
      })
    )
  }

  private openAddDocumentDialog() {
    this.showAddDocumentDialog = true
  }

  private closeAddDocumentDialog() {
    // empty array of newly added documents
    this.showAddDocumentDialog = false
  }

  private getFormattedDate(date: string): string {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private downloadDocument(url: string) {
    // get the URL of the document and allow download or open in new tab
    window.open(url, '_blank')
  }

  private deleteDocument(document: UploadedJobDocumentDetail) {
    Shared.confirmationPopup.open(
      'Are you sure you want to delete this document?',
      '',
      '',
      'No',
      'Yes',
      this,
      'DeleteDocumentFromJob',
      document
    )
  }

  private DeleteDocumentFromJob(document: UploadedJobDocumentDetail): void {
    this.loading = true
    DocumentController.DeleteUploadedJobDocument(
      this.jobId,
      document.documentName,
      document.documentUrl,
      document.documentType,
      document.documentTypeId
    )
      .then((res: boolean) => {
        if (res) {
          const index = this.uploadedDocuments.indexOf(document)
          if (index !== -1) {
            this.uploadedDocuments.splice(index, 1)
          }
          this.saveSnackbarText = 'Document deleted successfully.'
          this.saveSnackbar = true
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        eventBus.$emit('errorHandler', 'Error deleting document, please try again later.', true)
      })
  }

  private getAllUploadedJobDocument() {
    if (this.getAllUploadedJobDocumentRunning) {
      return
    }

    this.getAllUploadedJobDocumentRunning = true
    this.loading = true

    DocumentController.GetAllUploadedJobDocument(this.jobId)
      .then((res: UploadedJobDocumentDetail[]) => {
        this.loading = false
        if (res) {
          this.uploadedDocuments = []
          this.uploadedDocuments = res
          this.uploadedDocuments.sort((a, b) => Number(a.order) - Number(b.order))
          let i = 0
          this.uploadedDocuments.forEach((x) => {
            if (x.order === '0') {
              i++
            }
          })

          const allZeros = this.uploadedDocuments.splice(0, i)
          this.uploadedDocuments = this.uploadedDocuments.concat(allZeros)
          this.uploadedDocuments = this.sortItemsByAscendingOrder(this.uploadedDocuments)
          this.addIdToDocumentList()
          this.tempUploadedDocuments = JSON.parse(JSON.stringify(this.uploadedDocuments))
        }
        this.getAllUploadedJobDocumentRunning = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading uploaded document list, please try again', true)
        this.getAllUploadedJobDocumentRunning = false
        this.loading = false
      })
  }

  private onDocumentUploadComplete() {
    this.showAddDocumentDialog = false
    this.getAllUploadedJobDocument()
  }

  private sortItemsByAscendingOrder(itemsArray: UploadedJobDocumentDetail[]): UploadedJobDocumentDetail[] {
    let orderNo = 0
    itemsArray.forEach((e: UploadedJobDocumentDetail) => {
      const tmp = ++orderNo
      e.order = tmp.toString()
    })

    return itemsArray
  }

  private get getEngineerIconColor(): string {
    if (this.jobId) {
      return GetTheme(this.jobType).primary
    }
    return ''
  }

  private onEditDocumentDetail(item: UploadedJobDocumentDetail) {
    this.setRecordToDefault()
    this.previousItem = Object.assign({}, item)
    this.index = item.documentUrl
    this.errorMessage = ''
  }

  private onCancelEditDocumentDetail(item: UploadedJobDocumentDetail) {
    this.setRecordToDefault()
    this.index = null
  }

  private setRecordToDefault() {
    if (this.uploadedDocuments) {
      const index = this.uploadedDocuments.findIndex((e) => e.documentUrl === this.index)
      if (index !== -1) {
        this.uploadedDocuments[index] = this.previousItem
      }
      this.uploadedDocuments = [...this.uploadedDocuments]
    }
  }

  private itemKey(item: any) {
    if (!this.itemKeys.has(item)) {
      this.itemKeys.set(item, ++this.currentItemKey)
    }
    return this.itemKeys.get(item)
  }

  private getDocumentTypeDescription(id: number) {
    const documentType = this.uploadedDocumentTypes.find((d) => d.id === id)
    return documentType ? documentType.description : ''
  }

  private async onUpdateDocumentMetadata(item: UploadedJobDocumentDetail) {
    if (this.checkIsTypeDeleted()) {
      this.typeValidationMessage('The selected type is deleted.')
      return
    }

    if (JSON.stringify(this.previousItem) === JSON.stringify(item)) {
      this.saveSnackbarText = 'No Document Detail Updated!'
      this.saveSnackbar = true
      this.index = null
      return
    }

    this.loading = true

    try {
      item.note = item.note.replace(/\n/gi, ' ') // replace ↵ from string
      item.documentType = this.getDocumentTypeDescription(item.documentTypeId)

      const containerName = 'documents'
      const blobUri = item.documentUrl.split(`/${containerName}`)[0]
      const sasToken = item.documentUrl.split('?')[1]
      const blobName = item.documentUrl.split('?')[0].split(`${containerName}/`)[1]

      const metadata = {
        documentType: item.documentType,
        documentTypeId: String(item.documentTypeId),
        uploadedByUserName: item.uploadedByUserName,
        uploadedAt: item.uploadedAt,
        documentName: item.documentName,
        note: item.note,
        availableForEngineer: String(item.availableForEngineer ?? false),
      } as { [propertyName: string]: string }

      if (item.note) {
        metadata.note = item.note
      }

      await new BlobServiceClient(`${blobUri}?${sasToken}`)
        .getContainerClient(containerName)
        .getBlockBlobClient(blobName)
        .setMetadata(metadata)

      if (await DocumentController.UpdateJobDocumentMetadata(item, this.jobId)) {
        this.saveSnackbarText = 'Document detail updated successfully.'
        this.saveSnackbar = true
        this.index = null
      }
    } catch (error) {
      const message = `Error updating document detail, please try again`
      eventBus.$emit('errorHandler', message, true)
      logger.error(message, { error })
    }

    this.loading = false
  }

  private get getDocumentTypes() {
    // get document types with isDeleted flag false
    return this.uploadedDocumentTypes.filter((u) => !u.isDeleted)
  }

  private get getDocumentTypeItems() {
    return this.uploadedDocumentTypes.filter(
      (e) =>
        !e.isDeleted ||
        (e.isDeleted ? this.tempUploadedDocuments.findIndex((i) => i.documentTypeId === e.id) !== -1 : false)
    )
  }

  private checkIsTypeDeleted() {
    let isSelectedTypeDeleted = false
    for (const document of this.uploadedDocuments) {
      const documentType = this.uploadedDocumentTypes.find((tr) => tr.id === document.documentTypeId)
      const oldDocumentType = this.tempUploadedDocuments.find(
        (e) => e.documentTypeId === document.documentTypeId && e.id === document.id
      )
      isSelectedTypeDeleted = oldDocumentType ? false : documentType && documentType.isDeleted ? true : false
      if (isSelectedTypeDeleted) {
        break
      }
    }
    return isSelectedTypeDeleted
  }

  private addIdToDocumentList() {
    // add id to document type list, required to prevent user from select deleted document type, if previously not selected
    for (let index = 0; index < this.uploadedDocuments.length; index++) {
      const element = this.uploadedDocuments[index]
      element.id = index + 1
    }
  }

  private typeValidationMessage(errorMessage: string) {
    if (errorMessage !== '') {
      this.errorMessage = errorMessage
      return true
    }
  }
}
</script>

<style scoped>
.upload-document-section.gridView >>> th {
  font-size: 14px !important;
}
.upload-document-section >>> .action-col {
  min-width: 150px;
}
.upload-document-section >>> tbody td {
  word-break: break-all;
  white-space: normal;
}
.engineer-icon svg {
  width: 20px;
  position: relative;
  top: 5px;
  margin: 0px 3px;
  cursor: pointer;
}
.engineer-checkbox {
  vertical-align: middle;
}
.uploadedDocument >>> .v-table__overflow {
  overflow: visible;
}
.notes-tooltip-content {
  background-color: #fff9c9;
  opacity: 1;
  color: #000;
  font-size: 14px;
  width: 350px;
}
@media only screen and (max-width: 1400px) {
  .upload-document-section >>> thead th {
    width: 60px;
    max-width: 65px;
    word-break: break-all;
    white-space: normal;
  }
  .upload-document-section >>> thead th:first-child {
    width: 160px;
    min-width: 160px;
  }
  .upload-document-section >>> thead th:nth-child(2) {
    width: 80px;
    min-width: 80px;
  }
  .upload-document-section >>> thead th:nth-child(3) {
    width: 77px;
    min-width: 77px;
  }
  .upload-document-section >>> thead th:nth-child(4) {
    width: 84px;
    min-width: 84px;
  }
  .action-td-col .v-btn .v-icon {
    font-size: 18px;
  }
  .action-td-col .v-btn {
    height: 24px;
    width: 24px;
  }
  .engineer-icon svg {
    width: 16px;
    position: relative;
    top: 3px;
    margin: 0px 3px;
    cursor: pointer;
  }
}
</style>
