import { computed, ref } from 'vue'

export function createCountdown(onComplete: () => void) {
  const remainingSeconds = ref<number>(0)
  const timer = ref<number | undefined>(undefined)

  function start(seconds: number) {
    if (timer.value) {
      throw new Error('Attempted to start countdown when countdown is already running.')
    }
    remainingSeconds.value = seconds
    timer.value = window.setInterval(tick, 1000)
  }

  function stop() {
    timer.value = undefined
    remainingSeconds.value = 0
  }

  function tick() {
    remainingSeconds.value -= 1
    if (remainingSeconds.value <= 0) {
      onComplete()
      stop()
    }
  }

  const timeLeft = computed(() => {
    const seconds = String(remainingSeconds.value % 60).padStart(2, '0')
    const minutes = String(parseInt((remainingSeconds.value / 60).toString(), 10)).padStart(2, '0')
    return `${minutes}:${seconds}`
  })

  return {
    start,
    stop,
    timeLeft,
    remainingSeconds,
  }
}
