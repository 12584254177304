import { defineStore } from 'pinia'

import { Reservation } from 'twilio-taskrouter'
import { Call } from '@twilio/voice-sdk'
import { ref } from 'vue'

export const useTwilioCall = defineStore('twilio-call', () => {
  const callRef = ref<Call>()
  const reservationRef = ref<Reservation>()

  function setCall(call: Call) {
    callRef.value = call
  }

  function clearCall() {
    callRef.value = undefined
  }

  function setReservation(reservation: Reservation) {
    reservationRef.value = reservation
  }

  function clearReservation() {
    reservationRef.value = undefined
  }

  return {
    setCall,
    clearCall,
    setReservation,
    clearReservation,
    call: callRef,
    reservation: reservationRef,
  }
})
