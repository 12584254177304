<!-- OBSOLETE - Please use AgentAssistTextField.vue instead -->
<template>
  <v-flex xs12>
    <v-text-field
      :class="{ 'font-weight-bold': detail.isSet }"
      :label="label"
      v-model="detail.value"
      :readonly="true"
      :loading="detail.awaiting"
    >
      <template #append>
        <v-flex>
          <retrieval-button @click="$emit('retrieve')" :isSet="detail.isSet" :disabled="disabled" />
        </v-flex>
      </template>
    </v-text-field>
  </v-flex>
</template>

<script lang="ts">
import RetrievalButton from './RetrievalButton.vue'
import CardDetail from '@/models/twilio/assistedPay/CardDetail'

export default {
  components: {
    RetrievalButton,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    detail: {
      type: CardDetail,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
