import { defineStore } from 'pinia'
import { ref, Ref, watch } from 'vue'
import ContractorRatePackageModel from '@/models/contractor/ContractorRatePackageModel'
import RateController from '@/api/rateController'

export const useContractorRatesStore = defineStore('contractor-rates', () => {
  const all = ref<ContractorRatePackageModel[]>([])
  const rates = ref<ContractorRatePackageModel[]>([])
  const show = ref(false)
  const ratePackageId: Ref<string | null> = ref(null)

  watch(show, () => {
    if (show.value) {
      setRates()
    }
  })

  async function getAllRates() {
    all.value = await RateController.GetAllContractorRatePackages()
  }
  if (all.value.length === 0) {
    getAllRates()
  }

  async function setRates() {
    if (ratePackageId.value) {
      rates.value = all.value.filter((r) => r.id === ratePackageId.value)
      if (rates.value.length > 0) {
        return
      }

      RateController.GetContractorRatePackageAsync(ratePackageId.value).then((rate) => {
        if (rate) {
          all.value.push(rate)
          rates.value = [rate]
          return
        }
      })
      rates.value = [] as ContractorRatePackageModel[]
    }
  }

  return {
    rates,
    fetchRates: setRates,
    show,
    ratePackageId,
  }
})
