import Axios, { AxiosResponse } from 'axios'
import CallCentreApi, { handleError } from '@/api/CallCentreApiAxiosPlugin'
import SearchItemModel from '@/models/policyHolder/SearchItemModel'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import TradeModel from '@/models/policyHolder/TradeModel'
import EmergencyDetailModel from '@/models/policyHolder/EmergencyDetailModel'
import RepudiationReasonModel from '@/models/policyHolder/RepudiationModel'
import Store from '@/store'
import { JobType } from '@/models/types'
import TemplateTypeModel from '@/models/policy/TemplateTypeModel'
import DocumentTemplateModel from '@/models/policy/DocumentTemplateModel'
import PolicyRouteToMarketModel from '@/models/policy/PolicyRouteToMarketModel'
import EmergencyThirdPartyTrade from '@/models/policyHolder/EmergencyThirdPartyTrade'
import CustomPolicySearchRequest from '@/models/policy/CustomPolicySearchRequest'
import CustomPolicySearchResponse from '@/models/policy/CustomPolicySearchResponse'
import CustomPolicyRetrieveResponse from '@/models/policy/CustomPolicyRetrieveResponse'

export default class PolicyHolderController {
  public static async Search(searchValue: string): Promise<SearchItemModel[]> {
    const res = await CallCentreApi.get<SearchItemModel[]>(
      'PolicyHolder/Search?searchText=' + encodeURIComponent(searchValue)
    )
    const data = res.data ? res.data : []
    for (let i = 0, l = data.length; i < l; i++) {
      data[i].displayValue = [
        data[i].ph_name,
        data[i].ph_addressLine1,
        data[i].ph_postcode,
        `(${data[i].policy_number})`,
      ]
        .filter(Boolean)
        .join(', ')
    }
    return data
  }

  public static async GetEmergencies(jobType?: JobType): Promise<EmergencyModel[]> {
    const res = await CallCentreApi.get<EmergencyModel[]>('PolicyHolder/GetEmergencies?jobType=' + jobType)
    return res.data ? res.data : []
  }

  public static async GetEmergencyDetails(jobType?: JobType): Promise<EmergencyDetailModel[]> {
    const res = await CallCentreApi.get<EmergencyDetailModel[]>('PolicyHolder/GetEmergencyDetails?jobType=' + jobType)
    return res.data ? res.data : []
  }

  public static async GetTrades(): Promise<TradeModel[]> {
    const res = await CallCentreApi.get<TradeModel[]>('PolicyHolder/GetTrades')
    return res.data ? res.data : []
  }

  public static async SaveTrade(tradeModel: TradeModel): Promise<number | undefined> {
    const res: AxiosResponse = await CallCentreApi.post('PolicyHolder/SaveTrade', tradeModel)
    return res.status >= 200 && res.status < 300 ? res.data : undefined
  }

  public static async DeleteTrade(id: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete('PolicyHolder/DeleteTrade?tradeId=' + +encodeURIComponent(id))
    return res.status >= 200 && res.status < 300
  }

  public static async GetLoqateApiKey(): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get('PolicyHolder/GetLoqateApiKey')
    if (res.status === 200) {
      return res.data
    } else {
      return ''
    }
  }

  public static async FindAddressFromLoqateApi(text: string, apiKey: string, id: string): Promise<any> {
    const res: AxiosResponse = await Axios.get(
      Store.Instance.state.Environment.LocateFindApi +
        '?Key=' +
        apiKey +
        '&Text=' +
        text +
        (id ? '&Container=' + id : '')
    )
    if (res.status === 200) {
      return res
    } else {
      return false
    }
  }

  public static async RetrieveAddressLoqateApi(id: string, apiKey: string): Promise<any> {
    const res: AxiosResponse = await Axios.get(
      Store.Instance.state.Environment.LocateRetrieveApi + '?Key=' + apiKey + '&Id=' + id
    )
    if (res.status === 200) {
      return res
    } else {
      return false
    }
  }

  public static async GetRepudiationReasons(): Promise<RepudiationReasonModel[]> {
    const res = await CallCentreApi.get<RepudiationReasonModel[]>('repudiation-reasons')
    return res.data ? res.data : []
  }

  public static async GetTemplateTypes(): Promise<TemplateTypeModel[]> {
    const res = await CallCentreApi.get<TemplateTypeModel[]>('PolicyHolder/GetTemplateTypes')
    return res.data ? res.data : []
  }

  public static async SaveDocumentTemplate(documentTemplate: DocumentTemplateModel): Promise<number> {
    const res: AxiosResponse = await CallCentreApi.post('PolicyHolder/SaveDocumentTemplate', documentTemplate)
    if (res.status === 200) {
      const result = res.data as number
      if (!result) {
        return 0
      }
      return result
    } else {
      return 0
    }
  }

  public static async GetDocumentTemplateByInsurerId(insurerId: number): Promise<DocumentTemplateModel[]> {
    const res = await CallCentreApi.get<DocumentTemplateModel[]>(
      'PolicyHolder/GetDocumentTemplateByInsurerId?insurerId=' + insurerId
    )
    return res.data ? res.data : []
  }

  public static async GetPolicyRouteToMarketList(insurerId: number): Promise<PolicyRouteToMarketModel[]> {
    const res: AxiosResponse = await CallCentreApi.get<PolicyRouteToMarketModel[]>(
      'PolicyHolder/GetPolicyRouteToMarketList?insurerId=' + insurerId
    )
    return res.data ? res.data : []
  }

  public static async SaveThirdPartyDetail(
    thirdPartyTrade: EmergencyThirdPartyTrade
  ): Promise<EmergencyThirdPartyTrade> {
    const res = await CallCentreApi.post<EmergencyThirdPartyTrade>('PolicyHolder/SaveThirdPartyDetail', thirdPartyTrade)
    return res.data
  }

  public static async DeleteThirdPartyDetail(id: number): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete('PolicyHolder/DeleteThirdPartyDetail?id=' + id)
    return res.status >= 200 && res.status < 300
  }

  public static async GetThirdPartyContractor(
    policyId: number,
    emergencyTypeId: number
  ): Promise<EmergencyThirdPartyTrade | null> {
    const res = await CallCentreApi.get<EmergencyThirdPartyTrade>(
      'PolicyHolder/GetThirdPartyContractor?policyId=' + policyId + '&emergencyTypeId=' + emergencyTypeId
    )
    return res.data
  }

  public static async GetClientIdFromDeliveryNumber(deliveryPhoneNumber: string): Promise<string | null> {
    const res = await CallCentreApi.get<string | null>(
      `PolicyHolder/GetClientIdFromDeliveryNumber?deliveryPhoneNumber=${deliveryPhoneNumber}`
    )
    return res.data
  }

  public static async SearchCustomerPolicy(
    request: CustomPolicySearchRequest
  ): Promise<CustomPolicySearchResponse | null> {
    try {
      const response = await CallCentreApi.post<CustomPolicySearchResponse>('policies/form-search', request)
      if (response.status === 200) return response.data
      handleError('Failed to retrieve search data from API')
    } catch (error: any) {
      handleError('Failed to retrieve search data from API', error)
    }
    return null
  }

  public static async RetrieveCustomerPolicy(
    clientId: string,
    path: string
  ): Promise<CustomPolicyRetrieveResponse | null> {
    try {
      const response = await CallCentreApi.get<CustomPolicyRetrieveResponse>(
        `PolicyHolder/GetPolicy?clientId=${encodeURIComponent(clientId)}&path=${encodeURIComponent(path)}`
      )
      if (response.status === 200) {
        return response.data
      }
      handleError('Failed to retrieve policy from API')
    } catch (error: any) {
      handleError('Failed to retrieve policy from API', error)
    }
    return null
  }
}
