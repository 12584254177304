import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import UserModel from '@/models/user/UserModel'
import IncidentManagerShiftTimeModel from '@/models/user/IncidentManagerShiftTimeModel'

export default class UserController {
  public static async UpdateLoginTimeIM(): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get(`User/UpdateLoginTimeIM`)
    return res.status >= 200 && res.status < 300
  }

  public static async GetUserList(): Promise<UserModel[]> {
    const res = await CallCentreApi.get<UserModel[]>('User/GetUserList')
    return res.data ? res.data : []
  }

  public static async GetUserTeams(): Promise<string[]> {
    const res = await CallCentreApi.get<string[]>('User/GetUserTeams')
    return res.data ? res.data : []
  }

  public static async AddIncidentManagerShift(
    incidentManagerShiftTime: IncidentManagerShiftTimeModel
  ): Promise<IncidentManagerShiftTimeModel | null> {
    const res: AxiosResponse = await CallCentreApi.post('User/AddIncidentManagerShift', incidentManagerShiftTime)
    return res.data
  }

  public static async GetIncidentManagerShift(): Promise<IncidentManagerShiftTimeModel | null> {
    const res: AxiosResponse = await CallCentreApi.get('User/GetIncidentManagerShift')
    return res.data
  }

  public static async DeactivateUser(userId: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.delete('User/DeactivateUserAsync?userId=' + userId)
    return res.status >= 200 && res.status < 300
  }
}
