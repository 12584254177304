import Job from '@/models/Job'
import TimeLineCardModel from '@/models/TimeLineCardModel'
import moment from 'moment'
import CallContractorModel from '@/models/claim/CallContractorModel'
import Emergency from '@/models/Emergency'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import { EngineerJobVisitStatus } from '@/common/enums'
import EmailModel from '@/models/claim/EmailModel'
import JobNoteModel from '@/models/claim/JobNoteModel'
import JobDelay from '@/models/JobDelay'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import PictureUploadModel from '@/models/claim/PictureUploadModel'
import { JobType } from '@/models/types'
import ComplaintModel from '@/models/claim/ComplaintModel'
import { useLaunchDarkly } from '@/plugins/launch-darkly'

export abstract class TimeLineHelper {
  public abstract generateCards(job: Job): TimeLineCardModel[]

  public abstract showCompleteJobConfirmationCard(job: Job): boolean

  public addEmailCardToList(email: EmailModel): TimeLineCardModel {
    return new TimeLineCardModel({
      title: 'Outgoing Email',
      colour: 'yellow',
      icon: 'email',
      createdDate: email.createdAt,
      forJobId: email.jobId,
      forItemId: email.id,
      cardBodyComponent: 'EmailCard',
      detailComponent: 'EmailPreview',
      cardFooterComponent: '',
    })
  }

  public bindCallContractorCard(
    job: Job,
    callContractor: CallContractorModel | undefined,
    emergency: Emergency
  ): TimeLineCardModel {
    return new TimeLineCardModel({
      title: '',
      colour: 'yellow',
      icon: 'call',
      createdDate: moment(new Date()),
      forJobId: job.jobId,
      forItemId: callContractor ? callContractor.id : '',
      forEmergencyId: emergency.id,
      cardBodyComponent: 'CallContractorCard',
      detailComponent: 'CallContractorPreview',
      cardClass: 'flatCard',
    })
  }

  public bindFailedToContactCallCard(emergency: Emergency, unavailableContractors: ContractorAppointedModel[]) {
    return new TimeLineCardModel({
      title: '',
      colour: 'purple',
      icon: 'call',
      createdDate: unavailableContractors[unavailableContractors.length - 1].createdAt,
      forJobId: unavailableContractors[unavailableContractors.length - 1].jobId,
      forEmergencyId: emergency.id,
      cardBodyComponent: 'FailedToContactCallCard',
      detailComponent: 'FailedToContactCallPreview',
      cardClass: 'flatCard',
    })
  }

  public bindJobNoteCard(job: Job, note: JobNoteModel) {
    let jobNoteEmergency: Emergency | null | undefined = null
    let jobNoteEmergencyId = ''
    if (job) {
      // get emergency Id from emergency detail of job note
      jobNoteEmergency = job.emergencies.find((c) => c.detailId === note.forEmergencyDetailId)
      jobNoteEmergencyId = jobNoteEmergency ? jobNoteEmergency.id : ''
    }
    return new TimeLineCardModel({
      title: note.noteType === 'LinkedCall' ? 'Linked Phone Call' : 'Job Notes',
      colour: 'yellow',
      icon: 'forum',
      createdDate: note.createdAt,
      forJobId: note.jobId,
      forItemId: note.id,
      forEmergencyId: jobNoteEmergencyId,
      cardBodyComponent: 'JobNoteCard',
      detailComponent: 'JobNotePreview',
      cardFooterComponent: 'JobNoteFooter',
    })
  }

  public bindSiteDataCard(job: Job, engineerVisitDetail: EngineerVisitDetail) {
    return new TimeLineCardModel({
      title: 'Site Data',
      colour: 'yellow',
      icon: 'scatter_plot',
      createdDate: engineerVisitDetail.createdAt,
      forJobId: job.id,
      forItemId: engineerVisitDetail.id,
      cardBodyComponent: 'SiteDataCard',
      detailComponent: 'SiteDataPreview',
      cardFooterComponent: '',
    })
  }

  public bindPictureUploadCard(claimPictures: PictureUploadModel[]) {
    return new TimeLineCardModel({
      title: 'Picture Upload',
      colour: 'yellow',
      icon: 'camera_alt',
      createdDate: claimPictures[0].createdAt,
      forJobId: claimPictures[0].jobId,
      forItemId: claimPictures[0].id,
      cardBodyComponent: 'PictureUploadCard',
      detailComponent: 'PictureUploadPreview',
      cardClass: '',
    })
  }

  public bindEngineerVisitCard(visitDetail: EngineerVisitDetail, emergency: Emergency, jobType: JobType) {
    return new TimeLineCardModel({
      title:
        jobType === 'SI' && visitDetail.siReturnVisitReason ? 'Engineer Return Visit Detail' : 'Engineer Visit Detail',
      colour: this.getEngineerVisitStatusColor(visitDetail.status, emergency.followOnWorkRequired),
      icon: 'person',
      createdDate: visitDetail.createdAt,
      forJobId: visitDetail.jobId,
      forEmergencyId: emergency.id,
      forItemId: visitDetail.id,
      status:
        visitDetail.status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed] &&
        emergency.followOnWorkRequired
          ? 'Follow On Required'
          : visitDetail.status,
      cardBodyComponent: 'EngineerVisitCard',
      detailComponent: 'EngineerVisitPreview',
    })
  }

  public getEngineerVisitStatusColor(status, followOnWorkRequired) {
    if (status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed] && followOnWorkRequired) {
      return 'blue'
    }

    if (
      status === EngineerJobVisitStatus[EngineerJobVisitStatus.PolicyHolderAbsent] ||
      status === EngineerJobVisitStatus[EngineerJobVisitStatus.ReAssigned]
    ) {
      return 'yellow'
    }

    if (
      status === EngineerJobVisitStatus[EngineerJobVisitStatus.Pending] ||
      status === EngineerJobVisitStatus[EngineerJobVisitStatus.Accepted] ||
      status === EngineerJobVisitStatus[EngineerJobVisitStatus.Completed]
    ) {
      return 'green'
    }

    if (status === EngineerJobVisitStatus[EngineerJobVisitStatus.JobStarted]) {
      return 'blue'
    }

    return 'red'
  }

  public bindJobCompleteConfirmationCard(job: Job) {
    return new TimeLineCardModel({
      title: '',
      colour: 'yellow',
      icon: 'work',
      createdDate: moment(),
      forJobId: job.id,
      forItemId: '',
      forEmergencyId: '',
      cardBodyComponent: 'JobCompleteConfirmationCard',
      detailComponent:
        job.jobType === 'HE' || job.jobType === 'US'
          ? 'JobCompleteConfirmationPreview'
          : job.jobType === 'SI'
          ? 'SIJobCompleteConfirmationPreview'
          : '',
      cardClass: 'flatCard',
    })
  }

  public bindJobCompleteCard(job: Job) {
    return new TimeLineCardModel({
      title: 'Job Complete',
      colour: 'purple',
      icon: 'check_circle',
      createdDate: job.jobCompletedAtUtc ? moment(job.jobCompletedAtUtc) : moment(new Date()),
      forJobId: job.jobId,
      forItemId: '',
      cardBodyComponent: 'JobCompleteCard',
      detailComponent: 'JobCompletePreview',
      cardFooterComponent: 'JobCompleteFooter',
    })
  }

  public bindJobDelayCard(job: Job, jobDelay?: JobDelay): TimeLineCardModel {
    return new TimeLineCardModel({
      title: 'Delay',
      colour: 'red',
      icon: 'av_timer',
      createdDate: jobDelay ? jobDelay.createdAt : moment(new Date()),
      forJobId: job.id,
      forItemId: jobDelay ? jobDelay.id : '',
      forEmergencyId: '',
      cardBodyComponent: 'JobDelayCard',
      detailComponent: 'JobDelayPreview',
      cardFooterComponent: '',
    })
  }

  public bindCustomerToPayCard(jobId: string, model: CustomerToPayModel | null) {
    return new TimeLineCardModel({
      title: 'Customer To Pay',
      colour: 'yellow',
      icon: 'payment',
      createdDate: model && model.createdAt ? model.createdAt : moment(new Date()),
      forJobId: jobId,
      forItemId: model ? model.id : '',
      forEmergencyId: model ? model.emergencyId : '',
      cardBodyComponent: 'CustomerToPayCard',
      detailComponent: this.isCustomerToPayV2Enabled ? 'PaymentPreview' : 'CustomerToPayPreview',
      cardFooterComponent: '',
    })
  }

  public bindJobFinancialSummaryCard(job: Job) {
    const jobCompletionDate = job.jobCompletedAtUtc ? new Date(job.jobCompletedAtUtc.toString()) : new Date()
    return new TimeLineCardModel({
      title: 'Financial Summary',
      colour: 'purple',
      icon: 'check_circle',
      createdDate: moment(jobCompletionDate.setSeconds(jobCompletionDate.getSeconds() + 5)),
      forJobId: job.id,
      cardBodyComponent: 'JobFinancialSummaryCard',
      detailComponent: 'JobFinancialSummaryPreview',
      cardFooterComponent: 'JobCompleteFooter',
    })
  }

  public bindComplaintCard(job: Job, complaint?: ComplaintModel): TimeLineCardModel {
    return new TimeLineCardModel({
      title: 'Complaint Raised',
      colour: 'purple',
      icon: 'announcement',
      createdDate: complaint ? complaint.createdAt : moment(new Date()),
      forJobId: job.id,
      forItemId: complaint ? complaint.id : '',
      forEmergencyId: '',
      cardBodyComponent: 'ComplaintCard',
      detailComponent: 'ComplaintCardPreview',
      cardFooterComponent: 'ComplaintCardFooter',
    })
  }

  private get isCustomerToPayV2Enabled(): boolean {
    const ldClient = useLaunchDarkly()
    return ldClient !== undefined ? ldClient.variation('agent-assisted-payment-v2') : false
  }
}
