<template>
  <v-card class="pa-2">
    <v-layout wrap>
      <v-flex
        v-show="headerText"
        class="cnt-time-head"
        :class="headerText || dateSelection ? 'xs6 select-date' : 'xs12'"
      >
        <v-icon color="secondary" class="mt-2 mr-2">date_range</v-icon>
        <b class="chosen-date">
          {{ dateSelection ? '' : headerText }}
          <v-menu
            v-if="dateSelection"
            ref="datePickerMenu"
            v-model="showDatePickerSelection"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="chosenFormattedDate"
                label="Select Date"
                readonly
                hide-details
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="chosenDate" no-title scrollable :min="setMinDate" class="date-picker">
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="showDatePickerSelection = false">Cancel</v-btn>
              <v-btn flat color="primary" class="select-date-btn" @click="onSelectDatePicker(chosenDate)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </b>
      </v-flex>
      <v-flex v-if="dateSelection ? checkIsOldDate() : true" :class="headerText ? 'xs6' : 'xs12'">
        <v-select
          :id="headerText"
          ref="multipleSelectionTimeSlots"
          v-model="selectedSlots"
          :items="todayTimeSlot"
          :disabled="disableTimeSlots"
          multiple
          return-object
          item-text="slot"
          item-value="slotId"
          persistent-hint
          label="Select Time Slot(s)"
          hide-details
          class="hide-option select-slots"
          @change="onSlotChange(selectedSlots, $event)"
        >
          <template #selection="selection">
            <!-- We leave this blank as we dont want the values to show in the v-select -->
          </template>
          <template #item="data">
            <div class="custom-slot">
              <div class="custom-slot__checkbox">
                <v-checkbox :input-value="isSlotSelected(data.item.slot)"></v-checkbox>
              </div>
              <div class="custom-slot__text">
                <span>{{ data.item.slot === '24hrs' ? retrieveCustomSlotLabel(data.item.slot) : data.item.slot }}</span>
              </div>
            </div>
          </template>
        </v-select>
      </v-flex>
      <v-flex xs12 class="mt-2 mb-2">
        <template v-for="item in selectedSlots">
          <v-chip
            v-if="item.slotId > 0"
            :key="JSON.stringify(item.slotId)"
            flat
            outline
            :disabled="disableTimeSlots"
            class="white--text selected-slots"
            color="primary"
            dark
            small
            :close="dateSelection ? checkIsOldDate() : true"
            @input="removeSelectedSlot(item, selectedSlots, todayTimeSlot)"
          >
            <b>{{ item.slot }}</b>
          </v-chip>
        </template>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import TimeSlot from '@/models/claim/TimeSlot'
import Shared from '@/common/shared'
import moment from 'moment'
import Store from '@/store'
import storeGetters from '@/storeGetters'

@Component
export default class TimeSlotPicker extends Vue {
  @Prop() private headerText: string
  @Prop() private slotItems: TimeSlot[]
  @Prop({ default: 'Today' }) private slotType: string
  @Prop() private model: any
  @Prop({ default: false }) private dateSelection: boolean
  @Prop({ default: true }) private filterByDate: boolean
  @Prop({ default: false }) private filterSlotByTime: boolean

  private selectedSlots: TimeSlot[] = []
  private todayTimeSlot: TimeSlot[] = []
  private waitForMoreInputTimeoutHandle: number | null = null
  private isComponentLoaded = false
  private chosenDate: any = null
  private setMinDate: any = null
  private chosenFormattedDate: any = null
  private showDatePickerSelection = false
  private chosenDateFormat: string = Shared.dateFormatForDatePicker

  @Watch('selectedSlots')
  protected onSlotSelectionChange() {
    if (!this.dateSelection) {
      this.callWatchUpdate()
    }
  }

  @Watch('slotItems')
  private onSlotItemsCahange(newValue: any) {
    if (this.dateSelection) {
      // this.selectedSlots = []
      this.slotItems = newValue
      this.setTimeSlot()
    }
  }

  @Watch('headerText')
  private onHeaderTextChange() {
    if (this.dateSelection) {
      this.chosenFormattedDate = this.headerText
      this.chosenDate = Shared.getFormatedDate(moment(this.slotItems.map((c) => c.startTime)[0]), this.chosenDateFormat)
    }
  }

  private created() {
    if (this.dateSelection) {
      this.chosenFormattedDate = this.headerText
      this.chosenDate = Shared.getFormatedDate(moment(this.slotItems.map((c) => c.startTime)[0]), this.chosenDateFormat)
      const today = new Date()
      this.setMinDate = new Date(today).toISOString().slice(0, 10)
    }
  }

  private mounted() {
    this.setTimeSlot()
    setTimeout(() => {
      this.isComponentLoaded = true
    }, 1000)
  }

  private callWatchUpdate() {
    if (!this.isComponentLoaded) {
      return
    }
    if (this.waitForMoreInputTimeoutHandle) {
      window.clearTimeout(this.waitForMoreInputTimeoutHandle)
    }
    const self = this
    this.waitForMoreInputTimeoutHandle = window.setTimeout(() => {
      if (self.selectedSlots.some((x) => x.slot === '24hrs') && self.slotType === 'Today') {
        self.updateDayTwoAdditionalTimeSlots()
      }
      self.$emit('updateSlotItems', self.selectedSlots, self.model, self.slotType)
    }, 800)
  }

  private updateTimeSlotsWithPickedDate() {
    const self = this
    if (this.dateSelection) {
      const defaultDayAfterTomorrow: any = this.chosenFormattedDate ? this.chosenFormattedDate.split('/') : ''
      const formattedDate: Date | null =
        this.dateSelection && defaultDayAfterTomorrow
          ? new Date(defaultDayAfterTomorrow[2], defaultDayAfterTomorrow[1] - 1, defaultDayAfterTomorrow[0])
          : null
      const usingDate = formattedDate
        ? new Date(formattedDate.getFullYear(), formattedDate.getMonth(), formattedDate.getDate(), 0, 0, 0)
        : null

      // remove datetime slot, if no slot selected
      if (this.selectedSlots.length === 1 && this.selectedSlots[0].slot === 'Daytime') {
        this.selectedSlots = []
      }

      this.selectedSlots.forEach((element, index) => {
        if (usingDate && element.slotId > 0) {
          element.startTime =
            usingDate && element.slotId !== -1 ? new Date(usingDate.setHours(element.slotId - 1)) : element.startTime
          element.endTime =
            usingDate && element.slotId !== -1 ? new Date(usingDate.setHours(element.slotId)) : element.endTime
        }
      })
      this.callWatchUpdate()
      if (this.selectedSlots.length === 0) {
        // if no slot selected, reset slots as per selected date
        if (usingDate) {
          const todayDate: Date = new Date()
          let isTodayDateSelected = false
          if (todayDate.toDateString() === usingDate.toDateString()) {
            isTodayDateSelected = true
          }
          this.todayTimeSlot = []
          this.addCustomSlots(this.todayTimeSlot)

          if (isTodayDateSelected && todayDate.getHours() > 16) {
            this.todayTimeSlot.splice(1, 1)
          }

          for (let i = isTodayDateSelected ? todayDate.getHours() : 0; i < 24; i++) {
            const slot: TimeSlot = new TimeSlot()
            slot.slotId = i + 1
            slot.slot = i + ' to ' + (i + 1)
            slot.startTime = new Date(todayDate.setHours(i))
            slot.endTime = new Date(todayDate.setHours(i + 1))
            slot.isDayTime = i > 7 && i < 17 ? true : false
            this.todayTimeSlot.push(slot)
          }
        }
      }
    }
  }

  @Watch('filterSlotByTime')
  private setTimeSlot(): void {
    this.todayTimeSlot = []
    this.addCustomSlots(this.todayTimeSlot)
    const tempDate: Date = new Date()
    const todayDate: Date = new Date()

    for (let i = this.filterSlotByTime ? tempDate.getHours() : 0; i < 24; i++) {
      const slot: TimeSlot = new TimeSlot()
      slot.slotId = i + 1
      slot.slot = i + ' to ' + (i + 1)
      slot.startTime = new Date(todayDate.setHours(i))
      slot.endTime = new Date(todayDate.setHours(i + 1))
      slot.isDayTime = i > 7 && i < 17 ? true : false
      this.todayTimeSlot.push(slot)
    }
    // bind selected timeslots
    if (this.slotItems && this.slotItems.length > 0) {
      if (this.filterByDate) {
        this.selectedSlots = this.slotItems
      } else {
        this.selectedSlots = Shared.SortData(this.slotItems, 'startTime')
      }
    } else {
      this.selectedSlots = this.slotItems
    }

    if (this.selectedSlots && this.selectedSlots.length > 0) {
      // remove main day time slot if no any slots available for day time slot duration
      if (this.filterSlotByTime && this.todayTimeSlot && tempDate.getHours() > 16) {
        this.todayTimeSlot.splice(1, 1)
      }

      // auto select 24 hours and/or day time as per time slots found on component load
      const todayItem: TimeSlot | undefined = this.selectedSlots.find((e: TimeSlot) => e.slot === '24hrs')
      const dayTimeItem: TimeSlot | undefined = this.selectedSlots.find((e: TimeSlot) => e.slot === 'Daytime')

      // if other slots selected
      // length of slots in dropdown
      const lengthOfAlltimeSlotItems: number = this.todayTimeSlot.filter(
        (x: TimeSlot) => x.slotId !== 0 && x.slotId !== -1
      ).length
      // length of slots in day time
      const lengthOfAlltimeSlotItemsDayTime: number = this.todayTimeSlot.filter((a: TimeSlot) => a.isDayTime).length

      // length of selected slots in dropdown
      const lengthOfItems: number = this.selectedSlots.filter((x) => x.slotId !== 0 && x.slotId !== -1).length
      // length of selected slots in day time
      const lengthOfItemsDayTime: number = this.selectedSlots.filter((a: TimeSlot) => a.isDayTime).length

      // check/uncheck daytime checkbox
      if (lengthOfAlltimeSlotItemsDayTime === lengthOfItemsDayTime) {
        if (!dayTimeItem) {
          this.addCustomDayTimeDaySlot(this.selectedSlots)
        }
      } else if (lengthOfAlltimeSlotItemsDayTime !== lengthOfItemsDayTime) {
        if (dayTimeItem) {
          this.selectedSlots.splice(this.selectedSlots.indexOf(dayTimeItem), 1)
        }
      }
      // check/uncheck 24 hours checkbox
      if (lengthOfAlltimeSlotItems === lengthOfItems) {
        if (!todayItem) {
          this.addCustomAllDaySlot(this.selectedSlots)
        }
      } else if (lengthOfAlltimeSlotItems !== lengthOfItems) {
        if (todayItem) {
          this.selectedSlots.splice(this.selectedSlots.indexOf(todayItem), 1)
        }
      }
    }
  }

  private addCustomSlots(timeslot: TimeSlot[]) {
    this.addCustomAllDaySlot(timeslot)
    this.addCustomDayTimeDaySlot(timeslot)
  }

  private addCustomAllDaySlot(timeslot: TimeSlot[]) {
    const customslot: TimeSlot = new TimeSlot()
    customslot.slotId = -1
    customslot.slot = '24hrs'
    customslot.isDayTime = false
    timeslot.push(customslot)
  }

  private addCustomDayTimeDaySlot(timeslot: TimeSlot[]) {
    const customslot: TimeSlot = new TimeSlot()
    customslot.slotId = 0
    customslot.slot = 'Daytime'
    customslot.isDayTime = false
    timeslot.push(customslot)
  }

  private onSlotChange(bindItems: TimeSlot[], items: TimeSlot[]) {
    const self = this
    setTimeout(() => {
      // get non common item between bindItems and Items array
      const currentChosenItem: TimeSlot | undefined =
        bindItems.length > items.length
          ? bindItems.find((item) => items.indexOf(item) < 0)
          : items.find((item) => bindItems.indexOf(item) < 0)
      self.selectedSlots = self.bindSlotModel(
        self.todayTimeSlot,
        self.selectedSlots,
        bindItems,
        items,
        currentChosenItem
      )
      // check whether currentchosenItem is 24 hours or daytime item
      // if (currentChosenItem && currentChosenItem.slotId <= 0) {
      //   const selectionDrp: any = self.$refs.multipleSelectionTimeSlots as any;
      //   // Close the dropdown of time slots on selction of 24 hours or daytime
      //   selectionDrp.menuIsActive = false;
      // }
      if (self.dateSelection) {
        self.updateTimeSlotsWithPickedDate()
        // sort selected time slots after updating
        Shared.SortData(self.selectedSlots, 'startTime')
      }
    }, 0)
  }

  private onSelectDatePicker(date: string) {
    const subs: any = this.$refs.datePickerMenu
    subs.save(this.chosenDate)
    if (date && this.dateSelection) {
      this.chosenFormattedDate = Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateFormat)
    }
    this.updateTimeSlotsWithPickedDate()
  }

  private bindSlotModel(
    alltimeSlotItems: TimeSlot[],
    modelItems: TimeSlot[],
    bindItems: TimeSlot[],
    items: TimeSlot[],
    selectedSlot: TimeSlot | undefined
  ): TimeSlot[] {
    const todayItem: TimeSlot | undefined = items.find((e: TimeSlot) => e.slot === '24hrs')
    const dayTimeItem: TimeSlot | undefined = items.find((e: TimeSlot) => e.slot === 'Daytime')

    if (selectedSlot) {
      if (selectedSlot.slotId === -1) {
        // if 24 hours selected
        if (todayItem) {
          // if 24 hours checked
          modelItems = alltimeSlotItems
        } else {
          // if 24 hours unchecked
          const selectedTodayItem: TimeSlot | undefined = bindItems.find((e: TimeSlot) => e.slot === '24hrs')
          if (selectedTodayItem) {
            modelItems = []
          }
        }
      } else if (selectedSlot.slotId === 0) {
        // if day time selected
        if (dayTimeItem) {
          // if day time checked
          const selectedItems: TimeSlot[] = alltimeSlotItems.filter(
            (a: TimeSlot) => a.isDayTime || a.slot === 'Daytime'
          )
          selectedItems.forEach((item) => {
            if (modelItems.findIndex((e) => e.slot === item.slot && e.slotId === item.slotId) === -1) {
              modelItems.push(item)
            }
          })

          // Auto check 24 hours checkbox if all selected
          // length of slots in dropdown
          const lengthOfAlltimeSlotItems: number = alltimeSlotItems.filter(
            (x: TimeSlot) => x.slotId !== 0 && x.slotId !== -1
          ).length
          // length of selected slots in dropdown
          const lengthOfItems: number = items.filter((x) => x.slotId !== 0 && x.slotId !== -1).length
          if (lengthOfAlltimeSlotItems === lengthOfItems) {
            if (!todayItem) {
              this.addCustomAllDaySlot(modelItems)
            }
          }
        } else {
          // if day time unchecked
          const selectedDayTimeItem: TimeSlot | undefined = bindItems.find((e: TimeSlot) => e.slot === 'Daytime')
          if (selectedDayTimeItem) {
            const newItems: TimeSlot[] = items.filter((a: TimeSlot) => a.isDayTime === false)
            if (newItems && newItems.length > 0) {
              modelItems = newItems
            } else {
              modelItems = []
            }
          }

          // Auto uncheck 24 hours checkbox on deselect daytime
          if (todayItem) {
            modelItems.splice(modelItems.indexOf(todayItem), 1)
          }
        }
      } else {
        // if other slots selected
        // length of slots in dropdown
        const lengthOfAlltimeSlotItems: number = alltimeSlotItems.filter(
          (x: TimeSlot) => x.slotId !== 0 && x.slotId !== -1
        ).length
        // length of slots in day time
        const lengthOfAlltimeSlotItemsDayTime: number = alltimeSlotItems.filter((a: TimeSlot) => a.isDayTime).length

        // length of selected slots in dropdown
        const lengthOfItems: number = items.filter((x) => x.slotId !== 0 && x.slotId !== -1).length
        // length of selected slots in day time
        const lengthOfItemsDayTime: number = items.filter((a: TimeSlot) => a.isDayTime).length

        // check/uncheck daytime checkbox
        if (lengthOfAlltimeSlotItemsDayTime === lengthOfItemsDayTime) {
          if (!dayTimeItem) {
            this.addCustomDayTimeDaySlot(modelItems)
          }
        } else if (lengthOfAlltimeSlotItemsDayTime !== lengthOfItemsDayTime) {
          if (dayTimeItem) {
            modelItems.splice(modelItems.indexOf(dayTimeItem), 1)
          }
        }
        // check/uncheck 24 hours checkbox
        if (lengthOfAlltimeSlotItems === lengthOfItems) {
          if (!todayItem) {
            this.addCustomAllDaySlot(modelItems)
          }
        } else if (lengthOfAlltimeSlotItems !== lengthOfItems) {
          if (todayItem) {
            modelItems.splice(modelItems.indexOf(todayItem), 1)
          }
        }
      }
    }

    // sort and assign selected items
    return Shared.SortData(modelItems, 'startTime')
  }

  private removeSelectedSlot(item: TimeSlot, selectedData: TimeSlot[], todayTimeSlot: TimeSlot[]) {
    setTimeout(() => {
      this.selectedSlots = this.removeSelectedSlotModel(item, selectedData, todayTimeSlot)
    }, 0)
  }

  private removeSelectedSlotModel(item: TimeSlot, selectedData: TimeSlot[], todayTimeSlot: TimeSlot[]): TimeSlot[] {
    let selectedDatas: TimeSlot[] = selectedData

    // uncheck selected item from the selected list
    const newItems: TimeSlot[] = selectedDatas.filter((a: TimeSlot) => a.slotId !== item.slotId)
    selectedDatas = newItems

    // if day time selected and if slot between 9 - 17
    // remove selected slot as well remove day time selection
    const dayTimeSelected = selectedDatas.find((x: any) => x.slotId === 0)
    if (dayTimeSelected) {
      if (item.slotId >= 9 && item.slotId <= 17) {
        selectedDatas.splice(selectedDatas.indexOf(dayTimeSelected), 1)
      }
    }

    // if all day selected and if slot between 1 - 24
    // remove selected slot as well remove day time selection
    const allDayTimeSelected = selectedDatas.find((x: any) => x.slotId === -1)
    if (allDayTimeSelected) {
      if (item.slotId >= 1 && item.slotId <= 24) {
        selectedDatas.splice(selectedDatas.indexOf(allDayTimeSelected), 1)
      }
    }

    // sort and assign selected items
    this.selectedSlots = selectedDatas
    this.callWatchUpdate()
    return Shared.SortData(this.selectedSlots, 'startTime')
  }

  private checkIsOldDate(): boolean {
    const defaultDayAfterTomorrow: any = this.chosenFormattedDate ? this.chosenFormattedDate.split('/') : ''
    const formattedDate: Date | null = defaultDayAfterTomorrow
      ? new Date(defaultDayAfterTomorrow[2], defaultDayAfterTomorrow[1] - 1, defaultDayAfterTomorrow[0])
      : null
    const usingDate = formattedDate
      ? new Date(formattedDate.getFullYear(), formattedDate.getMonth(), formattedDate.getDate(), 0, 0, 0)
      : null
    if (usingDate !== null) {
      // get selected date
      const givenDate: Date = new Date(usingDate)
      const currentDate: Date = new Date()
      // if selected date is greater or equal to current date allow IM to update timeslots
      if (givenDate.setHours(0, 0, 0, 0) >= currentDate.setHours(0, 0, 0, 0)) {
        // show slots
        return true
      } else {
        // hide slots
        return false
      }
    } else {
      return false
    }
  }

  private get disableTimeSlots(): boolean {
    return Store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  private updateDayTwoAdditionalTimeSlots() {
    if (this.dayTwoAdditionalSlots.length > 0) {
      this.$emit('updateDayTwoTimeSlot', this.dayTwoAdditionalSlots)
    }
  }

  private get dayTwoAdditionalSlots() {
    const nextDaySlots: TimeSlot[] = []

    const nextDayDate = new Date()
    nextDayDate.setDate(nextDayDate.getDate() + 1)

    for (let i = 0; i < nextDayDate.getHours(); i++) {
      const slot: TimeSlot = new TimeSlot()
      slot.slotId = i + 1
      slot.slot = i + ' to ' + (i + 1)
      slot.startTime = new Date(new Date(nextDayDate).setHours(i))
      slot.endTime = new Date(new Date(nextDayDate).setHours(i + 1))
      slot.isDayTime = i > 7 && i < 17
      slot.availabilityOrder = 2
      nextDaySlots.push(slot)
    }

    return nextDaySlots
  }

  private isSlotSelected(slot) {
    return this.selectedSlots.some((x) => x.slot === slot)
  }

  private retrieveCustomSlotLabel(slotName: string) {
    
    const isCustomerAvailabilityComponent = this.$parent && this.$parent.$parent && this.$parent.$parent.$options.name === 'CustomerAvailabilityPreview'
    return this.slotType === 'Today' && isCustomerAvailabilityComponent ? 'Add 24 Hours' : slotName
  }
}
</script>

<style lang="scss" scoped>
.custom-slot {
  display: flex;
  align-items: center;
  margin-top: 5px;

  &__checkbox {
    ::v-deep {
      .v-input {
        &__slot {
          margin: 0 !important;
        }
        &--selection-controls {
          margin-top: 12px !important;

          &__input {
            margin-right: 20px;
          }
        }
      }
    }
  }

  &__text {
    margin-top: 2px;
  }
}

.cnt-time-head {
  display: flex;
}
.cnt-time-head b {
  display: inline-block;
  width: 100%;
  margin: auto 0 0;
}
.hide-option >>> .v-select__selections {
  max-height: 30px;
  opacity: 0;
}
.select-date >>> .v-input__control .v-input__slot:before,
.select-date >>> .v-input__control .v-input__slot:after {
  display: none;
}
</style>
