<template>
  <v-form>
    <v-container fluid class="pa-0 grid-list-md">
      <v-layout wrap>
        <v-flex xs6>
          <v-checkbox
            v-model="isGasSafeRegistered"
            hide-details
            color="primary"
            label="Are you Gas Safe Registered?"
            @change="onChangeGasSafeRegistered($event)"
          ></v-checkbox>
        </v-flex>
        <v-flex xs12>
          <v-layout>
            <v-flex xs4 pt-2>
              <v-text-field
                v-model="gasSafeRegistrationNumber"
                v-validate="getValidationRule"
                :disabled="!isGasSafeRegistered"
                label="Gas Safe Registration Number"
                :class="isGasSafeRegistered ? 'required' : ''"
                :data-vv-scope="formRef"
                data-vv-name="Registration Number"
                :error-messages="errors.collect('Registration Number')"
              ></v-text-field>
            </v-flex>
            <v-flex xs8>
              <FileUpload
                :id="contractorId"
                ref="refGasSafetyCertificate"
                :is-required-validation="isGasSafeRegistered"
                :uploaded-files="getUploadedFilesFor('Gas Safety Certificate')"
                label="Upload Gas Safety Certificate"
                :class="isGasSafeRegistered ? 'required' : ''"
                :upload-document-for="'Gas Safety Certificate'"
                :is-disabled="!isGasSafeRegistered"
                :show-date-time-picker="true"
                @removeSelectedAssociation="removeSelectedAssociation"
              ></FileUpload>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 pr-2 mb-2>
          <FileUpload
            :id="contractorId"
            ref="refPublicLiabilityInsurance"
            :is-required-validation="true"
            :uploaded-files="getUploadedFilesFor('Public Liability Insurance')"
            label="Upload Public Liability Insurance Documents"
            :upload-document-for="'Public Liability Insurance'"
          ></FileUpload>
        </v-flex>
        <v-flex xs6 pl-2 mb-2>
          <FileUpload
            :id="contractorId"
            ref="refEmployersLiabilityInsurance"
            :is-required-validation="
              contractor && contractor.companyType === CompanyType[CompanyType.SoleTrader] ? false : true
            "
            :upload-document-for="'Employers Liability Insurance'"
            :uploaded-files="getUploadedFilesFor('Employers Liability Insurance')"
            label="Upload Employers Liability Insurance Documents"
          ></FileUpload>
        </v-flex>
        <v-flex xs12>
          <v-autocomplete
            v-model="selectedAssociation"
            :items="membershipAssociationItems"
            label="Select Upload Documents"
            item-text="name"
            item-value="name"
            multiple
            chips
            :disabled="isUserRoleContractor"
            @input="onSelectedAssociationChange()"
          >
            <template slot="selection" slot-scope="data">
              <v-chip
                :key="JSON.stringify(data.item)"
                :selected="data.selected"
                close
                class="chip--select-multi"
                @input="onRemoveSelectedAssociation(data.item.name)"
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex v-for="item in selectedAssociation" :key="item" md12 lg6 pr-2>
          <v-layout>
            <v-flex xs12>
              <FileUpload
                :id="contractorId"
                ref="refAssociationDocuments"
                :uploaded-files="getUploadedFilesFor(item)"
                :is-required-validation="true"
                :label="`Upload ` + item + ` Documents`"
                :upload-document-for="item"
                :is-disabled="isUserRoleContractor"
                :show-date-time-picker="true"
                @removeSelectedAssociation="removeSelectedAssociation"
              ></FileUpload>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
          <h4>Number of employees for home emergency</h4>
        </v-flex>
        <v-flex xs12>
          <v-layout wrap grey lighten-3 px-2>
            <v-flex xs6>
              <v-text-field
                v-model="directEmployedHEWorker"
                v-validate="'required|numeric'"
                label="Direct Employed"
                :data-vv-scope="formRef"
                data-vv-name="Direct Employed"
                :error-messages="errors.collect('Direct Employed')"
                required
                class="required"
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="subContractedHEWorker"
                v-validate="'required|numeric'"
                label="Sub Contracted"
                :data-vv-scope="formRef"
                data-vv-name="Sub Contracted"
                :error-messages="errors.collect('Sub Contracted')"
                required
                class="required"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-checkbox
            v-model="hasLicenceToCarryWaste"
            hide-details
            color="primary"
            label="Are you licensed to carry waste?"
            @change="onChangeLicensedToCarryWaste($event)"
          ></v-checkbox>
        </v-flex>
        <v-flex xs8>
          <FileUpload
            :id="contractorId"
            ref="refLicensedToCarryWaste"
            :is-required-validation="hasLicenceToCarryWaste"
            :uploaded-files="getUploadedFilesFor('Waste Carries License Certificate')"
            label="Upload Waste Carries License Certificate"
            :upload-document-for="'Waste Carries License Certificate'"
            :is-disabled="!hasLicenceToCarryWaste"
            :show-date-time-picker="true"
            :is-date-validation-required="false"
            @removeSelectedAssociation="removeSelectedAssociation"
          ></FileUpload>
        </v-flex>
      </v-layout>
    </v-container>
    <v-divider></v-divider>
    <v-card-actions class="bottom-align">
      <v-spacer></v-spacer>
      <v-btn v-if="!isUserRoleContractor" color="primary" :disabled="loading" :loading="loading" @click="save()">
        save
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
      {{ saveSnackbarText }}
      <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
    </v-snackbar>
    <v-dialog v-if="confirmationDialog" v-model="confirmationDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="pa-3">
          <h3>{{ confirmationDialogMessage }}</h3>
        </v-card-title>
        <v-card-actions class="pt-2 px-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn small flat :disabled="isLoading" @click="onCancelDeleteDocuments">No</v-btn>
          <v-btn color="primary" small :disabled="isLoading" :loading="isLoading" @click="onConfirmDeleteDocuments">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import FileUpload from '@/components/FileUpload.vue'
import Shared from '@/common/shared'
import ContractorController from '@/api/contractorController'
import ContractorModel from '@/models/contractor/ContractorModel'
import UploadedContractorDocumentDetail from '@/models/contractor/UploadedContractorDocumentDetail'
import eventBus from '@/common/bus'
import store from '@/store'
import moment, { Moment } from 'moment'
import DateTimePicker from './DateTimePicker.vue'
import { CompanyType } from '@/common/enums'
import { logger } from '@/plugins/datadog'
import { BlobServiceClient } from '@azure/storage-blob'

interface IFile {
  fileName: string
  fileURL: File
  fileType: string
  uploadFileFor: string
  expirationDate: moment.Moment | null
}

@Component({
  components: { FileUpload },
})
export default class ContractorTradeAssociationMemberships extends Vue {
  @Prop() private contractorId: string
  @Prop() private contractor: ContractorModel | null
  @Prop() private documentDetailList: UploadedContractorDocumentDetail[]
  @Prop({ default: true }) private isContractorReviewed: boolean

  private contractorModel: ContractorModel = new ContractorModel()
  private isGasSafeRegistered = false
  private selectedAssociation: string[] = []
  private tempSelectedAssociation: string[] = []
  private formRef = 'tradeAssociationMembershipsForm'
  private directEmployedHEWorker: number | null = null
  private subContractedHEWorker: number | null = null
  private gasSafeRegistrationNumber = ''
  private loading = false
  private saveSnackbarTimeout = 3000
  private saveSnackbar = false
  private saveSnackbarText = 'Trade Association Membership Detail Saved Successfully.'
  private successfullyUploadedDocumentList: IFile[] = []
  private uploadedDocuments: IFile[] = []
  private documentDetailListLocal: UploadedContractorDocumentDetail[] = []
  private confirmationDialogMessage = ''
  private confirmationDialog = false
  private associationToRemove = ''
  private documentsToBeRemovedObj: any = {}
  private isLoading = false
  private hasLicenceToCarryWaste = false
  private confirmationDialogFor: any = null
  private isAssociationDocumentsDeleteConfirmationOpen = false
  private isGasSafeRegisteredDeleteConfirmationOpen = false
  private isLicensedToCarryWasteDeleteConfirmationOpen = false
  private updateDocumentMetadataList: IFile[] = []
  private successfullyUploadedDocumentMetadataList: IFile[] = []
  private membershipAssociationItems: any = [
    { name: 'BSI' },
    { name: 'MLA or ICL' },
    { name: 'BESCA' },
    { name: 'OFTEC' },
    { name: 'HETAS' },
    { name: 'NAPIT' },
    { name: 'STROMA' },
    { name: 'BPCA' },
    { name: 'SELECT' },
    { name: 'IOP' },
    { name: 'NICEIC or ELECSA' },
    { name: 'NFRC or CRC' },
    { name: 'CITB' },
    { name: 'APHC or CIPHE' },
    { name: 'CIOB' },
    { name: 'GGF or FENSA' },
    { name: 'OTHER' },
  ]
  private CompanyType = CompanyType

  // Check if userRole is contractor
  private get isUserRoleContractor(): boolean {
    return store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail' && this.isContractorReviewed
  }

  private mounted() {
    this.setContractorDetail()
    this.documentDetailListLocal = this.documentDetailList
    this.getSelectedAssociation()
  }

  private setContractorDetail() {
    if (this.contractor) {
      this.isGasSafeRegistered = this.contractor.isGasSafeRegistered
      this.gasSafeRegistrationNumber = this.contractor.gasSafeRegistrationNumber
      this.directEmployedHEWorker = this.contractor.directEmployedHEWorker
      this.subContractedHEWorker = this.contractor.subContractedHEWorker
      this.hasLicenceToCarryWaste = this.contractor.hasLicenceToCarryWaste
    }
  }

  private getSelectedAssociation() {
    this.documentDetailListLocal.forEach((e) => {
      const item = this.membershipAssociationItems.find(
        (a) => e.documentFor === a.name && e.contractorId === this.contractorId
      )
      if (item) {
        const index = this.selectedAssociation.findIndex((i) => i === item.name)
        if (index === -1) {
          this.selectedAssociation.push(item.name)
        }
      }
    })
    this.tempSelectedAssociation = JSON.parse(JSON.stringify(this.selectedAssociation))
  }

  private getUploadedFilesFor(type: string) {
    const documentList = this.documentDetailListLocal.filter(
      (e) => e.documentFor === type && e.contractorId === this.contractorId
    )
    return documentList.length > 0 ? documentList : []
  }

  @Watch('contractorId')
  private onContractorChange() {
    this.isGasSafeRegistered = false
    this.gasSafeRegistrationNumber = ''
    this.directEmployedHEWorker = null
    this.subContractedHEWorker = null
    this.selectedAssociation = []
    this.tempSelectedAssociation = []
    this.hasLicenceToCarryWaste = false
    this.$validator.errors.items = []
    const gasSafetyCertificate: any = this.$refs.refGasSafetyCertificate as FileUpload
    if (gasSafetyCertificate) {
      gasSafetyCertificate.fileErrorMessage = ''
    }
    const publicLiabilityInsurance: any = this.$refs.refPublicLiabilityInsurance as FileUpload
    if (publicLiabilityInsurance) {
      publicLiabilityInsurance.fileErrorMessage = ''
    }
    const employersLiabilityInsurance: any = this.$refs.refEmployersLiabilityInsurance as FileUpload
    if (employersLiabilityInsurance) {
      employersLiabilityInsurance.fileErrorMessage = ''
    }
    const associationDocumentsSub: any = this.$refs.refAssociationDocuments as FileUpload[]
    if (associationDocumentsSub) {
      associationDocumentsSub.fileErrorMessage = ''
    }
    const licensedToCarryWaste: any = this.$refs.refLicensedToCarryWaste as FileUpload
    if (licensedToCarryWaste) {
      licensedToCarryWaste.fileErrorMessage = ''
    }
    this.setContractorDetail()
    this.getSelectedAssociation()
  }

  @Watch('documentDetailList')
  private onDocumentDetailListChange(newValue: UploadedContractorDocumentDetail[]) {
    this.documentDetailListLocal = newValue
    this.getSelectedAssociation()
  }

  private async validate(): Promise<boolean> {
    const self = this
    let result = true
    result = await self.$validator.validateAll(this.formRef)

    // validate association document validation
    let associationDocumentValidation = true
    const associationDocumentsSub: any = this.$refs.refAssociationDocuments as FileUpload[]
    if (associationDocumentsSub) {
      for (const contact of associationDocumentsSub) {
        if (contact.selectedFileList.length === 0 && contact.isRequiredValidation) {
          associationDocumentValidation = false
          contact.fileValidationMessage(null, 'Please ' + contact.label)
          break
        } else if (contact.selectedFileList.length > 0 && contact.showDateTimePicker) {
          const dateTimePicker = contact.$refs.dateTimePicker as DateTimePicker
          const dateTimePickerValidation = await dateTimePicker.$validator.validateAll()
          associationDocumentValidation = dateTimePickerValidation
        }
      }
    }

    // validate public and employers liability insurance
    let isInsuranceValidate = true
    const publicLiabilityInsurance: any = this.$refs.refPublicLiabilityInsurance as FileUpload
    if (publicLiabilityInsurance) {
      if (publicLiabilityInsurance.selectedFileList.length === 0 && publicLiabilityInsurance.isRequiredValidation) {
        isInsuranceValidate = false
        publicLiabilityInsurance.fileValidationMessage(null, 'Please ' + publicLiabilityInsurance.label)
      }
    }
    const employersLiabilityInsurance: any = this.$refs.refEmployersLiabilityInsurance as FileUpload
    if (employersLiabilityInsurance) {
      if (
        employersLiabilityInsurance.selectedFileList.length === 0 &&
        employersLiabilityInsurance.isRequiredValidation
      ) {
        isInsuranceValidate = false
        employersLiabilityInsurance.fileValidationMessage(null, 'Please ' + employersLiabilityInsurance.label)
      }
    }

    // validate gas safety certificate
    let isGasSafetyValidate = true
    if (this.isGasSafeRegistered) {
      const gasSafetyCertificate: any = this.$refs.refGasSafetyCertificate as FileUpload
      if (gasSafetyCertificate) {
        if (gasSafetyCertificate.selectedFileList.length === 0 && gasSafetyCertificate.isRequiredValidation) {
          isGasSafetyValidate = false
          gasSafetyCertificate.fileValidationMessage(null, 'Please ' + gasSafetyCertificate.label)
        } else if (gasSafetyCertificate.selectedFileList.length > 0 && gasSafetyCertificate.showDateTimePicker) {
          const dateTimePicker = gasSafetyCertificate.$refs.dateTimePicker as DateTimePicker
          const dateTimePickerValidation = await dateTimePicker.$validator.validateAll()
          isGasSafetyValidate = dateTimePickerValidation
        }
      }
    }

    // validate licensed to carry waste certificate
    let hasLicenceToCarryWasteValidate = true
    if (this.hasLicenceToCarryWaste) {
      const licensedToCarryWaste: any = this.$refs.refLicensedToCarryWaste as FileUpload
      if (licensedToCarryWaste) {
        if (licensedToCarryWaste.selectedFileList.length === 0 && licensedToCarryWaste.isRequiredValidation) {
          hasLicenceToCarryWasteValidate = false
          licensedToCarryWaste.fileValidationMessage(null, 'Please ' + licensedToCarryWaste.label)
        } else if (licensedToCarryWaste.selectedFileList.length > 0 && licensedToCarryWaste.showDateTimePicker) {
          const dateTimePicker = licensedToCarryWaste.$refs.dateTimePicker as DateTimePicker
          const dateTimePickerValidation = await dateTimePicker.$validator.validateAll()
          hasLicenceToCarryWasteValidate = dateTimePickerValidation
        }
      }
    }

    if (
      !associationDocumentValidation ||
      !isInsuranceValidate ||
      !isGasSafetyValidate ||
      !hasLicenceToCarryWasteValidate
    ) {
      result =
        associationDocumentValidation && isInsuranceValidate && isGasSafetyValidate && hasLicenceToCarryWasteValidate
    }
    return result
  }

  private async uploadSingleDocument(document: IFile) {
    const guid = `${Shared.generateGuid()}.${document.fileName.split('.').at(-1)}`

    const token = await ContractorController.GetTokenToUploadContractorDocument(this.contractorId, guid)
    if (!token) {
      this.saveUploadedDocuments()
      return
    }

    try {
      const containerName = 'contractor'
      const blobUri = token.split(`/${containerName}`)[0]
      const sasToken = token.split('?')[1]
      const expirationDate = document.expirationDate
        ? document.expirationDate
        : document.uploadFileFor === 'Waste Carries License Certificate'
        ? null
        : new Date()

      const metadata = {
        documentType: document.fileType,
        documentName: document.fileName,
        documentFor: document.uploadFileFor,
      } as { [propertyName: string]: string }

      if (expirationDate) {
        metadata.documentExpirationDate = Shared.getFormatedDate(
          moment(expirationDate),
          Shared.dateFormatToSaveDocumentOnAzure()
        )
      }

      await new BlobServiceClient(`${blobUri}?${sasToken}`)
        .getContainerClient(containerName)
        .getBlockBlobClient(`${this.contractorId}/${guid}`)
        .uploadData(document.fileURL, { metadata })

      this.successfullyUploadedDocumentList.push(document)
      if (this.uploadedDocuments.length === this.successfullyUploadedDocumentList.length) {
        this.saveTradeMembershipDetails(null)
      }
    } catch (error) {
      this.saveUploadedDocuments()
      const message = `Error saving Trade Association Memberships document`
      eventBus.$emit('errorHandler', message, true)
      logger.error(message, { error, contractorId: this.contractorId })
    }
  }

  private saveUploadedDocuments() {
    if (this.successfullyUploadedDocumentList.length > 0 || this.successfullyUploadedDocumentMetadataList.length > 0) {
      this.saveTradeMembershipDetails('Error in Uploading Document!')
    } else {
      this.showErrorMessage('Error in Uploading Document!')
    }
    this.loading = false
  }

  private getToBeUploadDocumentList(refFileUploadComponent: any) {
    const files = refFileUploadComponent.selectedFileList.filter((s) => s.uploadFileFor !== '')
    files.forEach((element) => {
      element.expirationDate = refFileUploadComponent.getDateTime
      this.uploadedDocuments.push(element)
    })
  }

  private getUpdateDocumentMetadataList(refFileUploadComponent: any) {
    const uploadedFiles = JSON.parse(
      JSON.stringify(refFileUploadComponent.selectedFileList.filter((s) => s.uploadFileFor === ''))
    )
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((element) => {
        const elementDate = Shared.getFormatedDate(
          moment(element.expirationDate),
          store.Instance.state.Environment.DateFormat
        )
        const selectedDate = Shared.getFormatedDate(
          moment(refFileUploadComponent.getDateTime),
          store.Instance.state.Environment.DateFormat
        )
        if (elementDate !== selectedDate) {
          element.expirationDate = refFileUploadComponent.getDateTime
          element.uploadFileFor = refFileUploadComponent.uploadDocumentFor
          this.updateDocumentMetadataList.push(element)
        }
      })
    }
  }

  private getListofDocumentsToBeUploaded() {
    this.uploadedDocuments = []
    this.updateDocumentMetadataList = []
    const associationDocumentsSub: any = this.$refs.refAssociationDocuments as FileUpload[]
    if (associationDocumentsSub) {
      for (const sub of associationDocumentsSub) {
        if (sub.selectedFileList.length > 0) {
          this.getToBeUploadDocumentList(sub)
          this.getUpdateDocumentMetadataList(sub)
        }
      }
    }

    const publicLiabilityInsurance: any = this.$refs.refPublicLiabilityInsurance as FileUpload
    if (publicLiabilityInsurance && publicLiabilityInsurance.selectedFileList.length > 0) {
      this.getToBeUploadDocumentList(publicLiabilityInsurance)
    }
    const employersLiabilityInsurance: any = this.$refs.refEmployersLiabilityInsurance as FileUpload
    if (employersLiabilityInsurance && employersLiabilityInsurance.selectedFileList.length > 0) {
      this.getToBeUploadDocumentList(employersLiabilityInsurance)
    }

    const gasSafetyCertificate: any = this.$refs.refGasSafetyCertificate as FileUpload
    if (gasSafetyCertificate && gasSafetyCertificate.selectedFileList.length > 0) {
      this.getToBeUploadDocumentList(gasSafetyCertificate)
      this.getUpdateDocumentMetadataList(gasSafetyCertificate)
    }

    const licensedToCarryWaste: any = this.$refs.refLicensedToCarryWaste as FileUpload
    if (licensedToCarryWaste && licensedToCarryWaste.selectedFileList.length > 0) {
      this.getToBeUploadDocumentList(licensedToCarryWaste)
      this.getUpdateDocumentMetadataList(licensedToCarryWaste)
    }
  }

  private showErrorMessage(error: string) {
    // show error message
    if (error) {
      eventBus.$emit('errorHandler', error, true)
    }
  }

  private save() {
    this.validate().then((result) => {
      if (result) {
        this.loading = true
        // upload documents to azure and save details
        this.getListofDocumentsToBeUploaded()
        if (this.uploadedDocuments.length === 0 && this.updateDocumentMetadataList.length === 0) {
          this.saveTradeMembershipDetails(null)
        } else {
          if (this.updateDocumentMetadataList.length > 0) {
            this.updateDocumentMetadataList.forEach((document) => {
              this.updateDocumentMetadata(document)
            })
          }
          if (this.uploadedDocuments.length > 0) {
            this.uploadedDocuments.forEach((document) => {
              this.uploadSingleDocument(document)
            })
          }
        }
      }
    })
  }

  private async updateDocumentMetadata(document: IFile) {
    try {
      const containerName = 'contractor'
      const blobUri = String(document.fileURL).split(`/${containerName}`)[0]
      const sasToken = String(document.fileURL).split('?')[1]
      const blobName = String(document.fileURL).split('?')[0].split(`${containerName}/`)[1]
      const expirationDate = document.expirationDate ? document.expirationDate : new Date()

      await new BlobServiceClient(`${blobUri}?${sasToken}`)
        .getContainerClient(containerName)
        .getBlockBlobClient(blobName)
        .setMetadata({
          documentType: document.fileType,
          documentName: document.fileName,
          documentFor: document.uploadFileFor,
          documentExpirationDate: Shared.getFormatedDate(
            moment(expirationDate),
            Shared.dateFormatToSaveDocumentOnAzure()
          ),
        })

      this.successfullyUploadedDocumentMetadataList.push(document)
      if (
        this.updateDocumentMetadataList.length === this.successfullyUploadedDocumentMetadataList.length &&
        this.uploadedDocuments.length === 0
      ) {
        this.saveTradeMembershipDetails(null)
      }
    } catch (error) {
      this.saveUploadedDocuments()
      const message = `Error updating Trade Association Memberships document metadata`
      eventBus.$emit('errorHandler', message, true)
      logger.error(message, { error, contractorId: this.contractorId })
    }
  }

  private saveTradeMembershipDetails(error: string | null) {
    const contractor: ContractorModel = new ContractorModel()
    contractor.id = this.contractorId
    contractor.isGasSafeRegistered = this.isGasSafeRegistered
    contractor.gasSafeRegistrationNumber = this.gasSafeRegistrationNumber
    contractor.directEmployedHEWorker = this.directEmployedHEWorker
    contractor.subContractedHEWorker = this.subContractedHEWorker
    contractor.hasLicenceToCarryWaste = this.hasLicenceToCarryWaste
    ContractorController.SaveTradeMembershipDetails(contractor)
      .then((res: boolean) => {
        if (res) {
          this.successfullyUploadedDocumentList = []
          this.successfullyUploadedDocumentMetadataList = []
          if (error !== null) {
            this.showErrorMessage(error)
          }
          this.saveSnackbar = true
          this.$emit(
            'onSaveTradeMembershipDetails',
            contractor,
            this.uploadedDocuments.length > 0 || this.updateDocumentMetadataList.length > 0 ? true : false
          )
        }
        this.loading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error subitting trade membership detail, please try again', true)
        this.loading = false
      })
  }

  private onSelectedAssociationChange() {
    if (this.tempSelectedAssociation.length > this.selectedAssociation.length) {
      const unSelectedAssociation = this.tempSelectedAssociation.filter(
        (x) => !(this.selectedAssociation as any).includes(x)
      )[0]
      this.onRemoveSelectedAssociation(unSelectedAssociation)
    } else {
      const selectedAssociation = this.selectedAssociation.filter(
        (x) => !(this.tempSelectedAssociation as any).includes(x)
      )[0]
      this.tempSelectedAssociation.push(selectedAssociation)
    }
  }

  private onRemoveSelectedAssociation(item: string) {
    this.associationToRemove = item
    const uploadedDocs = this.getUploadedFilesFor(item)
    if (uploadedDocs.length > 0) {
      this.isAssociationDocumentsDeleteConfirmationOpen = true
      const data = { item, uploadedDocs }
      this.documentsToBeRemovedObj = data
      this.confirmationDialogFor = this.$refs.refAssociationDocuments as FileUpload[]
      this.confirmationDialogMessage = 'Are you sure you want to delete all documents of ' + item + ' ?'
      this.confirmationDialog = true
    } else {
      this.removeSelectedAssociation(item)
    }
  }

  private onConfirmDeleteDocuments() {
    this.isLoading = true
    const self = this
    if (!self.confirmationDialogFor) {
      return
    }
    let counter = 0
    let isLastDocument = false
    self.documentsToBeRemovedObj.uploadedDocs.forEach((file) => {
      counter++
      const fileSelected: any = {}
      fileSelected.fileName = file.documentName
      fileSelected.fileURL = file.documentUrl
      fileSelected.fileType = file.documentType
      fileSelected.uploadFileFor = file.documentFor
      fileSelected.expirationDate = file.documentExpirationDate
      isLastDocument = counter === self.documentsToBeRemovedObj.uploadedDocs.length ? true : false
      if (this.isAssociationDocumentsDeleteConfirmationOpen) {
        self.confirmationDialogFor[0].deleteUploadedFile(fileSelected, isLastDocument, self.documentsToBeRemovedObj)
      } else {
        self.confirmationDialogFor.deleteUploadedFile(fileSelected, isLastDocument, self.documentsToBeRemovedObj)
      }
    })
  }

  private onCancelDeleteDocuments() {
    this.confirmationDialog = false
    this.confirmationDialogMessage = ''
    if (this.isGasSafeRegisteredDeleteConfirmationOpen) {
      this.isGasSafeRegistered = true
    } else if (this.isLicensedToCarryWasteDeleteConfirmationOpen) {
      this.hasLicenceToCarryWaste = true
    } else {
      const index = this.selectedAssociation.findIndex((e) => e === this.associationToRemove)
      if (index === -1) {
        this.selectedAssociation.push(this.associationToRemove)
        this.tempSelectedAssociation = JSON.parse(JSON.stringify(this.selectedAssociation))
      }
    }
  }

  private removeSelectedAssociation(item: string) {
    this.isLoading = false
    this.confirmationDialog = false
    if (this.isGasSafeRegisteredDeleteConfirmationOpen) {
      this.resetGasSafeRegistered()
    } else if (this.isLicensedToCarryWasteDeleteConfirmationOpen) {
      this.resetLicensedToCarryWaste()
    } else {
      this.selectedAssociation = []
      this.selectedAssociation = this.tempSelectedAssociation.filter((association) => association !== item)
      this.tempSelectedAssociation = JSON.parse(JSON.stringify(this.selectedAssociation))
    }
  }

  private get getValidationRule() {
    if (this.isGasSafeRegistered) {
      return 'required|numeric'
    }
    return 'numeric'
  }

  private onChangeGasSafeRegistered(isChecked: boolean) {
    if (!isChecked) {
      const gasSafetyCertificateText = 'Gas Safety Certificate'
      const uploadedDocs = this.getUploadedFilesFor(gasSafetyCertificateText)
      if (uploadedDocs.length > 0) {
        this.isGasSafeRegisteredDeleteConfirmationOpen = true
        const data = { gasSafetyCertificateText, uploadedDocs }
        this.documentsToBeRemovedObj = data
        this.confirmationDialogFor = this.$refs.refGasSafetyCertificate as FileUpload
        this.confirmationDialogMessage =
          'Are you sure you want to delete all documents and details of Gas Safety Certificate ?'
        this.confirmationDialog = true
      } else {
        this.resetGasSafeRegistered()
      }
    }
  }

  private resetGasSafeRegistered() {
    this.isGasSafeRegistered = false
    this.gasSafeRegistrationNumber = ''
    const gasSafetyCertificate = this.$refs.refGasSafetyCertificate as FileUpload
    if (gasSafetyCertificate) {
      gasSafetyCertificate.removeUnUploadedFiles('Gas Safety Certificate')
    }
  }

  private onChangeLicensedToCarryWaste(isChecked: boolean) {
    if (!isChecked) {
      const licensedToCarryWasteCertificateText = 'Waste Carries License Certificate'
      const uploadedDocs = this.getUploadedFilesFor(licensedToCarryWasteCertificateText)
      if (uploadedDocs.length > 0) {
        this.isLicensedToCarryWasteDeleteConfirmationOpen = true
        const data = { licensedToCarryWasteCertificateText, uploadedDocs }
        this.documentsToBeRemovedObj = data
        this.confirmationDialogFor = this.$refs.refLicensedToCarryWaste as FileUpload
        this.confirmationDialogMessage =
          'Are you sure you want to delete all documents of Waste Carries License Certificate ?'
        this.confirmationDialog = true
      } else {
        this.resetLicensedToCarryWaste()
      }
    }
  }

  private resetLicensedToCarryWaste() {
    this.hasLicenceToCarryWaste = false
    const licensedToCarryWaste = this.$refs.refLicensedToCarryWaste as FileUpload
    if (licensedToCarryWaste) {
      licensedToCarryWaste.removeUnUploadedFiles('Waste Carries License Certificate')
    }
  }
}
</script>
