import { render, staticRenderFns } from "./EmergencyQuestionList.vue?vue&type=template&id=2566d813&scoped=true"
import script from "./EmergencyQuestionList.vue?vue&type=script&lang=ts"
export * from "./EmergencyQuestionList.vue?vue&type=script&lang=ts"
import style0 from "./EmergencyQuestionList.vue?vue&type=style&index=0&id=2566d813&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2566d813",
  null
  
)

export default component.exports