import { onSessionChanged } from '@/plugins/datadog'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import SessionController from '@/api/sessionController'
import { UserSession, Permissions } from '@/models/session/session'

export const useSessionStore = defineStore('user', () => {
  const session = ref<UserSession | null>(null)

  async function setToken(token: string | null) {
    if (!token) {
      session.value = null
      return
    }

    const apiSession = await SessionController.GetSession(token)
    session.value = {
      userType: apiSession.userType,
      userId: apiSession.userId,
      userName: apiSession.userName,
      email: apiSession.email,
      applications: apiSession.applications,

      jobTypes: apiSession.claims.JobType ?? [],
      permissions: apiSession.permissions,
      roles: apiSession.roles,
      clientId: apiSession.claims.ClientId?.[0] ?? null,
      contractorId: apiSession.claims.ContractorId?.[0] || null,
    }
    onSessionChanged(session.value)
  }

  return {
    setToken,
    hasPermission(permission: Permissions) {
      return !!session.value?.permissions.includes(permission)
    },
    isLoaded: computed(() => !!session.value),
    session,
  }
})
