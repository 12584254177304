<template>
  <v-form>
    <v-container fluid class="pa-0 grid-list-md">
      <v-layout wrap>
        <v-flex xs6>
          <v-text-field
            v-model="healthSafetyWellbeingEnvironmentModel.name"
            v-validate="'required|alpha_num'"
            label="Who is responsible for Health and Safety?"
            required
            class="required"
            :data-vv-scope="formRef"
            data-vv-name="Responsible Name"
            :error-messages="errors.collect('Responsible Name')"
          ></v-text-field>
        </v-flex>
        <v-flex xs6>
          <v-autocomplete
            v-model="healthSafetyWellbeingEnvironmentModel.position"
            v-validate="'required'"
            :items="positionList"
            label="Select Position"
            item-value="description"
            item-text="description"
            :loading="isLoadingPosition"
            required
            class="required position-list"
            :data-vv-scope="formRef"
            data-vv-name="Postion"
            :error-messages="errors.collect('Postion')"
            @change="OnPositionChange($event)"
          >
            <template slot="item" slot-scope="data">
              <v-list-tile-content>
                <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                  {{ data.item.description }}
                </v-list-tile-title>
              </v-list-tile-content>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex xs12>
          <v-tooltip top nudge-bottom="30">
            <FileUpload
              :id="contractorId"
              slot="activator"
              ref="refRiskAssessmentBehalfOfCET"
              :is-required-validation="true"
              :uploaded-files="getUploadedFilesFor('Risk Assessment')"
              label="Upload documents of risk assessment for work you propose to undertake on behalf of CET"
              :upload-document-for="'Risk Assessment'"
              :is-disabled="isUserRoleContractor"
            ></FileUpload>
            <span>Upload documents of risk assessment for work you propose to undertake on behalf of CET</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs12>
          <v-tooltip top nudge-bottom="30">
            <FileUpload
              :id="contractorId"
              slot="activator"
              ref="refSafeMethodsBehalfOfCET"
              :is-required-validation="true"
              :uploaded-files="getUploadedFilesFor('Safe methods of work')"
              label="Upload documents of safe methods of work for work you propose to undertake on behalf of CET"
              :upload-document-for="'Safe methods of work'"
              :is-disabled="isUserRoleContractor"
            ></FileUpload>
            <span>Upload documents of safe methods of work for work you propose to undertake on behalf of CET</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
          <h3 class="primary--text">Are Your workforce trained in the following Health & Safety topics?</h3>
        </v-flex>
        <v-flex xs12 grey lighten-3 mt-1 px-2>
          <v-layout wrap>
            <v-flex xs8>
              <b class="hs-que">Manual Handling Awareness</b>
              &nbsp;
            </v-flex>
            <v-flex xs4>
              <v-radio-group
                v-model="healthSafetyWellbeingEnvironmentModel.manualHandlingAwareness"
                v-validate="'required'"
                hide-details
                class="d-inline-block"
                row
                :data-vv-scope="formRef"
                data-vv-name="Manual Handling Awareness"
                :error-messages="errors.collect('Manual Handling Awareness')"
              >
                <v-radio label="YES" color="secondary" :value="true"></v-radio>
                <v-radio label="NO" color="secondary" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 grey lighten-3 mt-1 px-2>
          <v-layout wrap>
            <v-flex xs8>
              <b class="hs-que">Asbestos Awareness</b>
              &nbsp;
            </v-flex>
            <v-flex xs4>
              <v-radio-group
                v-model="healthSafetyWellbeingEnvironmentModel.asbestosAwareness"
                v-validate="'required'"
                hide-details
                class="d-inline-block"
                row
                :data-vv-scope="formRef"
                data-vv-name="Asbestos Awareness"
                :error-messages="errors.collect('Asbestos Awareness')"
              >
                <v-radio label="YES" color="secondary" :value="true"></v-radio>
                <v-radio label="NO" color="secondary" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 grey lighten-3 mt-1 px-2>
          <v-layout wrap>
            <v-flex xs8>
              <b class="hs-que">Work at Height</b>
              &nbsp;
            </v-flex>
            <v-flex xs4>
              <v-radio-group
                v-model="healthSafetyWellbeingEnvironmentModel.workAtHeight"
                v-validate="'required'"
                hide-details
                class="d-inline-block"
                row
                :data-vv-scope="formRef"
                data-vv-name="Work at Height"
                :error-messages="errors.collect('Work at Height')"
              >
                <v-radio label="YES" color="secondary" :value="true"></v-radio>
                <v-radio label="NO" color="secondary" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 grey lighten-3 mt-1 px-2>
          <v-layout wrap>
            <v-flex xs8>
              <b class="hs-que">Slips, Trips & Falls Awareness</b>
              &nbsp;
            </v-flex>
            <v-flex xs4>
              <v-radio-group
                v-model="healthSafetyWellbeingEnvironmentModel.slipsTripsFallsAwareness"
                v-validate="'required'"
                hide-details
                class="d-inline-block"
                row
                :data-vv-scope="formRef"
                data-vv-name="Slips, Trips, Falls Awareness"
                :error-messages="errors.collect('Slips, Trips, Falls Awareness')"
              >
                <v-radio label="YES" color="secondary" :value="true"></v-radio>
                <v-radio label="NO" color="secondary" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 grey lighten-3 mt-1 px-2>
          <v-layout wrap>
            <v-flex xs8>
              <b class="hs-que">
                Do you provide your workforce with any form of Health Surveillance or Health Monitoring?
              </b>
              &nbsp;
            </v-flex>
            <v-flex xs4>
              <v-radio-group
                v-model="healthSafetyWellbeingEnvironmentModel.provideHealthSurveillanceOrMonitoring"
                v-validate="'required'"
                hide-details
                class="d-inline-block"
                row
                :data-vv-scope="formRef"
                data-vv-name="Provide Health Surveillance Or Monitoring"
                :error-messages="errors.collect('Provide Health Surveillance Or Monitoring')"
              >
                <v-radio label="YES" color="secondary" :value="true"></v-radio>
                <v-radio label="NO" color="secondary" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 grey lighten-3 mt-1 px-2>
          <v-layout wrap>
            <v-flex xs8>
              <b class="hs-que">Have you had any RIDDOR reportable incidents in the past three years?</b>
              &nbsp;
            </v-flex>
            <v-flex xs4>
              <v-radio-group
                v-model="healthSafetyWellbeingEnvironmentModel.riddorReportableIncidents"
                v-validate="'required'"
                hide-details
                class="d-inline-block"
                row
                :data-vv-scope="formRef"
                data-vv-name="Riddor Reportable Incidents"
                :error-messages="errors.collect('Riddor Reportable Incidents')"
                name="riddorReportableIncidents"
              >
                <v-radio label="YES" color="secondary" :value="true"></v-radio>
                <v-radio label="NO" color="secondary" :value="false"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-textarea
            v-if="healthSafetyWellbeingEnvironmentModel.riddorReportableIncidents"
            v-model="healthSafetyWellbeingEnvironmentModel.riddorReportableIncidentsMoreInfo"
            v-validate="'required'"
            label="More Info"
            required
            class="required"
            :data-vv-scope="formRef"
            data-vv-name="More Info"
            name="moreInfo"
            :error-messages="errors.collect('More Info')"
          ></v-textarea>
        </v-flex>
      </v-layout>
    </v-container>
    <v-divider class="mt-2"></v-divider>
    <v-card-actions class="bottom-align">
      <v-spacer></v-spacer>
      <v-btn
        v-if="!isUserRoleContractor"
        class="btn-save"
        color="primary"
        :disabled="loading"
        :loading="loading"
        @click="save()"
      >
        save
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="saveSnackbar" :timeout="saveSnackbarTimeout" :bottom="true" :left="true">
      {{ saveSnackbarText }}
      <v-btn flat color="secondary" @click.native="saveSnackbar = false">Close</v-btn>
    </v-snackbar>

    <!-- Configure dialog -->
    <v-dialog
      v-if="configureDropdownDialog"
      v-model="configureDropdownDialog"
      max-width="820"
      persistent
      content-class="v-dialog--scrollable"
    >
      <ConfigureDropdown
        ref="configureDropdown"
        :record-type="configureDropdownType"
        @CloseConfigureDropdownDialog="onConfigureDropdownDialog"
      ></ConfigureDropdown>
    </v-dialog>
  </v-form>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import FileUpload from '@/components/FileUpload.vue'
import HealthSafetyWellbeingEnvironment from '@/models/contractor/HealthSafetyWellbeingEnvironment'
import UploadedContractorDocumentDetail from '@/models/contractor/UploadedContractorDocumentDetail'
import ContractorController from '@/api/contractorController'
import eventBus from '@/common/bus'
import Shared from '@/common/shared'
import store from '@/store'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import MasterRecord from '../models/MasterRecord'
import MasterRecordController from '../api/masterRecordController'
import { logger } from '@/plugins/datadog'
import { BlobServiceClient } from '@azure/storage-blob'

interface IFile {
  fileName: string
  fileURL: File
  fileType: string
  uploadFileFor: string
}

@Component({
  components: { FileUpload, ConfigureDropdown },
})
export default class ContractorHSAndWellbeingEnvironment extends Vue {
  @Prop() private contractorId: string
  @Prop() private documentDetailList: UploadedContractorDocumentDetail[]
  @Prop()
  private healthSafetyWellbeingEnvironment: HealthSafetyWellbeingEnvironment | null
  @Prop({ default: true }) private isContractorReviewed: boolean
  private successfullyUploadedDocumentList: IFile[] = []
  private uploadedDocuments: IFile[] = []
  private loading = false
  private saveSnackbarTimeout = 3000
  private saveSnackbar = false
  private saveSnackbarText = 'Health, Safety, Wellbeing and Environment Detail Saved Successfully.'
  private healthSafetyWellbeingEnvironmentModel: HealthSafetyWellbeingEnvironment =
    new HealthSafetyWellbeingEnvironment()
  private documentDetailListLocal: UploadedContractorDocumentDetail[] = []
  private configureDropdownDialog = false
  private configureDropdownType = ''
  private positionList: MasterRecord[] = []
  private isLoadingPosition = false
  private formRef = 'hsAndWellbeingEnvironmentForm'

  private created() {
    // get position list
    this.GetPositions()
  }

  // Check if userRole is contractor
  private get isUserRoleContractor(): boolean {
    return store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail' && this.isContractorReviewed
  }

  // Check if userRole is Administrartor.
  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  @Watch('contractorId')
  private onContractorChange() {
    this.healthSafetyWellbeingEnvironmentModel = new HealthSafetyWellbeingEnvironment()
    if (this.healthSafetyWellbeingEnvironment) {
      this.healthSafetyWellbeingEnvironmentModel = this.healthSafetyWellbeingEnvironment
    }
    const riskAssessmentBehalfOfCET: any = this.$refs.refRiskAssessmentBehalfOfCET as FileUpload
    if (riskAssessmentBehalfOfCET) {
      riskAssessmentBehalfOfCET.fileErrorMessage = ''
    }
    const safeMethodsBehalfOfCET: any = this.$refs.refSafeMethodsBehalfOfCET as FileUpload
    if (safeMethodsBehalfOfCET) {
      safeMethodsBehalfOfCET.fileErrorMessage = ''
    }
    setTimeout(() => {
      this.$validator.errors.items = []
    }, 0)
  }

  @Watch('documentDetailList')
  private onDocumentDetailListChange(newValue: UploadedContractorDocumentDetail[]) {
    this.documentDetailListLocal = newValue
  }

  private mounted() {
    if (this.healthSafetyWellbeingEnvironment) {
      this.healthSafetyWellbeingEnvironmentModel = this.healthSafetyWellbeingEnvironment
    }
    this.documentDetailListLocal = this.documentDetailList
  }

  private getUploadedFilesFor(type: string) {
    const documentList = this.documentDetailListLocal.filter(
      (e) => e.documentFor === type && e.contractorId === this.contractorId
    )
    return documentList.length > 0 ? documentList : []
  }

  private getListofDocumentsToBeUploaded() {
    this.uploadedDocuments = []
    const riskAssessmentBehalfOfCETSub: any = this.$refs.refRiskAssessmentBehalfOfCET as FileUpload
    if (riskAssessmentBehalfOfCETSub && riskAssessmentBehalfOfCETSub.selectedFileList.length > 0) {
      riskAssessmentBehalfOfCETSub.selectedFileList
        .filter((s) => s.uploadFileFor !== '')
        .map((file) => {
          this.uploadedDocuments.push(file)
        })
    }
    const safeMethodsBehalfOfCETSub: any = this.$refs.refSafeMethodsBehalfOfCET as FileUpload
    if (safeMethodsBehalfOfCETSub && safeMethodsBehalfOfCETSub.selectedFileList.length > 0) {
      safeMethodsBehalfOfCETSub.selectedFileList
        .filter((s) => s.uploadFileFor !== '')
        .map((file) => {
          this.uploadedDocuments.push(file)
        })
    }
  }

  private async uploadSingleDocument(document: IFile) {
    const blobName = `${Shared.generateGuid()}.${document.fileName.split('.').at(-1)}`

    const token = await ContractorController.GetTokenToUploadContractorDocument(this.contractorId, blobName)
    if (!token) {
      this.saveUploadedDocuments()
      return
    }

    try {
      const containerName = 'contractor'
      const blobUri = token.split(`/${containerName}`)[0]
      const sasToken = token.split('?')[1]

      await new BlobServiceClient(`${blobUri}?${sasToken}`)
        .getContainerClient(containerName)
        .getBlockBlobClient(`${this.contractorId}/${blobName}`)
        .uploadData(document.fileURL, {
          metadata: {
            documentType: document.fileType,
            documentName: document.fileName,
            documentFor: document.uploadFileFor,
          },
        })

      this.successfullyUploadedDocumentList.push(document)
      if (this.uploadedDocuments.length === this.successfullyUploadedDocumentList.length) {
        this.saveHealthSafetyWellbeingEnvironment(null)
      }
    } catch (error) {
      this.saveUploadedDocuments()
      const message = `Error saving Health, Safety, Wellbeing and Environment document`
      eventBus.$emit('errorHandler', message, true)
      logger.error(message, { error, contractorId: this.contractorId })
    }
  }

  private saveUploadedDocuments() {
    if (this.successfullyUploadedDocumentList.length > 0) {
      this.saveHealthSafetyWellbeingEnvironment('Error in Uploading Document!')
    } else {
      this.showErrorMessage('Error in Uploading Document!')
    }
    this.loading = false
  }

  private save() {
    this.validate().then((result) => {
      if (result) {
        this.loading = true
        // upload documents to azure and save details
        this.getListofDocumentsToBeUploaded()
        if (this.uploadedDocuments.length > 0) {
          this.uploadedDocuments.forEach((document) => {
            this.uploadSingleDocument(document)
          })
        } else {
          this.saveHealthSafetyWellbeingEnvironment(null)
        }
      }
    })
  }

  private async validate(): Promise<boolean> {
    const self = this
    let result = true
    result = await self.$validator.validateAll(this.formRef)

    let isHSValidated = true
    const riskAssessmentBehalfOfCET: any = this.$refs.refRiskAssessmentBehalfOfCET as FileUpload
    if (riskAssessmentBehalfOfCET) {
      if (riskAssessmentBehalfOfCET.selectedFileList.length === 0 && riskAssessmentBehalfOfCET.isRequiredValidation) {
        isHSValidated = false
        riskAssessmentBehalfOfCET.fileValidationMessage(null, 'Please ' + riskAssessmentBehalfOfCET.label)
      }
    }
    const safeMethodsBehalfOfCET: any = this.$refs.refSafeMethodsBehalfOfCET as FileUpload
    if (safeMethodsBehalfOfCET) {
      if (safeMethodsBehalfOfCET.selectedFileList.length === 0 && safeMethodsBehalfOfCET.isRequiredValidation) {
        isHSValidated = false
        safeMethodsBehalfOfCET.fileValidationMessage(null, 'Please ' + safeMethodsBehalfOfCET.label)
      }
    }

    return result && isHSValidated
  }

  private showErrorMessage(error: string) {
    // show error message
    if (error) {
      eventBus.$emit('errorHandler', error, true)
    }
  }

  private saveHealthSafetyWellbeingEnvironment(error: string | null) {
    if (
      this.healthSafetyWellbeingEnvironmentModel &&
      !this.healthSafetyWellbeingEnvironmentModel.riddorReportableIncidents
    ) {
      this.healthSafetyWellbeingEnvironmentModel.riddorReportableIncidentsMoreInfo = ''
    }
    ContractorController.SaveHealthSafetyWellbeingEnvironment(
      this.contractorId,
      this.healthSafetyWellbeingEnvironmentModel
    )
      .then((res: boolean) => {
        if (res) {
          this.successfullyUploadedDocumentList = []
          if (error !== null) {
            this.showErrorMessage(error)
          }
          this.saveSnackbar = true
          this.saveSnackbarText = 'Health, Safety, Wellbeing and Environment Detail Saved Successfully.'
          this.$emit(
            'onSaveHealthSafetyWellbeingEnvironment',
            this.healthSafetyWellbeingEnvironmentModel,
            this.uploadedDocuments.length > 0 ? true : false
          )
        }
        this.loading = false
      })
      .catch((err: any) => {
        eventBus.$emit(
          'errorHandler',
          'Error saving Health, Safety, Wellbeing and Environment Detail, please try again',
          true
        )
        this.loading = false
      })
  }

  private OnPositionChange(item: any) {
    if (item === 'Configure' && this.isUserRoleAdministrator) {
      const element: any = document.getElementsByClassName('application')
      if (element && element.length > 0) {
        element[0].classList.add('enquiry-category-dialog')
      }
      this.configureDropdownType = 'Position'
      this.configureDropdownDialog = true
    }
  }

  private GetPositions() {
    // Get all categories and add one Item for Configuration
    const self = this
    self.isLoadingPosition = true
    self.configureDropdownType = 'Position'
    MasterRecordController.GetMasterRecords(this.configureDropdownType)
      .then((res: MasterRecord[]) => {
        if (res) {
          this.positionList = res.filter((e) => e.isDeleted === false)
          if (this.isUserRoleAdministrator) {
            const position: any = []
            position.description = 'Configure'
            self.positionList.push(position)
          }
          self.isLoadingPosition = false
        }
      })
      .catch((err: any) => {
        self.isLoadingPosition = false
        eventBus.$emit('errorHandler', 'Error loading Position list, please try again', true)
      })
  }

  private onConfigureDropdownDialog(items: MasterRecord[]) {
    // update position items
    this.configureDropdownDialog = false
    this.configureDropdownType = ''
    this.healthSafetyWellbeingEnvironmentModel.position = ''
    this.positionList = items.slice(0)
    const position: any = []
    position.description = 'Configure'
    this.positionList.push(position)
  }
}
</script>

<style scoped>
.bold-select {
  font-weight: 600;
}
</style>
