<template>
  <div id="app" :class="[getClassName, openJobView ? 'show-jobs' : '']">
    <div v-if="isShowOverlayImage" class="overlay-images"></div>
    <v-app id="inspire">
      <div v-if="isOnline">
        <v-navigation-drawer
          v-if="!jobIdToShowInHeader"
          v-model="showNavDrawer"
          fixed
          :clipped="$vuetify.breakpoint.mdAndUp"
          app
          temporary
          class="side-menubar"
        >
          <v-list>
            <template v-if="isLoggedIn && showMenuIcon">
              <SidedrawMenu @menuItemClick="setNavDrawerl" />
            </template>
            <v-divider v-if="isLoggedIn"></v-divider>
            <v-list-tile v-if="isLoggedIn" @click="logout">
              <v-list-tile-action>
                <v-icon>exit_to_app</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Logout</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-navigation-drawer>

        <v-toolbar
          app
          :clipped-left="$vuetify.breakpoint.mdAndUp"
          fixed
          :color="isOffline ? 'grey darken-2' : 'primary'"
          dark
          class="top-menubar"
        >
          <v-toolbar-title class="ml-0">
            <v-toolbar-side-icon
              v-if="isLoggedIn && showMenuIcon && !isClientUserLoggedIn && !jobIdToShowInHeader"
              @click.stop="setNavDrawerl"
            ></v-toolbar-side-icon>
            <JobWarnings v-if="showWarning && jobId" :job-id="jobId"></JobWarnings>
            <span v-if="showJobAddress" class="hidden-sm-and-down">
              {{ jobAddress }}
            </span>
            <span v-else-if="showDashBoardName !== ''" class="hidden-sm-and-down">
              <span v-if="!jobIdToShowInHeader">{{ showDashBoardName }}</span>
              <span v-else>Job Id: {{ jobIdToShowInHeader }}</span>
            </span>
            <span v-else class="hidden-sm-and-down">SIMPLIFi</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <UniversalSearch v-if="isLoggedIn && !jobIdToShowInHeader" :parent-component="'App'" />
          <Notification v-if="isLoggedIn" :notification-snackbar.sync="notificationSnackbar"></Notification>
          <div v-if="addSpace" class="menu-spacer"></div>

          <TwilioClientV2
            v-show="!showLiveLocation"
            v-if="dialpadV2Enabled && isLoggedIn && isUserLoggedIn"
            @showTwilioSnackbar="showTwilioSnackbar"
            @clearTwilioSnackbar="clearTwilioSnackbar"
            @handleStatusUpdate="handleStatusUpdate"
          ></TwilioClientV2>
          <TwilioClient
            v-show="!showLiveLocation"
            v-if="!dialpadV2Enabled && isLoggedIn && isUserLoggedIn"
            @showTwilioSnackbar="showTwilioSnackbar"
            @clearTwilioSnackbar="clearTwilioSnackbar"
            @handleStatusUpdate="handleStatusUpdate"
          ></TwilioClient>

          <AppSwitcher v-if="isLoggedIn" />
          <UserMenu
            v-if="isLoggedIn"
            :image-snackbar.sync="imageSnackbar"
            :image-snackbar-text.sync="imageSnackbarText"
          />
        </v-toolbar>
      </div>
      <div v-else v-show="userRoleDescription" class="offline-header"></div>
      <v-content>
        <v-container fluid fill-height>
          <v-layout>
            <v-flex xs12 :class="isOffline ? 'offline-card-wrap' : ''">
              <v-fade-transition
                v-if="isOnline && !isAppSettingsError"
                v-bar
                mode="out-in"
                :class="openJobView ? 'hide-dashboard' : 'show-dashboard'"
              >
                <router-view :key="$route.fullPath" />
              </v-fade-transition>
              <v-snackbar v-model="isAppSettingsError" :multi-line="true" :color="'red'" :timeout="0" bottom>
                <span>{{ appSettingsErrorMsg }}</span>
              </v-snackbar>
              <v-card v-if="isOffline" class="elevation-0 offline-card">
                <v-layout class="d-flex justify-center align-center">
                  <v-flex>
                    <img src="/img/error-img.jpeg" alt="cloud image" />
                  </v-flex>
                  <v-flex ml-5 class="grey--text text--darken-2">
                    <h3 class="display-2 mb-4 font-weight-bold grey--text text--darken-2">No Internet</h3>
                    <p>The service is currently offline.</p>
                    <p>Try:</p>
                    <ul>
                      <li>Checking the network cables, modem, and router</li>
                      <li>Reconnecting to Wi-Fi</li>
                      <li>Reconnecting network diagnostics</li>
                    </ul>
                    <p></p>
                    <p>
                      <u>Want to add jobs in offline mode?</u>
                    </p>
                    <ul>
                      <li>Referesh the Page</li>
                      <li>Add jobs in offline mode</li>
                      <li>Once network service is available, you will get option to publish these jobs to server</li>
                    </ul>
                  </v-flex>
                </v-layout>
              </v-card>
              <PartialJobView
                class="back-btn"
                btn-text="Back to Wizard"
                :job-id="selectedJobIdToExpand"
                @closeJobView="closeJobView"
              ></PartialJobView>
              <FloatingAddJobButton v-if="showFloatingButton" :offline-d-b="offlineDB" />
            </v-flex>
            <ConnectionStatusBar v-if="showConnectionStatusSnackBar" />
          </v-layout>
        </v-container>
      </v-content>
      <Confirmation></Confirmation>
      <Snackbar></Snackbar>
      <v-snackbar v-model="twilioSnackbar" :timeout="0" bottom left>
        {{ twilioSnackbarText }}
        &nbsp;
        <Counter ref="twilioSnackbarCounter"></Counter>
        <v-btn flat color="secondary" @click.native="clearTwilioSnackbar">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="errorSnackbar"
        absolute
        :multi-line="true"
        class="mt-5"
        auto-height
        :color="snackbarColor ? snackbarColor + 'lighten-2' : 'red lighten-2'"
        top
        center
      >
        {{ errorSnackbarText }}
        <v-btn color="white" flat @click="clearErrorSnackbar">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>
      <v-snackbar v-model="statusUpdateSnackbar" :multi-line="true" :timeout="0" bottom left color="red">
        You have been logged out of the queue!
        <v-btn color="white" flat @click="reconnectWorker">
          Reconnect
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-snackbar>
      <v-snackbar v-model="clockInSnackbar" :timeout="6000" bottom left>
        {{ clockInSnackbarText }}
        <v-btn flat color="secondary" @click="clearClockInSnackbar">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>
      <GlobalNotificationsHandler v-if="haveAccessToken" @newAppVersionFound="refreshAppSnackbar = true" />

      <iframe v-if="logoutUrl" class="hide" :src="logoutUrl" height="5" width="5" />

      <v-dialog v-model="showInactivityConfirmation" max-width="600" content-class="info-dialog" persistent>
        <v-card>
          <v-card-title class="">
            <h3 class="grey--text text--darken-3">Session Timeout</h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mb-0 sessionOutText">
              You are being logged out automatically due to inactivity. Please log in again.
            </p>
            <p class="mb-0 sessionOutText">
              <b>
                You will be automatically logged out in
                <span>( {{ getAutoLogoutTimeLeft }} )</span>
                seconds.
              </b>
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="text-center pa-3">
            <v-spacer></v-spacer>
            <v-btn
              v-if="!incidentManagerShiftDialog"
              color="primary"
              class="m-auto"
              @click="resetInactivityConfirmationSettings"
            >
              Stay Logged-In
            </v-btn>
            <v-btn color="primary" class="m-auto" @click="logout">Logout Now</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showInactivityComplete" max-width="600" content-class="info-dialog" persistent>
        <v-card>
          <v-card-title class="">
            <h3 class="grey--text text--darken-3">Session Timeout</h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mb-3 sessionOutText">You have been logged off automatically. You will be redirected shortly...</p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
    <v-dialog
      v-if="incidentManagerShiftDialog"
      v-model="incidentManagerShiftDialog"
      max-width="600"
      persistent
      content-class="v-dialog--scrollable"
    >
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Shift Time</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-3 pt-0 scroll-content-dialog">
          <v-layout row wrap>
            <v-flex sm6 pr-2>
              <v-menu
                ref="shiftStartTimePickerDialog"
                v-model="showShiftStartTimePicker"
                :close-on-content-click="false"
                :return-value.sync="startTime"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="startTime"
                    label="Start time"
                    append-icon="access_time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-model="startTime" format="24hr" :min="minShiftStart" full-width>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="showShiftStartTimePicker = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.shiftStartTimePickerDialog.save(startTime)">OK</v-btn>
                </v-time-picker>
              </v-menu>
            </v-flex>
            <v-flex sm6 pl-2>
              <v-menu
                ref="shiftEndTimePickerDialog"
                v-model="showShiftEndTimePicker"
                :close-on-content-click="false"
                :return-value.sync="endTime"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
                :nudge-right="40"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="endTime"
                    v-validate="'required'"
                    label="End time"
                    append-icon="access_time"
                    readonly
                    required
                    class="required"
                    data-vv-name="TimePicker"
                    :error-messages="validationMessage('TimePicker')"
                    data-vv-scope="frmshiftTime"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-model="endTime" format="24hr" :min="startTime" full-width>
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="showShiftEndTimePicker = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.shiftEndTimePickerDialog.save(endTime)">OK</v-btn>
                </v-time-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-flex v-if="wrongTimeRangeError" error--text>
            {{ wrongTimeRangeError }}
          </v-flex>
          <p class="mb-0 sessionOutText">
            <b>
              You will be automatically logged out in
              <span>({{ getInactivityTimeLeft }})</span>
              , please enter your shift.
            </b>
          </p>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mr-0"
            :loading="isLoading"
            :disabled="isLoading"
            @click="addIncidentManagerShiftTime"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="imageSnackbar" :timeout="3000" bottom left>
      {{ imageSnackbarText }}
      <v-btn flat color="secondary" @click.native="imageSnackbar = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar v-model="refreshAppSnackbar" :timeout="0" bottom left color="red">
      <span>New updates available! Refresh your page.</span>
      <v-btn flat color="white" @click.native="refreshApplication">
        <v-icon class="md-icon">replay</v-icon>
        Refresh
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="notificationSnackbar" :timeout="5000" bottom left>
      <span>You have a New Notification.</span>
      <v-btn flat color="secondary" @click.native="notificationSnackbar = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbar" :timeout="5000" bottom left>
      <span>{{ snackbarMessage }}</span>
      <v-btn flat color="secondary" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-dialog
      v-if="addEmergencyInfoDialog && emergencyAddedFromAnswer.length > 0"
      v-model="addEmergencyInfoDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="pa-3">
          <h3>
            <div v-if="emergencyAddedFromAnswer.length === 1">New Emergency has been added.</div>
            <div v-else>New Emergencies have been added.</div>
            <br />
            <ul v-for="(emergency, index) in emergencyAddedFromAnswer" :key="index" class="ml-1">
              <li>{{ emergency.typeDescription }} ({{ emergency.detailDescription }})</li>
            </ul>
            <br />
            <div>Please provide answers for emergency questions.</div>
          </h3>
        </v-card-title>
        <v-card-actions class="pt-2 px-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeEmergencyInfoDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <IncomingCallModal v-if="callAlert" :call-alert="callAlert" @closeModal="() => (callAlert = null)" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import UserMenu from '@/components/navigation/UserMenu.vue'
import AppSwitcher from '@/components/navigation/AppSwitcher.vue'
import FloatingAddJobButton from '@/components/FloatingAddJobButton.vue'
import Confirmation from '@/components/Confirmation.vue'
import { ConnectionStatus } from '@/common/environment'
import ConnectionStatusBar from '@/components/ConnectionStatusBar.vue'
import { SetTheme, OfflineTheme, SetThemeForJobType } from '@/common/themes'
import TwilioClient from '@/components/twilio/TwilioClient.vue'
import TwilioClientV2 from '@/components/twilio/TwilioClientV2.vue'
import Counter from '@/components/twilio/Counter.vue'
import eventBus from '@/common/bus'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import JobWarnings from '@/components/JobWarnings.vue'
import Store from '@/store'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import ComplaintModel from '@/models/claim/ComplaintModel'
import UniversalSearch from '@/components/timeline/UniversalSearch.vue'
import GlobalNotificationsHandler from '@/components/GlobalNotificationsHandler.vue'
import { Action } from 'vuex-class'
import CustomerToPayModel from '@/models/claim/CustomerToPayModel'
import IncidentManagerShiftTimeModel from '@/models/user/IncidentManagerShiftTimeModel'
import UserController from '@/api/userController'
import moment from 'moment'
import Shared from '@/common/shared'
import TwilioController from '@/api/twilioController'
import { UserManager, SignoutRequest } from 'oidc-client'
import Loader from '@/loader'
import PartialJobView from '@/components/PartialJobView.vue'
import Notification from '@/components/notification/Notification.vue'
import JobEscalationDetail from './models/claim/JobEscalationDetailModel'
import Emergency from '@/models/Emergency'
import SidedrawMenu from './components/navigation/SidedrawMenu.vue'
import Snackbar from './components/snackbar/Snackbar.vue'
import { receiveCommandMessage } from '@/messaging/messaging-service'
import { Loader as GoogleLoader } from '@googlemaps/js-api-loader'
import IncomingCallModal from '@/components/twilio/IncomingCallModal.vue'
import CallTaskAlert from '@/models/twilio/CallTaskAlert'

@Component({
  components: {
    UserMenu,
    AppSwitcher,
    FloatingAddJobButton,
    Confirmation,
    TwilioClient,
    TwilioClientV2,
    ConnectionStatusBar,
    JobWarnings,
    Counter,
    UniversalSearch,
    GlobalNotificationsHandler,
    PartialJobView,
    Notification,
    SidedrawMenu,
    Snackbar,
    IncomingCallModal,
  },
})
export default class App extends Vue {
  public Vuebar = require('vuebar')
  // Required For Froala Editor
  public froalaEditorJs = require('froala-editor/js/plugins.pkgd.min.js')
  public froalaEditorCSS = require('froala-editor/css/froala_editor.pkgd.min.css')
  public fontAwesome = require('font-awesome/css/font-awesome.css')
  public froalaStyle = require('froala-editor/css/froala_style.min.css')

  public showDialog = false
  public showNavDrawer = false
  private jobAddress = ''
  private twilioSnackbar = false
  private twilioSnackbarText = ''
  private statusUpdateSnackbar = false

  private incidentManagerShiftDialog = false
  private showShiftStartTimePicker = false
  private showShiftEndTimePicker = false
  private startTime = ''
  private endTime: string | null = null
  private isLoading = false

  private errorSnackbar = false
  private errorSnackbarText = ''

  private clockInSnackbar = false
  private clockInSnackbarText = ''
  private showInactivityConfirmation = false
  private incidentMangerIdleTimeLimit = 1800 // in seconds
  private supervisorIdleTimeLimit = 7200 // in seconds
  private idleTimeLimit: number = this.incidentMangerIdleTimeLimit // in seconds
  private autoLogoutConfirmationTimeLimt = 30 // in seconds
  private inactivityTimeLimit: number = 1000 * this.idleTimeLimit
  private autoLogoutTimeLimit: number = 1000 * this.autoLogoutConfirmationTimeLimt
  private autoLogoutTimeLeft: number = this.autoLogoutConfirmationTimeLimt
  private timerInactivityHandle: NodeJS.Timer | null = null
  private timerAutoLogoutHandle: NodeJS.Timer | null = null
  private autoLogoutTimeLeftInterval: NodeJS.Timer
  private showInactivityComplete = false
  private userManager: UserManager
  private logoutUrl = ''
  private imageSnackbar = false
  private imageSnackbarText = ''
  private isShowOverlayImage = false
  private refreshAppSnackbar = false
  private wrongTimeRangeError = ''
  private minShiftStart: string = moment().format('HH:mm')
  private inactivityTimeLeftInterval: NodeJS.Timer
  private inactivityTimeLeft = this.inactivityTimeLimit
  private jobIdToShowInHeader = ''
  // open job view
  private openJobView = false
  private selectedJobIdToExpand = ''

  private snackbarColor = ''
  private offlineDB: any = {}
  // local variable to show offline jobs list only on reloading the page
  private isUserOnline: boolean = navigator.onLine
  private notificationSnackbar = false
  private snackbar = false
  private snackbarMessage = ''
  private showLiveLocation = false
  private addEmergencyInfoDialog = false
  private emergencyAddedFromAnswer: Emergency[] = []
  private isAppSettingsError = false
  private appSettingsErrorMsg = ''

  public callAlert: CallTaskAlert | null = null

  @Action('signOutOidc') private signOutOidc

  public get haveAccessToken(): boolean {
    return Store.Instance.state.SessionDetail.accessToken !== ''
  }

  public get isLoggedIn(): boolean {
    return (
      Store.Instance.state.SessionDetail.accessToken !== '' ||
      Store.Instance.state.Environment.ConnectionStatus === ConnectionStatus.Offline
    )
  }

  public get dialpadV2Enabled(): boolean {
    return this.$ld.variation('plat-242-twilio-dialpad-v2')
  }

  private get showConnectionStatusSnackBar(): boolean {
    return Store.Instance.state.Environment.ConnectionStatus === ConnectionStatus.Offline
  }

  public mounted(): void {
    eventBus.$on('setupInitialSettings', async () => await this.setupInitialSettings())
    eventBus.$on('appSettingsError', (errorMsg: string) => this.appSettingsError(errorMsg))
    eventBus.$on('errorHandler', this.handleError.bind(this))
    eventBus.$on('showTwilioSnackbar', (message: string, showCounter: boolean) => {
      this.showTwilioSnackbar(message, showCounter)
    })
    eventBus.$on('clearTwilioSnackbar', () => {
      this.clearTwilioSnackbar()
    })
    eventBus.$on('showHideOverlayImage', (isShowImage: any) => {
      this.isShowOverlayImage = isShowImage
    })
    eventBus.$on('redirectToJob', (jobId: string) => {
      this.onRedirectToJob(jobId)
    })
    eventBus.$on('showJobIdInHeader', (jobId = '') => {
      this.jobIdToShowInHeader = jobId
    })
    eventBus.$on('showSnackbar', (message: string) => {
      // when user do not have rights to open a selected type of job
      this.snackbar = true
      this.snackbarMessage = message
    })
    eventBus.$on('showEngineerLiveLocation', (showLiveLocation: boolean) => {
      this.showLiveLocation = showLiveLocation
    })
    eventBus.$on('addEmergencyBasedOnAnswer', (emergencyList: Emergency[]) => {
      this.emergencyAddedFromAnswer = emergencyList
      this.addEmergencyInfoDialog = true
    })
    eventBus.$on('validationErrorHandler', (message = '') => {
      this.handleValidationError(message)
    })
    eventBus.$on('showIncomingCallAlertModal', (callAlert: CallTaskAlert) => {
      this.callAlert = callAlert
    })
    eventBus.$on('hideIncomingCallAlertModal', () => {
      this.callAlert = null
    })
    eventBus.$on('handleStatusUpdate', (isOffline: boolean) => {
      this.handleStatusUpdate(isOffline)
    })
    if (Store.Instance.state.Environment.ConnectionStatus === ConnectionStatus.Offline) {
      SetTheme(this, new OfflineTheme())
    }

    if (Loader.oidcSettings) {
      this.userManager = new UserManager(Loader.oidcSettings)
    }
    // created one database and table in indexdb
    this.createOfflineDatabase()

    if (!this.incidentManagerShiftDialog && this.isUserRoleIncidentManager) {
      window.onload = this.resetInactivityTimer
      document.onmousemove = this.resetInactivityTimer
      document.onkeydown = this.resetInactivityTimer
    }
  }

  public logout() {
    const self = this
    const errorMsg = 'There was an issue logging you out, please try again.'
    TwilioController.LogoutWorker()
      .then((result: boolean) => {
        if (result) {
          self.signOutOidc()
        } else {
          console.error(errorMsg)
          self.errorSnackbarText = errorMsg
          this.snackbarColor = ''
          self.errorSnackbar = true
        }
      })
      .catch((error) => {
        console.error(error)
        this.errorSnackbarText = errorMsg
        this.snackbarColor = ''
        this.errorSnackbar = true
      })
  }

  private setNavDrawerl() {
    this.showNavDrawer = !this.showNavDrawer
  }

  private created() {
    Vue.use(this.Vuebar)
    // for job start and end time for IM
    const time = this.roundedMinutes(moment(), 15)
    if (time) {
      this.startTime = time.format('HH:mm')
    }

    window.addEventListener('message', receiveCommandMessage, false)
  }

  // Configure inactivity timer for IM when logging in
  @Watch('incidentManagerShiftDialog')
  private setShorterTimer() {
    if (this.incidentManagerShiftDialog) {
      window.onload = null
      document.onmousemove = null
      document.onkeydown = null
      if (Store.Instance.state.SessionDetail.detailRecord.UserDetail.timerStartedIM) {
        // if they have already logged in recently, calculate how long they have left to enter shift
        const timeDifference = moment().diff(
          moment(Store.Instance.state.SessionDetail.detailRecord.UserDetail.timerStartedIM, moment.ISO_8601),
          'seconds'
        )
        this.idleTimeLimit = 300 - timeDifference
        this.inactivityTimeLimit = 300000 - timeDifference * 1000
        if (this.idleTimeLimit <= 0) {
          // instantly logout IM should they have already passed the 5 minute login restriction
          this.showInactivityConfirmation = false
          this.incidentManagerShiftDialog = false
          this.logout()
        } else {
          this.resetInactivityTimer()
          this.startInactivityTimer()
        }
      } else {
        // start timer on login and display, update API with time
        this.idleTimeLimit = 300
        this.inactivityTimeLimit = 1000 * this.idleTimeLimit
        UserController.UpdateLoginTimeIM()
        this.resetInactivityTimer()
        this.startInactivityTimer()
      }
    } else {
      // reset timer to usual and update IM activity to idle on dialogue closure
      eventBus.$emit(
        'updateWorkerActivity',
        Store.Instance.state.Environment.TwilioIdleActivitySid,
        'Idle',
        'Closed shift dialog'
      )
      this.idleTimeLimit = this.isUserRoleSupervisor ? this.supervisorIdleTimeLimit : this.incidentMangerIdleTimeLimit
      this.inactivityTimeLimit = 1000 * this.idleTimeLimit
      // restart the timer to work with new time, add handlers to reset timer
      this.resetInactivityTimer()
      this.startInactivityTimer()
      window.onload = this.resetInactivityTimer
      document.onmousemove = this.resetInactivityTimer
      document.onkeydown = this.resetInactivityTimer
    }
  }

  @Watch('isUserRoleIncidentManager')
  @Watch('isUserRoleSupervisor')
  private isUserRoleIncidentManagerChange() {
    if (this.isLoggedIn && (this.isUserRoleIncidentManager || this.isUserRoleSupervisor)) {
      // set idle time limit
      this.idleTimeLimit = this.isUserRoleSupervisor ? this.supervisorIdleTimeLimit : this.incidentMangerIdleTimeLimit
      this.inactivityTimeLimit = 1000 * this.idleTimeLimit
      // start timer for auto logout if user is IM
      this.timerInactivityHandle = null
      this.startCheckingInactivity()

      // Get IncidentManager Shift => if shift is ended and relogin then again ask to enter shift timing.
      UserController.GetIncidentManagerShift()
        .then((res: IncidentManagerShiftTimeModel | null) => {
          if (res === null) {
            this.incidentManagerShiftDialog = true
          }
        })
        .catch((err: any) => {
          this.handleError('Error loading shift detail, please try again', true, undefined, { error: err })
        })
    }
  }

  // get job detail
  private get job(): Job | null {
    return storeGetters.getJob(this.$route.params.jobId)
  }

  private get showFloatingButton(): boolean {
    if (!navigator.onLine) {
      const userRole: string | null = localStorage.getItem('userRole')
      return userRole
        ? userRole.toLowerCase() === 'contractor/engineer' ||
          userRole.toLowerCase() === 'clientmanager' ||
          userRole.toLowerCase() === 'networkmanager' ||
          userRole.toLowerCase() === 'finance'
          ? false
          : true
        : false
    } else {
      if (
        (this.$route &&
          (this.$route.name === 'clientManagement' ||
            this.$route.name === 'clientManagementwithBordereauDetail' ||
            this.$route.name === 'clientManagementwithfinancialSummary' ||
            this.$route.name === 'policySchedule' ||
            this.$route.name === 'contractorManagement' ||
            this.$route.name === 'dynamicDashboard' ||
            this.$route.name === 'contractorManagementWithId' ||
            this.$route.name === 'engineerRequestDashboard')) ||
        Store.Instance.state.SessionDetail.detailRecordType !== 'UserDetail'
      ) {
        return false
      }
    }
    return true
  }

  // get vulnerabilityQA
  private get vulnerabilityQAItem(): VulnerabilityQAModel | undefined {
    if (this.jobId) {
      return storeGetters.getVulnerabilityQA(this.jobId)
    }
    return undefined
  }

  // get healthAndSafetyQA
  private get healthAndSafetyQAItem(): HealthAndSafetyQAModel[] {
    if (this.jobId) {
      return storeGetters.getHealthAndSafetyQA(this.jobId)
    }
    return []
  }

  // get Complaint
  private get complaints(): ComplaintModel[] {
    return this.jobId ? storeGetters.getComplaints(this.jobId) : []
  }

  // get is Customer VIP Customer or not
  private get getIsVipCustomer(): boolean {
    if (this.jobId) {
      return this.job && this.job.isVIPCustomer ? true : false
    }
    return false
  }

  // get CustomerToPay
  private get customerToPay(): CustomerToPayModel | undefined {
    if (this.jobId) {
      return storeGetters.getAuthorisedCustomerToPay(this.jobId)
    }
    return undefined
  }

  // show hide job warning
  private get showWarning(): boolean {
    let warning = false
    if (this.vulnerabilityQAItem && this.vulnerabilityQAItem.isHealthAffected === true) {
      warning = true
    }
    if (!warning && this.healthAndSafetyQAItem) {
      this.healthAndSafetyQAItem.forEach((element: HealthAndSafetyQAModel) => {
        if (element && element.isHealthAffected === true) {
          warning = true
          return
        }
      })
    }
    if (this.complaints && this.complaints.length > 0) {
      warning = this.complaints.filter((c) => !c.isResolved).length > 0 ? true : false
    }
    if (this.getIsVipCustomer) {
      warning = true
    }

    // check asbestos
    if (this.job && this.job.emergenciesQAs) {
      this.job.emergenciesQAs.forEach((emergency) => {
        if (emergency.asbestosEffectLevel) {
          warning = true
        }
      })
    }
    if (this.customerToPay && this.customerToPay.isTransactionCompleted) {
      warning = true
    }

    // job escalation
    if (this.escalationDetails) {
      warning = true
    }

    // check outstanding excess amount
    if (this.job && this.job.jobType === 'US') {
      const filteredCustomerToPay =
        this.job && this.job.customerToPay && this.job.customerToPay.length > 0
          ? this.job.customerToPay.filter((x) => x.isTransactionCompleted === true)
          : []
      const totalPaidAmount =
        filteredCustomerToPay.length > 0
          ? filteredCustomerToPay.map((y) => y.amount).reduce((prev, next) => prev + next)
          : 0
      const emergency = this.job!.emergencies[this.job!.emergencies.length - 1]
      if (totalPaidAmount < (emergency && emergency.excessValue ? emergency.excessValue : 0)) {
        warning = true
      }
    }

    return warning
  }

  private get escalationDetails(): JobEscalationDetail | null {
    return this.jobId ? storeGetters.getJobActiveEscalationDetails(this.jobId) : null
  }

  private get jobId(): string | undefined {
    if ((this.$route && this.$route.name === 'job') || this.$route.name === 'jobWithEngineerRequestId') {
      return this.$route.params.jobId
    }
    return undefined
  }

  private get showJobAddress(): boolean {
    if ((this.$route && this.$route.name === 'job') || this.$route.name === 'jobWithEngineerRequestId') {
      if (this.job) {
        this.jobAddress = 'Job Id: ' + this.$route.params.jobId + ' - ' + this.job.getAddress(', ')
      }
      return true
    }
    return false
  }

  private get isUserRoleIncidentManager(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'IncidentManager'
    )
  }

  private get isUserRoleSupervisor(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Supervisor'
    )
  }

  @Watch('job.jobType')
  private onJobTypeChange() {
    if (this.job && this.job.jobType) {
      SetThemeForJobType(this, this.job.jobType)
    } else {
      SetThemeForJobType(this, undefined)
    }
  }

  private get showMenuIcon(): boolean {
    if (
      this.$route &&
      (this.$route.name === 'home' || this.$route.name === 'contractorManagement') &&
      Store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
    ) {
      return false
    }
    return true
  }

  private get getClassName(): string {
    if (
      this.$route &&
      (this.$route.name === 'contractorManagement' ||
        this.$route.name === 'contractorManagementWithId' ||
        this.$route.name === 'clientManagement' ||
        this.$route.name === 'clientManagementwithfinancialSummary' ||
        this.$route.name === 'clientManagementwithBordereauDetail')
    ) {
      return 'custom-theme-wrapper'
    }
    return ''
  }

  private showTwilioSnackbar(message: string, showCounter: boolean): void {
    this.twilioSnackbar = true
    this.twilioSnackbarText = message
    if (showCounter) {
      setTimeout(() => {
        ;(this.$refs.twilioSnackbarCounter as Counter).startCounter()
      }, 0)
    }
  }

  private clearTwilioSnackbar(): void {
    if (this.$refs.twilioSnackbarCounter) {
      ;(this.$refs.twilioSnackbarCounter as Counter).clearCounter()
    }
    this.twilioSnackbar = false
    this.twilioSnackbarText = ''
  }

  private handleStatusUpdate(isOffline: boolean): void {
    this.statusUpdateSnackbar = isOffline
  }

  private clearErrorSnackbar(): void {
    this.errorSnackbar = false
    this.errorSnackbarText = ''
    this.snackbarColor = ''
  }

  private clearClockInSnackbar(): void {
    this.clockInSnackbar = false
    this.clockInSnackbarText = ''
  }

  private get showDashBoardName(): string {
    if (this.$route) {
      switch (this.$route.name) {
        case 'incidentmanagerdashboard':
          return 'Incident Manager'
        case 'jobDashboard':
          return 'Job History'
        case 'dashboard':
          return 'Supervisor'
        case 'unlinkedPhoneCallsDashboard':
          return 'Unlinked PhoneCalls'
        case 'complaintTracker':
          return 'Complaints'
        case 'managerSupervisorDashboard':
          return 'Manager Supervisor'
        case 'undeployedEmergencyDashboard':
          return 'Undeployed Emergencies'
        case 'networkManagerDashboard':
          return 'Network Manager'
        case 'thirdPartyDashboard':
          return 'Third Party'
        case 'queueDashboard':
          return 'Queue'
        case 'outstandingJobDashboard':
          return 'Work in Progress'
        case 'engineerRequestDashboard':
          return 'Engineer Request'
        case 'dynamicDashboard':
          return 'Custom Dashboard'
        case 'ctpDashboard':
          return 'Customer To Pay'
        case 'sIJobDashboard':
          return 'SI Dashboard'
        case 'missedJobsDashboard':
          return 'Open Jobs'
        case 'usJobDashboard':
          return 'US Dashboard'
        case 'clientInvoiceDashboardSI':
          return 'Client Invoice SI'
        case 'clientInvoiceDashboard':
          return 'Client Invoice'
        default:
          return ''
      }
    }
    return ''
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    return message ? (message = 'The shift end time is required.') : message
  }

  private async validate(): Promise<boolean> {
    if (this.wrongTimeRangeError) {
      return false
    }
    const result: boolean = await this.$validator.validateAll('frmshiftTime')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private roundedMinutes(originalDate: moment.Moment | null, minutesInterval: number) {
    if (originalDate) {
      const roundedETAFromMinute = Math.ceil(moment(originalDate).minute() / minutesInterval) * minutesInterval
      return moment(originalDate).minute(roundedETAFromMinute).second(0)
    }
    return null
  }

  private allowedMinutes(value: number) {
    return value % 15 === 0
  }

  @Watch('startTime')
  @Watch('endTime')
  private shoftEndTimeChange() {
    this.wrongTimeRangeError = ''
    if (this.endTime) {
      const timeStart = Number(this.startTime.split(':')[0])
      const timeEnd = Number(this.endTime.split(':')[0])
      const hours: number = timeEnd - timeStart
      if (hours > 24) {
        this.wrongTimeRangeError = 'Total shift time must be less than 24 hours.'
      }
    }
  }

  private addIncidentManagerShiftTime() {
    const shiftStartDateTime = moment(new Date().toISOString().slice(0, 10) + ' ' + this.startTime)
    let shiftEndDateTime: moment.Moment | null = null
    if (this.endTime) {
      const timeFormat = 'hh:mm:ss'
      const startTime = moment(this.startTime, timeFormat)
      const endTime = moment(this.endTime, timeFormat)
      const time = moment('23:59:00', timeFormat)
      // check if shift end time is between shift start time and time(23:59)
      if (endTime.isBetween(startTime, time)) {
        // if between then set today's date as end time date
        shiftEndDateTime = moment(new Date().toISOString().slice(0, 10) + ' ' + this.endTime)
      } else {
        // if not between then set tomorrow's date as end time date
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() + 1)
        shiftEndDateTime = moment(currentDate.toISOString().slice(0, 10) + ' ' + this.endTime)
      }
    }
    const incidentManagerShiftTime: IncidentManagerShiftTimeModel = new IncidentManagerShiftTimeModel()
    incidentManagerShiftTime.userId = Store.Instance.state.SessionDetail.detailRecord.UserDetail.id
    incidentManagerShiftTime.startTime = moment.utc(shiftStartDateTime)
    if (shiftEndDateTime) {
      incidentManagerShiftTime.endTime = moment.utc(shiftEndDateTime)
    }
    this.validate()
      .then((result: boolean) => {
        if (result) {
          this.isLoading = true
          UserController.AddIncidentManagerShift(incidentManagerShiftTime)
            .then((res: IncidentManagerShiftTimeModel | null) => {
              if (res !== null) {
                this.incidentManagerShiftDialog = false
              }
              this.isLoading = false
            })
            .catch((err: any) => {
              this.handleError('Error submitting shift detail, please try again', true, undefined, { error: err })
              this.isLoading = false
            })
        }
      })
      .catch((err: any) => {
        this.handleValidationError()
      })
  }

  private replaceUsername(msg: string): string {
    if (msg !== null) {
      msg = msg.replace('$username$', this.userName)
    }
    return msg
  }

  private startCheckingInactivity(): void {
    if (Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'IncidentManager') {
      this.setupInactivityCheck()
      window.onload = this.setupInactivityCheck
      document.onmousemove = this.resetInactivityTimer
      document.onkeydown = this.resetInactivityTimer
    } else {
      this.resetInactivityTimer()
      window.onload = this.resetInactivityTimer
      document.onmousemove = this.resetInactivityTimer
      document.onkeydown = this.resetInactivityTimer
    }
  }

  private setupInactivityCheck(): void {
    if (this.timerInactivityHandle) {
      clearTimeout(this.timerInactivityHandle)
      this.timerInactivityHandle = null
    }
    if (!this.showInactivityComplete) {
      this.timerInactivityHandle = setTimeout(this.showSessionExpiredPopup, this.inactivityTimeLimit)
    }
  }

  private resetInactivityTimer(): void {
    if (this.timerInactivityHandle) {
      clearTimeout(this.timerInactivityHandle)
      this.timerInactivityHandle = null
    }
    if (!this.showInactivityComplete) {
      this.timerInactivityHandle = setTimeout(this.showSessionExpiredPopup, this.inactivityTimeLimit)
    }
  }

  private stopCheckingInactivity(): void {
    if (this.timerInactivityHandle) {
      clearTimeout(this.timerInactivityHandle)
      this.timerInactivityHandle = null
    }
  }

  private stopCheckingAutoLogout(): void {
    if (this.timerAutoLogoutHandle) {
      clearTimeout(this.timerAutoLogoutHandle)
      this.timerAutoLogoutHandle = null
    }
  }

  private showSessionExpiredPopup() {
    // get the current call sid and check if the IM on call or not
    const currentCallSid: string = storeGetters.getCurrentCallSid()
    if (currentCallSid) {
      // reset timer if IM on call
      this.startCheckingInactivity()
      return
    }
    // start timer
    this.startAutoLogoutTimer()
    // show inactivity popup
    this.showInactivityConfirmation = true
    // reset auto logout timer
    this.stopCheckingAutoLogout()
    this.timerAutoLogoutHandle = setTimeout(this.logoutDueToInactivity, this.autoLogoutTimeLimit)
  }

  private startAutoLogoutTimer(): void {
    clearInterval(this.autoLogoutTimeLeftInterval)
    this.autoLogoutTimeLeft = this.autoLogoutConfirmationTimeLimt
    if (!this.showInactivityComplete) {
      this.autoLogoutTimeLeftInterval = setInterval(() => {
        if (this.autoLogoutTimeLeft > 0) {
          this.autoLogoutTimeLeft--
        }
      }, 1000)
    }
  }

  private startInactivityTimer(): void {
    clearInterval(this.inactivityTimeLeftInterval)
    this.inactivityTimeLeft = this.idleTimeLimit
    if (this.incidentManagerShiftDialog) {
      this.inactivityTimeLeftInterval = setInterval(() => {
        if (this.inactivityTimeLeft > 0) {
          this.inactivityTimeLeft--
        }
      }, 1000)
    }
  }

  private get getInactivityTimeLeft(): string {
    if (this.inactivityTimeLeft) {
      let secondsLeft = this.inactivityTimeLeft % 60
      let minutesLeft = (this.inactivityTimeLeft - secondsLeft) / 60
      if (minutesLeft < 0) {
        minutesLeft = 0
      }
      if (secondsLeft < 0) {
        secondsLeft = 0
      }
      if (secondsLeft < 10) {
        return minutesLeft + ':0' + secondsLeft
      } else {
        return minutesLeft + ':' + secondsLeft
      }
    }
    return '-:--'
  }

  private get getAutoLogoutTimeLeft(): number {
    return this.autoLogoutTimeLeft
  }

  private logoutDueToInactivity(): void {
    this.showInactivityComplete = false
    if (this.showInactivityConfirmation) {
      this.showInactivityConfirmation = false
      this.stopCheckingInactivity()
      this.stopCheckingAutoLogout()
      this.userManager.createSignoutRequest().then((request: SignoutRequest) => {
        this.logoutUrl = request.url
      })
      this.showInactivityComplete = true
      this.logout()
    }
  }

  private resetInactivityConfirmationSettings() {
    // close inactivity popup
    this.showInactivityConfirmation = false
    this.startCheckingInactivity()
  }

  private loginFromAutoLogout() {
    location.href = location.origin
  }

  private get isUserLoggedIn(): boolean {
    return Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail'
  }

  private get userName(): string {
    return Store.Instance.state.SessionDetail.userName
  }

  private reconnectWorker() {
    eventBus.$emit(
      'updateWorkerActivity',
      Store.Instance.state.Environment.TwilioIdleActivitySid,
      'Idle',
      'Reconnected worker'
    )
  }

  private refreshApplication() {
    window.location.reload()
  }

  private get isClientUserLoggedIn() {
    return Store.Instance.state.SessionDetail.detailRecordType === 'ClientUserDetail'
  }

  private onRedirectToJob(jobId: string) {
    // open timeline with relative card open.
    this.openJobView = true
    this.selectedJobIdToExpand = jobId
    this.$store.dispatch('heWizardModule/setIsDuplicateJobOpen', true)
    this.addorRemoveClassFromParentElement()
  }

  private closeJobView() {
    this.openJobView = false
    this.$store.dispatch('heWizardModule/setIsDuplicateJobOpen', false)
    this.selectedJobIdToExpand = ''
    this.addorRemoveClassFromParentElement()
    Shared.passJobIdInHeader()
  }

  private createOfflineDatabase() {
    // create Indexed DB
    const offLineDBName = 'SIMPLIFi-OfflineJobRequests'
    const dbVersion = 1
    const request = indexedDB.open(offLineDBName, dbVersion)

    request.onsuccess = (event) => {
      this.offlineDB = request.result
    }

    request.onupgradeneeded = (event: any) => {
      const db = event.target.result
      db.createObjectStore(Shared.offlineJobRequestTable, {
        keyPath: 'jobId',
        autoIncrement: true,
      })
    }

    request.onerror = (error) => {
      this.errorSnackbarText = 'Error creating database'
      this.errorSnackbar = true
    }
  }

  private get userRoleDescription(): boolean {
    // just to get updated values
    const userRoleDescription: string = Store.Instance.state.SessionDetail.userRoleDescription
    // save user role to user at a time of offline mode
    if (userRoleDescription) {
      if (userRoleDescription !== 'Unknown') {
        localStorage.setItem('userRole', userRoleDescription)
        localStorage.setItem('offlineUserRole', userRoleDescription)
      } else {
        localStorage.setItem('offlineUserRole', userRoleDescription)
      }
    }
    return true
  }

  private addorRemoveClassFromParentElement() {
    const element: any = document.getElementsByClassName('wizard-dialog')
    const overlayelement: any = document.getElementsByClassName('v-overlay')
    if (this.openJobView) {
      if (element && element.length > 0 && overlayelement && overlayelement.length > 0) {
        element[0].parentNode.classList.add('wizard-dialog-content')
        overlayelement[0].style.display = 'none'
      }
    } else {
      if (overlayelement && overlayelement.length > 0) {
        overlayelement[0].style.display = 'block'
      }
    }
  }

  private get addSpace(): boolean {
    return Store.Instance.state.SessionDetail.detailRecordType === 'UserDetail'
  }

  private closeEmergencyInfoDialog() {
    this.addEmergencyInfoDialog = false
    eventBus.$emit('resetEmergencyAddedFromAnswer')
  }

  private handleError(error: Error | string, showToUser = false, errorColor = '', props?: { [name: string]: string }) {
    console.error(error, props)
    if (showToUser) {
      this.errorSnackbarText = JSON.stringify(error)
      this.snackbarColor = errorColor
      this.errorSnackbar = true
    }
  }

  private handleValidationError(errorMessage = '') {
    this.errorSnackbarText = errorMessage ? errorMessage : 'Validation failed. Please try again.'
    this.errorSnackbar = true
  }

  private async setupInitialSettings() {
    await this.setupGoogleMaps()
    this.$store.dispatch('authModule/submitGetAuthPolicies')
    if (this.isUserLoggedIn) {
      this.$store.dispatch('usersModule/retrievePermissions')
    }
  }

  private async setupGoogleMaps() {
    const loader = new GoogleLoader({
      apiKey: Store.Instance.state.Environment.GoogleMapsJavascriptAPIKey,
      version: 'weekly',
    })
    await loader.importLibrary('places')
    await loader.importLibrary('drawing')
    await loader.importLibrary('geometry')
    await loader.importLibrary('marker')
  }

  private appSettingsError(errorMsg: string) {
    this.isAppSettingsError = true
    this.appSettingsErrorMsg = errorMsg
    this.handleError(errorMsg)
  }

  public get isDuplicateJobOpen(): boolean {
    return this.$store.getters['heWizardModule/isDuplicateJobOpen']
  }
}
</script>

<style scoped>
.v-toolbar .menu-spacer {
  width: 60px;
  height: 56px;
  flex-shrink: 0;
}
/* #inspire  >>> .v-toolbar__content{padding-left: 0px !important;} */
.call-button >>> .v-btn .v-icon {
  color: #fff;
}
.custom-theme-wrapper .v-content__wrap > .container {
  padding: 0px;
}
.application >>> .v-speed-dial {
  position: fixed;
  z-index: 11;
}
.sessionOutText {
  font-size: 15px;
  line-height: 1.5;
}
.sessionOutText span {
  color: #ff0000;
}
.top-menubar {
  z-index: 11 !important;
}
.side-menubar {
  margin-top: 64px !important;
  height: calc(100% - 64px) !important;
}
.v-navigation-drawer--is-mobile,
.v-navigation-drawer--temporary {
  z-index: 10;
}
.overlay-images {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
}
.back-btn >>> .jobview-hide .v-btn {
  left: -79px;
}
.hide-dashboard {
  visibility: hidden;
}
.show-dashboard {
  visibility: visible;
}
.offline-card-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.offline-card {
  background: transparent !important;
  max-width: 1200px;
}
.offline-card p {
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 6px;
}
.img-wrap {
  width: 130px;
  margin: 0 auto;
}
.img-wrap img {
  max-width: 100%;
}
.offline-header {
  height: 64px !important;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #616161;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>
