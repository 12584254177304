<template>
  <v-dialog v-model="store.show" max-width="1200" persistent content-class="v-dialog--scrollable full-card-width">
    <v-card>
      <v-toolbar card dark color="primary">
        <v-toolbar-title>Contractor Rate</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="close-rate-template-dialog" @click.native="store.show = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-card-text class="px-3 scroll-content-dialog">
        <v-card class="py-0">
          <ContractorRate
            ref="contractorRateRef"
            :readonly="readonly"
            :from-job-completion-screen="true"
            :all-contractor-rate-package-list.sync="store.rates"
            :contractor-id="contractorId"
            :rate-package-id.sync="store.ratePackageId"
          ></ContractorRate>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import ContractorRate from '@/components/ContractorRate.vue'
import { useContractorRatesStore } from '@/pinia/contractor-rates'

@Component({
  components: { ContractorRate },
})
export default class ContractorRatePackageList extends Vue {
  @Prop() private contractorId: string
  @Prop() private readonly: boolean

  private store = useContractorRatesStore()
  mounted() {
    this.store.fetchRates()
  }
}
</script>
