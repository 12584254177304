import { render, staticRenderFns } from "./AgentAssist.vue?vue&type=template&id=4bf96300&scoped=true"
import script from "./AgentAssist.vue?vue&type=script&lang=ts"
export * from "./AgentAssist.vue?vue&type=script&lang=ts"
import style0 from "./AgentAssist.vue?vue&type=style&index=0&id=4bf96300&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf96300",
  null
  
)

export default component.exports