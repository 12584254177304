<template>
  <div>
    <v-list-group v-for="menuItem in getVisibleItems(menuItems)" :key="menuItem.path" append-icon>
      <v-list-tile slot="activator" @click="menuItemClick(menuItem)">
        <v-list-tile-action>
          <v-icon v-if="menuItem.icon">{{ menuItem.icon }}</v-icon>
          <img v-else :src="menuItem.image" class="menu-img" />
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>
            <v-tooltip v-if="menuItem.tooltip" bottom>
              <template #activator="{ on }">
                <span v-on="on">{{ menuItem.text }}</span>
              </template>
              <span>{{ menuItem.tooltip }}</span>
            </v-tooltip>
            <span v-else>{{ menuItem.text }}</span>
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile
        v-for="item in getVisibleSubItems(menuItem)"
        :key="item.path"
        class="sub-menu-list"
        @click="subMenuItemClick(item)"
      >
        <v-list-tile-action v-if="item.icon || item.image">
          <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
          <img v-else :src="item.image" class="menu-img" />
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>{{ item.text }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list-group>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import store from '@/store'
import { Moment } from 'moment'
import eventBus from '@/common/bus'
import CustomDashboardController from '@/api/customDashboardController'
import { Environment } from '@/common/environment'
import UserModel from '@/models/user/UserModel'
import SessionDetail from '@/models/auth/SessionDetail'
import AuthPolicies from '@/models/auth/auth-policies'
import { useSessionStore } from '@/pinia/session'
import { Permissions } from '@/models/session/session'

interface MenuItem {
  path: string
  text: string
  icon?: string
  image?: string
  tooltip?: string
  openInNewTab?: boolean
  show?: (m: MenuItem) => boolean
  subMenu?: MenuItem[]
}

@Component
export default class SidedrawMenu extends Vue {
  private menuItems: MenuItem[] = []
  private customDashboardItems: MenuItem[] = []

  private get environment(): Environment {
    return this.$store.getters['environment']
  }

  private get user(): UserModel {
    return this.$store.getters['userDetail']
  }

  private get session(): SessionDetail {
    return this.$store.getters['sessionDetail']
  }

  private get viewedNewJobAt(): Moment | null {
    return this.$store.getters['lastAddedNewJobAt']
  }

  private getVisibleItems(items: MenuItem[]): MenuItem[] {
    const visibleItems = items.filter((m) => !m.show || m.show(m))
    return visibleItems
  }

  private getVisibleSubItems(item: MenuItem): MenuItem[] {
    return item.subMenu ? item.subMenu.filter((m) => !m.show || m.show(m)) : []
  }

  private async onDashboardsChanged(dashboardName: string, currentRoute: string, isDashboardDeleted: boolean) {
    if (isDashboardDeleted) {
      // Remove dashboard from list.
      const itemIndex = this.customDashboardItems.findIndex((e: MenuItem): boolean => e.path === currentRoute)
      if (itemIndex !== -1) {
        this.customDashboardItems.splice(itemIndex, 1)
      }
    } else {
      const item: MenuItem | undefined = this.customDashboardItems.find((e) => e.path === '/' + currentRoute)
      if (item) {
        // Update existing dashboard
        item.text = dashboardName
      } else {
        // Add new dashboard
        this.customDashboardItems.push({
          path: '/' + currentRoute,
          icon: 'dashboard',
          text: dashboardName,
        })
      }
    }
    this.refresh()
  }

  private menuItemClick(item: MenuItem) {
    if (item.subMenu && item.subMenu.length) {
      return
    }

    // reset the policy schedule when you click menu item
    // render same component again when redirect on the same route.
    this.$router.push({ path: item.path })
    this.$emit('menuItemClick')
    eventBus.$emit('clearSearchInput', '', true)
  }

  private subMenuItemClick(item: MenuItem) {
    if (!item.path) {
      return
    }

    if (item.openInNewTab) {
      window.open(item.path, '_blank')
    } else {
      this.$router.push({
        path: item.path,
      })
    }
    eventBus.$emit('clearSearchInput', '', true)
  }

  @Watch('viewedNewJobAt')
  private refresh() {
    this.menuItems = [
      {
        path: '/',
        icon: 'home',
        text: 'Home',
      },
      ...this.getRecentJobs(),
      {
        path: '#',
        icon: 'dashboard',
        text: 'Dashboard',
        show: (m) => !!m.subMenu && m.subMenu.length > 0,
        subMenu: [
          {
            path: '/dynamicDashboard',
            text: 'Create Dashboard',
            show: () =>
              this.hasUserRoles('SystemAdministrator', 'Administrator', 'Supervisor', 'Finance', 'ClientManager'),
          },
          ...this.customDashboardItems,
          ...this.getUserDashboardItems(),
        ],
      },
      {
        path: '/clientInvoices',
        image: '/img/finance-grey.svg',
        text: 'Finance Area',
        show: () => this.authPolicies.clients.readFinancial || this.authPolicies.contractors.readFinancial,
        subMenu: [
          {
            path: '/clientInvoiceDashboard',
            text: 'Client Invoices',
            show: () => this.authPolicies.clients.readFinancial,
          },
          {
            path: '/clientInvoiceDashboardSI',
            text: 'Client Invoices SI',
            show: () => this.authPolicies.clients.readFinancial,
          },
          {
            path: '/contractorPODashboard',
            text: 'Contractor Purchase Orders',
            show: () => this.authPolicies.contractors.readFinancial,
          },
          {
            path: '/clientReports',
            text: 'Client Reports',
            show: () => this.authPolicies.clients.readFinancial,
          },
          {
            path: '/ctpDashboard',
            text: 'Customer To Pay Invoices',
            show: () =>
              this.user && !!this.user.dashboardUserRights.find((c) => c.name!.toLowerCase() === 'ctpdashboard'),
          },
        ],
      },
      {
        path: '/contractorManagement',
        image: '/img/contractor-icon.svg',
        text: 'Contractor Area',
        show: () => this.hasUserRoles('SystemAdministrator', 'Administrator', 'NetworkManager'),
        subMenu: [
          {
            path: '/contractorManagement',
            text: 'Contractor Management',
          },
          {
            path: '/networkManagerDashboard',
            text: 'Network Management',
          },
          {
            path: '/regionManager',
            text: 'Region Management',
          },
        ],
      },
      {
        path: '/clientmanagement',
        icon: 'person',
        text: 'Client Area',
        show: () => this.hasUserRoles('SystemAdministrator', 'Administrator', 'ClientManager'),
      },
      {
        path: '/adminDashboard',
        image: '/img/admin-area.png',
        text: 'Admin Area',
        show: () => this.hasUserRoles('SystemAdministrator', 'Administrator', 'Supervisor'),
        subMenu: [
          {
            path: '/vipCustomer',
            image: '/img/vip-customer-grey.svg',
            text: 'VIP Customers',
            show: () => this.hasUserRoles('SystemAdministrator', 'Administrator'),
          },
          {
            path: '/users',
            icon: 'people',
            text: 'Users',
            show: () => this.$store.getters['usersModule/canListUsers'],
          },
          {
            path: '/documenttemplate',
            icon: 'email',
            text: 'Document Template',
            show: () => this.hasUserRoles('SystemAdministrator', 'Administrator'),
          },
          {
            path: '/tradeManagement',
            image: '/img/employee.svg',
            text: 'Trade Management',
            show: () => this.hasUserRoles('SystemAdministrator', 'Administrator'),
          },
          {
            path: '/cookieManagement',
            image: '/img/cookie.svg',
            text: 'Cookie Management',
            show: () => this.hasUserRoles('SystemAdministrator', 'Administrator'),
          },
          {
            path: '/agentsDashboard',
            image: '/img/spanner.svg',
            text: 'Fix Trapped Agents',
            show: () =>
              useSessionStore().hasPermission(Permissions.FixStuckTasks) &&
              !!this.$ld.variation('fnol-58-release-stuck-agents'),
          },
        ],
      },
    ]
  }

  private get authPolicies(): AuthPolicies {
    return this.$store.getters['authModule/policies']
  }

  private hasUserRoles(...userRoles: string[]): boolean {
    return this.user && userRoles.some((role) => this.user.roleName === role)
  }

  private getUserDashboardItems(): MenuItem[] {
    if (!this.user) {
      return []
    }

    const dashboardItems: MenuItem[] = this.user.dashboardUserRights.map((dashboard) => ({
      path: '/' + dashboard.name,
      text: dashboard.name && dashboard.name.toLowerCase() === 'missedjobsdashboard' ? 'Open Jobs' : dashboard.label,
    }))

    return dashboardItems
  }

  private async getCustomDashboardsAsync(): Promise<MenuItem[]> {
    try {
      if (!this.hasUserRoles('SystemAdministrator', 'Administrator', 'Finance')) {
        return []
      }

      // get custom dashboards for this user
      const customDashboards = await CustomDashboardController.GetUserCustomDashboards()
      const customDashboardItems: MenuItem[] = customDashboards.map((dashboard) => ({
        path: '/dynamicDashboard/' + dashboard.id,
        text: dashboard.name,
        subMenu: [],
      }))
      return customDashboardItems
    } catch (error) {
      eventBus.$emit(
        'errorHandler',
        'There was an error retrieving the list of custom dashboards, please try again.',
        false
      )
      return []
    }
  }

  private getRecentJobs(): MenuItem[] {
    const recentJobs = store.Instance.state.Jobs
    if (!recentJobs) {
      return []
    }

    const keys = Object.keys(recentJobs)
    return keys
      .map((k) => recentJobs[k])
      .map((job) => {
        const jobDescription = `${job.jobId} - ${job.addressLine1}, ${job.postCode}`
        return {
          path: `/job/${job.jobId}`,
          icon: 'today',
          text: jobDescription,
          tooltip: jobDescription,
        }
      })
  }

  private async mounted(): Promise<void> {
    this.customDashboardItems = await this.getCustomDashboardsAsync()
    this.refresh()
    eventBus.$on('addRemoveDashboardInNavigationMenu', this.onDashboardsChanged.bind(this))
  }
}
</script>

<style>
.v-list__group__items {
  transition: 0.3s all linear !important;
}
</style>

<style scoped>
.menu-img {
  max-width: 24px;
}
.v-list__group.v-list__group--active:after,
.v-list__group.v-list__group--active:before {
  display: none;
  contain: '';
}
.v-list__group__header > div {
  width: 100%;
}
.sub-menu-list {
  padding-left: 56px;
}
</style>
