import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import TwilioController from '@/api/twilioController'
import UserController from '@/api/userController'
import Store from '@/store'
import { Worker } from 'twilio-taskrouter'
import { useTwilioDevice } from './device'
import eventBus from '@/common/bus'
import { logger } from '@/plugins/datadog'
import { createCountdown } from '@/lib/countdown'

export const useTwilioWorker = defineStore('twilio-worker', () => {
  const device = useTwilioDevice()

  const initialized = ref<boolean>(false)
  const instance = ref<Worker>()
  const inQueue = ref<boolean>(false)
  const inWrapUp = ref<boolean>(false)
  const notInQueueReason = ref<string>()
  const wrapUpCountdown = createCountdown(returnToQueue)

  function onReady(worker: Worker) {
    inQueue.value = worker.activity.name === 'Idle'
    if (inQueue.value) {
      notInQueueReason.value = undefined
    } else {
      notInQueueReason.value = worker.activity.name
    }
  }

  async function onTokenExpired() {
    await refreshToken()
  }

  function onActivityUpdated(worker: Worker) {
    if (!instance.value) {
      throw new Error('Received activity updated callback without worker.')
    }

    if (instance.value.activity.name !== worker.activity.name && worker.activity.name === 'Offline') {
      eventBus.$emit('handleStatusUpdate', true)
    } else if (instance.value.activity.name !== worker.activity.name && instance.value.activity.name === 'Offline') {
      eventBus.$emit('handleStatusUpdate', false)
    }

    logger.info('Worker Activity ' + worker.activity.name, {
      ActivitySid: worker.activity.sid,
      PreviousActivityName: instance.value.activity.name,
      ActivityName: worker.activity.name,
    })

    instance.value = worker
    inQueue.value = worker && worker.activity.name === 'Idle'
    if (!inQueue.value) {
      notInQueueReason.value = worker.activity.name
    }
    inWrapUp.value = worker && worker.activity.name === 'WrapUp'
    if (inWrapUp.value) {
      // if we've just been put into wrapup, start countdown
      wrapUpCountdown.start(30)
    } else {
      wrapUpCountdown.stop()
    }
  }

  function onError(error: Error) {
    const errorMessage = `Twilio Worker. Error Name: ${error.name}. Error Message: ${error.message}. Error Cause: ${error.cause}.`
    console.error(errorMessage)
    eventBus.$emit('errorHandler', errorMessage)
  }

  async function init() {
    if (initialized.value) {
      return
    }

    const workerToken = await TwilioController.GetWorkerAccessToken()

    // TODO: Refactor this code once worker-shift-rework is merged in,
    // shift starts and ends will be stored on worker record instead
    if (Store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'IncidentManager') {
      const shiftTime = await UserController.GetIncidentManagerShift()
      if (shiftTime) {
        instance.value = new Worker(workerToken, {
          connectActivitySid: Store.Instance.state.Environment.TwilioIdleActivitySid,
        })
      } else {
        instance.value = new Worker(workerToken, {
          connectActivitySid: Store.Instance.state.Environment.TwilioOfflineActivitySid,
        })
      }
    } else {
      instance.value = new Worker(workerToken, {
        connectActivitySid: Store.Instance.state.Environment.TwilioIdleActivitySid,
      })
    }

    instance.value.on('ready', onReady)
    instance.value.on('tokenExpired', onTokenExpired)
    instance.value.on('activityUpdated', onActivityUpdated)
    instance.value.on('error', onError)

    initialized.value = true
  }

  async function setActivity(activitySid: string, reason: string | undefined) {
    if (!instance.value) {
      throw new Error('Attempted to set worker activity without worker.')
    }

    // Not sure why we do this after setting every activity.
    await device.refreshToken()

    const newActivity = instance.value.activities.get(activitySid)
    if (!newActivity) {
      throw new Error('Attempted to set an invalid activity: ' + activitySid)
    }

    const oldActivity = instance.value.activity
    if (oldActivity.name === newActivity.name) {
      logger.info(`Skipping no-op worker activity change from ${oldActivity.name} to ${newActivity.name}`, {
        ActivitySid: activitySid,
        PreviousActivityName: oldActivity.name,
        ActivityName: newActivity.name,
        ChangeReason: reason,
      })
    } else {
      await newActivity.setAsCurrent()

      logger.info(`Changed worker activity from ${oldActivity.name} to ${newActivity.name}`, {
        ActivitySid: activitySid,
        PreviousActivityName: oldActivity.name,
        ActivityName: newActivity.name,
        ChangeReason: reason,
      })
    }
  }

  async function refreshToken() {
    if (!instance.value) {
      throw new Error('Attempted to refresh worker token without worker.')
    }

    await device.refreshToken()
    const workerToken = await TwilioController.GetWorkerAccessToken()
    instance.value.updateToken(workerToken)
  }

  async function returnToQueue() {
    if (!instance.value) {
      throw new Error('Attempted to return to queue without worker.')
    }

    if (instance.value.activity.name === 'WrapUp') {
      await setActivity(Store.Instance.state.Environment.TwilioIdleActivitySid, 'Return to queue')
      wrapUpCountdown.stop()
    }
  }

  return {
    init,
    setActivity,
    returnToQueue,
    inQueue,
    notInQueueReason,
    inWrapUp,
    wrapUpCountdown: wrapUpCountdown.timeLeft,
    initialized,
    instance: computed(() => {
      if (!instance.value) {
        throw new Error('Attempted to access worker before init.')
      }
      return instance.value
    }),
  }
})
